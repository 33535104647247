import { z } from 'zod';
import { EventStatus } from './event';
import { ProductDTOSchema } from './product';
import {
  CurrencyDTOSchema,
  EventThemeDTOSchema,
  FacebookRestreamingConfigurationDTOSchema,
  RestreamingConfigurationDTOSchema,
  VideoStreamChannelDTOSchema,
  YoutubeRestreamingConfigurationDTOSchema,
} from './V1/api';
import {
  StringNullableSchema,
  StringSchema,
  URLNullableSchema,
  URLSchema,
  UUIDNullableSchema,
  UUIDSchema,
} from './shared';

const ProductVariantStateSchema = z.object({
  coverUrl: URLSchema.nullable(),
  crossedOutPrice: z.number().nullable(),
  label: StringSchema,
  option1ValueId: UUIDSchema,
  option2ValueId: UUIDNullableSchema,
  option3ValueId: UUIDNullableSchema,
  price: z.number(),
  removed: z.boolean(),
  variantId: StringSchema,
});

const ProductOptionValueStateSchema = z.object({
  id: UUIDSchema,
  name: StringSchema,
});

const CBOEventReadModelDTOProductOptionSchema = z.object({
  allValues: ProductOptionValueStateSchema.array(),
  id: UUIDSchema,
  name: StringSchema,
  selectedValues: ProductOptionValueStateSchema.array(),
  type: StringSchema,
});

const CBOEventReadModelDTOProductOptionsSchema = z.object({
  option1: CBOEventReadModelDTOProductOptionSchema,
  option2: CBOEventReadModelDTOProductOptionSchema.nullable(),
  option3: CBOEventReadModelDTOProductOptionSchema.nullable(),
  variants: ProductVariantStateSchema.array(),
  variantsHaveSamePrice: z.boolean(),
});

const OverrideSyncedProductFieldsSchema = z.object({
  description: StringNullableSchema,
  imageUrls: URLSchema.array().nullable(),
  title: StringNullableSchema,
});

const OverrideableSyncedProductFieldsSchema = ProductDTOSchema.pick({
  description: true,
  imageUrls: true,
  title: true,
});

const CBOEventReadModelDTOProductBaseSchema = ProductDTOSchema.omit({
  id: true,
  inventoryPolicy: true,
  inventoryQuantity: true,
  options: true,
}).extend({
  id: UUIDSchema,
  options: CBOEventReadModelDTOProductOptionsSchema.nullable(),
});

const CBOEventReadModelDTOSyncedProductSchema = CBOEventReadModelDTOProductBaseSchema.extend({
  isSynced: z.literal(true),
  overriddenFields: OverrideSyncedProductFieldsSchema,
});

const CBOEventReadModelDTONotSyncedProductSchema = CBOEventReadModelDTOProductBaseSchema.extend({
  isSynced: z.literal(false),
});

const CBOEventReadModelDTOProductSchema = z.union([
  CBOEventReadModelDTOSyncedProductSchema,
  CBOEventReadModelDTONotSyncedProductSchema,
]);

const CBOEventReadModelDTOPinnedChatMessageSchema = z.object({
  content: StringSchema,
  messageId: StringSchema,
});

// TODO: Move to V1.cbo.event-read-model
export const CBOEventReadModelDTOSchema = z.object({
  chatIntroductionText: StringSchema,
  chatModeratorIds: StringSchema.array(),
  createdAt: StringSchema,
  deletedAt: StringSchema.datetime().nullable(),
  description: StringSchema,
  facebookRestreamingConfiguration: FacebookRestreamingConfigurationDTOSchema.nullable(),
  finishTime: StringSchema.datetime().nullable(),
  id: UUIDSchema,
  isLiveViewersCountDisplayed: z.boolean(),
  moderatorMessageLabel: StringSchema,
  pinnedChatMessage: CBOEventReadModelDTOPinnedChatMessageSchema.nullable(),
  postEventText: StringSchema,
  preEventText: StringSchema,
  products: CBOEventReadModelDTOProductSchema.array(),
  publisherPhone: StringSchema,
  replayFileUrl: URLNullableSchema,
  restreamingConfigurations: RestreamingConfigurationDTOSchema.array(),
  showTime: StringSchema.datetime().nullable(),
  startTime: StringSchema.datetime().nullable(),
  status: z.nativeEnum(EventStatus),
  tenantId: UUIDSchema,
  theme: EventThemeDTOSchema,
  title: StringSchema,
  videoStreamChannel: VideoStreamChannelDTOSchema,
  videoDuration: z.number().nullable(),
  youtubeRestreamingConfiguration: YoutubeRestreamingConfigurationDTOSchema.nullable(),
});

export const SynchronizeEventListReadModelResponseDTOSchema = CBOEventReadModelDTOSchema.pick({
  id: true,
  publisherPhone: true,
  replayFileUrl: true,
  showTime: true,
  status: true,
  title: true,
  videoDuration: true,
}).extend({
  image: StringSchema,
  productsIds: UUIDSchema.array(),
  remindersCount: z.number(),
});

export type CBOEventReadModelDTO = z.infer<typeof CBOEventReadModelDTOSchema>;
export type CBOEventReadModelDTOPinnedChatMessage = z.infer<typeof CBOEventReadModelDTOPinnedChatMessageSchema>;
export type CBOEventReadModelDTOProduct = z.infer<typeof CBOEventReadModelDTOProductSchema>;
export type CBOEventReadModelDTOProductBase = z.infer<typeof CBOEventReadModelDTOProductBaseSchema>;
export type CBOEventReadModelDTOProductOption = z.infer<typeof CBOEventReadModelDTOProductOptionSchema>;
export type CBOEventReadModelDTOProductOptions = z.infer<typeof CBOEventReadModelDTOProductOptionsSchema>;
export type CBOEventReadModelDTOSyncedProduct = z.infer<typeof CBOEventReadModelDTOSyncedProductSchema>;
export type OverrideableSyncedProductFields = z.infer<typeof OverrideableSyncedProductFieldsSchema>;
export type OverrideSyncedProductFields = z.infer<typeof OverrideSyncedProductFieldsSchema>;
export type ProductVariantState = z.infer<typeof ProductVariantStateSchema>;
export type SynchronizeEventListReadModelResponseDTO = z.infer<typeof SynchronizeEventListReadModelResponseDTOSchema>;
export type CBOEventReadModelDTONotSyncedProduct = z.infer<typeof CBOEventReadModelDTONotSyncedProductSchema>;
export type UpdateSyncedProductDTO = OverrideSyncedProductFields;
