import { z } from 'zod';
import { StringNullableSchema, StringSchema, UUIDSchema } from '../../shared/schemas';

export const AuthenticateChatGuestDTOSchema = z.object({
  authToken: StringSchema
})

export const ChatCredentialsDTOSchema = z.object({
  authenticationToken: StringSchema,
  userId: StringSchema,
})

export const PinMessageDTOSchema = z.object({
  content: StringSchema,
  messageId: StringSchema,
});

export const PinnedMessageDTOSchema = z.object({
  content: StringSchema,
  id: UUIDSchema,
  messageId: StringSchema,
  pinnedAt: StringSchema,
  unpinnedAt: StringNullableSchema,
})


export const UnpinMessageDTOSchema = z.object({
  messageId: StringSchema,
});

export type AuthenticateChatGuestDTO = z.infer<typeof AuthenticateChatGuestDTOSchema>;
export type ChatCredentialsDTO = z.infer<typeof ChatCredentialsDTOSchema>;
export type PinnedMessageDTO = z.infer<typeof PinnedMessageDTOSchema>;
export type PinMessageDTO = z.infer<typeof PinMessageDTOSchema>;
export type UnpinMessageDTO = z.infer<typeof UnpinMessageDTOSchema>;
