import { BILLING_PLANS, BillingPlanId, Cms } from '@bellepoque/api-contracts';

import { ShoppablesSettingsFilesUploads } from '../../../core/gateways/shoppables-gateway';
import { AppDispatch, State } from '../../../core/store';
import { fetchProduct } from '../../../core/usecases/catalog/fetch-product';
import { fetchMediaCollections } from '../../../core/usecases/media-collections/fetch-media-collections';
import {
  UpdateMediaCollectionSettingsRequest,
  resetUpdateMediaCollectionSettings,
  updateMediaCollectionSettings,
} from '../../../core/usecases/media-collections/update-media-collection-settings';
import { checkPlayerPlaylistsBlockIsAdded } from '../../../core/usecases/shoppables/check-player-playlists-block-is-added';
import { copyShoppableId } from '../../../core/usecases/shoppables/copy-shoppable-id';
import { deleteShoppable } from '../../../core/usecases/shoppables/delete-shoppable';
import { fetchOneShoppable } from '../../../core/usecases/shoppables/fetch-shoppable';
import { fetchShoppables } from '../../../core/usecases/shoppables/fetch-shoppables';
import { fetchShoppablesSettings } from '../../../core/usecases/shoppables/fetch-shoppables-settings';
import { getShoppableVideoFileUploadUrl } from '../../../core/usecases/shoppables/get-shoppable-video-file-upload-url';
import { getShoppablesSettingsFileUploadUrls } from '../../../core/usecases/shoppables/get-shoppables-settings-file-upload-urls';
import {
  UpdateShoppablesSettingsPayload,
  resetUpdateShoppablesSettings,
  updateShoppablesSettings,
} from '../../../core/usecases/shoppables/update-shoppables-settings';
import {
  resetUploadShoppableVideoFile,
  uploadShoppableVideoFile,
} from '../../../core/usecases/shoppables/upload-shoppable-video-file';
import { uploadShoppablesSettingsFiles } from '../../../core/usecases/shoppables/upload-shoppables-settings-files';
import { trackUserJourneyEvent } from '../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createShoppablesViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const {
      currentShoppable,
      fileUploading,
      fileUploadUrl,
      shoppableCreation,
      shoppableDeletion,
      shoppables,
      shoppablesFetching,
      shoppablesSettings,
      shoppablesSettingsFileUploading,
      shoppablesSettingsFileUploadUrls,
      shoppablesSettingsFileUploadUrlsFetching,
      shoppablesSettingsUpdate,
    } = state.shoppables;
    const {
      activeSubscriptionId,
      currency: tenantCurrency,
      id: tenantId,
      cms,
      name: tenantName,
    } = state.tenants.currentTenant;
    const shoppablesFetchingStatus = shoppablesFetching.status;
    const {
      mediaCollections,
      mediaCollectionSettingsUpdate: { status: mediaCollectionSettingsUpdateStatus },
      publishedShoppablesCount,
    } = state.mediaCollections;

    const currentPlanId: BillingPlanId = activeSubscriptionId ?? 'live_access';

    return {
      canPublishShoppables: cms === Cms.Shopify,
      canUploadFile: !!fileUploadUrl,
      copyShoppableId: (shoppableId: string) => {
        copyShoppableId({ shoppableId });
      },
      creationStatus: shoppableCreation.status,
      currentShoppable,
      deleteShoppable: (shoppableId: string) => {
        dispatch(deleteShoppable({ shoppableId, tenantId }));
      },
      deletionStatus: shoppableDeletion.status,
      fetchCurrentShoppableCatalogProduct: () => {
        if (currentShoppable) {
          dispatch(fetchProduct({ productId: currentShoppable.productId, tenantId }));
        }
      },
      fetchMediaCollections: () => {
        dispatch(fetchMediaCollections({ tenantId }));
      },
      fetchPlayerPlaylistsBlock: () => {
        dispatch(checkPlayerPlaylistsBlockIsAdded(tenantId));
      },
      fetchShoppable: (shoppableId: string) => {
        dispatch(fetchOneShoppable({ shoppableId, tenantId }));
      },
      fetchShoppableVideoFileUploadUrl: () => {
        if (currentShoppable) {
          dispatch(getShoppableVideoFileUploadUrl({ shoppableId: currentShoppable.id, tenantId }));
        }
      },
      fetchShoppables: () => {
        dispatch(fetchShoppables({ shoppablesFetchingStatus, tenantId }));
      },
      fetchShoppablesSettings: () => {
        dispatch(fetchShoppablesSettings({ tenantId }));
      },
      fetchShoppablesSettingsFileUploadUrls: () => {
        dispatch(getShoppablesSettingsFileUploadUrls({ tenantId }));
      },
      fileUploadStatus: fileUploading.status,
      fileUploadUrl,
      maxPublishedShoppablesCount: BILLING_PLANS[currentPlanId].maxPublishedShoppables ?? null,
      mediaCollectionSettingsUpdateStatus,
      mediaCollections,
      publishedShoppablesCount,
      resetUpdateMediaCollectionSettings: () => {
        dispatch(resetUpdateMediaCollectionSettings());
      },
      resetUpdateShoppablesSettings: () => {
        dispatch(resetUpdateShoppablesSettings());
      },
      resetUploadFile: () => {
        dispatch(resetUploadShoppableVideoFile());
      },
      shoppables,
      shoppablesFetchingStatus,
      shoppablesSettings,
      shoppablesSettingsFileUploadUrls,
      shoppablesSettingsFileUploadUrlsFetchingStatus: shoppablesSettingsFileUploadUrlsFetching.status,
      shoppablesSettingsFileUploadingStatus: shoppablesSettingsFileUploading.status,
      tenantCurrency,
      tenantId,
      tenantName,
      trackUserJourneyEvent: () => {
        dispatch(
          trackUserJourneyEvent({
            data: {
              'Tenant id': tenantId,
              'Tenant name': tenantName,
            },
            name: 'Shoppables',
          }),
        );
      },
      updateMediaCollectionSettings: (
        mediaCollectionId: string,
        payload: UpdateMediaCollectionSettingsRequest['payload'],
      ) => {
        dispatch(updateMediaCollectionSettings({ mediaCollectionId, payload, tenantId }));
      },
      updateShoppablesSettings: (payload: UpdateShoppablesSettingsPayload) => {
        dispatch(updateShoppablesSettings({ payload, tenantId }));
      },
      updateShoppablesSettingsStatus: shoppablesSettingsUpdate.status,
      uploadFile: (file: File) => {
        if (fileUploadUrl && currentShoppable)
          dispatch(uploadShoppableVideoFile({ file, fileUploadUrl, shoppableId: currentShoppable.id, tenantId }));
      },
      uploadShoppablesSettingsFiles: (files: ShoppablesSettingsFilesUploads) => {
        dispatch(uploadShoppablesSettingsFiles({ files }));
      },
    };
  };
