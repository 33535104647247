import { z } from 'zod';
import { StringNullableSchema, StringSchema } from '../../shared';
import { MessageId, MessageSchema, MessageTypes, SerializableMessage } from '../Message';

export const ShopifyAppInstalledDataSchema = z.object({
  storeCurrencyCode: StringSchema,
  storeCountryCode: StringNullableSchema,
  storeId: StringSchema,
  storeEmail: StringSchema,
  storeName: StringSchema,
});

const ShopifyAppInstalledMessageSchema = MessageSchema.extend({ data: ShopifyAppInstalledDataSchema });

export type ShopifyAppInstalledData = z.infer<typeof ShopifyAppInstalledDataSchema>;
export type ShopifyAppInstalledMessage = z.infer<typeof ShopifyAppInstalledMessageSchema>;

export class ShopifyAppInstalled implements SerializableMessage<ShopifyAppInstalledData> {
  constructor(public readonly id: MessageId, private time: string, private data: ShopifyAppInstalledData) {
  }

  toJSON(): ShopifyAppInstalledMessage {
    return {
      data: this.data,
      datacontenttype: 'application/json;charset=utf-8',
      id: this.id,
      partitionkey: this.data.storeId,
      source: 'shopify',
      time: this.time,
      type: MessageTypes.Shopify_AppInstalled,
    };
  }
}