import { z } from 'zod';
import { MessageId, MessageSchema, MessageTypes, SerializableMessage } from '../Message';
import { StringSchema } from '../../shared';
import { BillingPlanIdSchema } from '../../plans';

export const ShopifySubscriptionCreatedDataSchema = z.object({
  storeId: StringSchema,
  planId: BillingPlanIdSchema,
  subscriptionId: StringSchema,
});

const ShopifySubscriptionCreatedMessageSchema = MessageSchema.extend({data: ShopifySubscriptionCreatedDataSchema});

export type ShopifySubscriptionCreatedData = z.infer<typeof ShopifySubscriptionCreatedDataSchema>;
export type ShopifySubscriptionCreatedMessage = z.infer<typeof ShopifySubscriptionCreatedMessageSchema>;

export class ShopifySubscriptionCreated implements SerializableMessage<ShopifySubscriptionCreatedData> {
  constructor(public readonly id: MessageId, private time: string, private data: ShopifySubscriptionCreatedData) {}

  toJSON(): ShopifySubscriptionCreatedMessage {
    return {
      data: this.data,
      datacontenttype: 'application/json;charset=utf-8',
      id: this.id,
      partitionkey: this.data.storeId,
      source: 'shopify',
      time: this.time,
      type: MessageTypes.Shopify_SubscriptionCreated,
    };
  }
}
