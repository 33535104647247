import { Search } from '@mui/icons-material';
import { Box, InputAdornment, MenuItem, Select, TextField, styled } from '@mui/material';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CatalogSortType } from '../../../../core/store/state/catalog';

const ProductListHeaderRoot = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  flex: 1,
  justifyContent: 'start',
  left: 0,
  position: 'sticky',
  top: 0,
}));

const SortSelect = styled(Select)({
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
});

const SearchTextField = styled(TextField)({
  '& .input': {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
  width: 300,
});

export type ProductListHeaderProps = {
  isProductsListEmpty: boolean;
  onChangeSort: (sort: CatalogSortType) => void;
  onSearchProduct: (search: string) => void;
  sort: CatalogSortType;
};

const ProductListHeader = ({ isProductsListEmpty, onChangeSort, onSearchProduct, sort }: ProductListHeaderProps) => {
  const { t } = useTranslation(['catalog', 'common', 'product']);

  const sortValues: { label: string; value: string }[] = useMemo(
    () => [
      {
        label: t('common:Sort.Label', {
          field: t('products:title_capitalized'),
          order: t('common:Sort.Order.asc_alpha'),
        }),
        value: 'product.title',
      },
      {
        label: t('common:Sort.Label', {
          field: t('products:title_capitalized'),
          order: t('common:Sort.Order.desc_alpha'),
        }),
        value: '-product.title',
      },
      {
        label: t('common:Sort.Label', { field: t('common:Price'), order: t('common:Sort.Order.asc') }),
        value: 'minPrice',
      },
      {
        label: t('common:Sort.Label', { field: t('common:Price'), order: t('common:Sort.Order.desc') }),
        value: '-minPrice',
      },
    ],
    [t],
  );

  let debounceTimer: number;

  const handleSearch = useCallback(
    () => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const search = event.target.value;

      const callback = () => onSearchProduct(search);

      if (debounceTimer) window.clearTimeout(debounceTimer);

      debounceTimer = window.setTimeout(callback, 500);
    },
    [onSearchProduct],
  );

  return (
    <ProductListHeaderRoot>
      <Box>
        <SortSelect
          defaultValue={sort}
          disabled={isProductsListEmpty}
          onChange={(e) => onChangeSort(e.target.value as CatalogSortType)}
          size="small"
        >
          {sortValues.map(({ label, value }, i) => (
            <MenuItem key={i} value={value}>
              {label}
            </MenuItem>
          ))}
        </SortSelect>
      </Box>
      <Box>
        <SearchTextField
          InputProps={{
            className: 'input',
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch()}
          size="small"
        />
      </Box>
    </ProductListHeaderRoot>
  );
};

export default ProductListHeader;
