import { Message, MessageData, MessageId, MessageTypes, SerializableMessage } from '../Message';

export interface ShopifyExportCompletedData extends MessageData {
  storeId: string;
  fileUrl: string;
}

export type ShopifyExportCompletedMessage = Message<ShopifyExportCompletedData>;

export class ShopifyExportCompleted implements SerializableMessage<ShopifyExportCompletedData> {
  constructor(public readonly id: MessageId, private time: string, private data: ShopifyExportCompletedData) {}

  toJSON(): ShopifyExportCompletedMessage {
    return {
      data: this.data,
      datacontenttype: 'application/json;charset=utf-8',
      id: this.id,
      partitionkey: this.data.storeId,
      source: 'shopify',
      time: this.time,
      type: MessageTypes.Shopify_ExportCompleted,
    };
  }
}
