import { z } from 'zod';
import { UUIDSchema, StringSchema, StringNullableSchema, URLNullableSchema, URLSchema } from '../../shared/schemas';
import {
  FacebookRestreamingConfigurationDTOSchema,
  RestreamingConfigurationDTOSchema,
  YoutubeRestreamingConfigurationDTOSchema,
} from './restreaming';
import { ProductDTOSchema } from './products';
import { PinnedMessageDTOSchema } from './chat';
import { EventThemeDTOSchema } from './theme';
import { EventStatus } from '../../event';

export const constraints = {
  chatIntroductionText: {
    minLength: 0,
    maxLength: 2000,
  },
  description: {
    minLength: 5,
    maxLength: 2000,
  },
  moderatorMessageLabel: {
    minLength: 0,
    maxLength: 20,
  },
  preEventText: {
    minLength: 0,
    maxLength: 1000,
  },
  postEventText: {
    minLength: 0,
    maxLength: 1000,
  },
  rtmpRestreamingKey: {
    minLength: 0,
    maxLength: 255,
  },
  rtmpRestreamingUrl: {
    minLength: 0,
    maxLength: 2000,
  },
  title: {
    minLength: 3,
    maxLength: 100,
  },
};

const StreamKeyDTOSchema = z.object({
  key: StringSchema,
  url: StringSchema,
});

export const VideoStreamChannelDTOSchema = z.object({
  channelId: StringSchema,
  streamKey: StreamKeyDTOSchema,
});

export const AddCatalogProductsToEventDTOSchema = z.object({
  productsIds: UUIDSchema.array(),
});

export const CurrencySchema = z.object({
  code: StringSchema,
  decimalDigits: z.number(),
  id: UUIDSchema,
  symbol: StringSchema,
});

export const CreateEventDTOSchema = z.object({
  description: StringSchema,
  id: UUIDSchema,
  publisherPhone: StringSchema,
  showTime: StringNullableSchema,
  tenantId: UUIDSchema.optional(),
  title: StringSchema,
});

const EventChatCredentialsDTOSchema = z.object({
  authenticationToken: StringSchema,
  userId: StringSchema,
});

export const EventDTOSchema = z.object({
  chatIntroductionText: StringSchema,
  chatModeratorIds: StringSchema.array(),
  description: StringSchema,
  facebookRestreamingConfiguration: FacebookRestreamingConfigurationDTOSchema.nullable(),
  finishTime: StringSchema.datetime().nullable(),
  id: UUIDSchema,
  isLiveViewersCountDisplayed: z.boolean(),
  moderatorMessageLabel: StringSchema,
  pinnedChatMessage: PinnedMessageDTOSchema.nullable(),
  postEventText: StringSchema,
  preEventText: StringSchema,
  products: ProductDTOSchema.array(),
  publisherPhone: StringSchema,
  replayFileUrl: URLNullableSchema,
  restreamingConfigurations: RestreamingConfigurationDTOSchema.array(),
  showTime: StringSchema.datetime().nullable(),
  startTime: StringSchema.datetime().nullable(),
  status: z.nativeEnum(EventStatus),
  tenantId: UUIDSchema,
  theme: EventThemeDTOSchema,
  title: StringSchema,
  videoStreamChannel: VideoStreamChannelDTOSchema,
  youtubeRestreamingConfiguration: YoutubeRestreamingConfigurationDTOSchema.nullable(),
});

export const EventDepictionDTOSchema = EventDTOSchema.pick({
  id: true,
  title: true,
}).extend({
  showTime: StringSchema.datetime().nullable(),
});

export const PublicEventDepictionDTOSchema = EventDTOSchema.pick({
  id: true,
  title: true,
  description: true,
  status: true,
  showTime: true,
}).extend({
  preEventCoverUrl: URLSchema,
});

export const ReplayFileUploadUrlsDTOSchema = z.object({
  coverUrl: StringSchema,
});

export const ToggleLiveViewersCountDisplayDTOSchema = z.object({
  isDisplayed: z.boolean(),
});

export const UpdateEventDTOSchema = z.object({
  chatIntroductionText: StringSchema,
  description: StringSchema,
  facebookRestreamingConfiguration: FacebookRestreamingConfigurationDTOSchema.nullable(),
  moderatorMessageLabel: StringSchema,
  postEventText: StringSchema,
  preEventText: StringSchema,
  publisherPhone: StringSchema,
  restreamingConfigurations: RestreamingConfigurationDTOSchema.array(),
  showTime: StringNullableSchema,
  title: StringSchema,
  youtubeRestreamingConfiguration: YoutubeRestreamingConfigurationDTOSchema.nullable(),
});

export const UpdateReplayDTOSchema = z.object({
  coverUploaded: z.boolean(),
  description: StringSchema,
  title: StringSchema,
});

export const UpdateEventViewersCountDTOSchema = z.object({
  eventId: UUIDSchema,
});

export const ViewerTokenDTOSchema = z.object({
  channelId: StringSchema,
  viewerToken: StringSchema,
});

const ReplayChapterDTOSchema = z.object({
  endAt: z.number(),
  image: URLSchema,
  startAt: z.number(),
  title: StringSchema,
});
export const ReplaysChaptersDTOSchema = z.record(UUIDSchema, ReplayChapterDTOSchema.array());

export type AddCatalogProductsToEventDTO = z.infer<typeof AddCatalogProductsToEventDTOSchema>;
export type CreateEventDTO = z.infer<typeof CreateEventDTOSchema>;
export type EventChatCredentialsDTO = z.infer<typeof EventChatCredentialsDTOSchema>;
export type EventDepictionDTO = z.infer<typeof EventDepictionDTOSchema>;
export type EventDTO = z.infer<typeof EventDTOSchema>;
export type PublicEventDepictionDTO = z.infer<typeof PublicEventDepictionDTOSchema>;
export type ReplayChapterDTO = z.infer<typeof ReplayChapterDTOSchema>;
export type ReplayFileUploadUrlsDTO = z.infer<typeof ReplayFileUploadUrlsDTOSchema>;
export type ReplaysChaptersDTO = z.infer<typeof ReplaysChaptersDTOSchema>;
export type StreamKeyDTO = z.infer<typeof StreamKeyDTOSchema>;
export type ToggleLiveViewersCountDisplayDTO = z.infer<typeof ToggleLiveViewersCountDisplayDTOSchema>;
export type UpdateEventDTO = z.infer<typeof UpdateEventDTOSchema>;
export type UpdateEventViewersCountDTO = z.infer<typeof UpdateEventViewersCountDTOSchema>;
export type UpdateReplayDTO = z.infer<typeof UpdateReplayDTOSchema>;
export type VideoStreamChannelDTO = z.infer<typeof VideoStreamChannelDTOSchema>;
export type ViewerTokenDTO = z.infer<typeof ViewerTokenDTOSchema>;
