import { z } from 'zod';
import { EventDepictionDTOSchema } from './events';
import { SafeNonNegativeFloatSchema, SafeNonNegativeIntegerSchema } from '../../shared';

const HomePageBaseDTOSchema = z.object({
  productsCount: SafeNonNegativeIntegerSchema,
  productPagesWithMediaCollectionsCount: SafeNonNegativeIntegerSchema,
  publishedShoppablesCount: SafeNonNegativeIntegerSchema,
  upcomingEvent: EventDepictionDTOSchema.nullable(),
  views: z.object({
    live: SafeNonNegativeIntegerSchema,
    replay: SafeNonNegativeIntegerSchema,
    shoppableVideos: SafeNonNegativeIntegerSchema
  })
});

const HomepageWithSalesDTOSchema = HomePageBaseDTOSchema.extend({
  purchasedCarts:z.object({
    live: SafeNonNegativeIntegerSchema,
    replay: SafeNonNegativeIntegerSchema,
    shoppableVideos: SafeNonNegativeFloatSchema
  }),
  purchasedAmounts:z.object({
    live: SafeNonNegativeFloatSchema,
    replay: SafeNonNegativeFloatSchema,
    shoppableVideos: SafeNonNegativeFloatSchema
  }),
});

const HomepageWithoutSalesDTOSchema = HomePageBaseDTOSchema.extend({
  validatedCarts: z.object({
    live: SafeNonNegativeIntegerSchema,
    replay: SafeNonNegativeIntegerSchema,
  }),
  validatedCartsAmounts: z.object({
    live: SafeNonNegativeFloatSchema,
    replay: SafeNonNegativeFloatSchema,
  }),
});

const HomepageDTOSchema = z.union([HomepageWithSalesDTOSchema, HomepageWithoutSalesDTOSchema]);

export type HomePageWithSalesDTO = z.infer<typeof HomepageWithSalesDTOSchema>;
export type HomePageWithoutSalesDTO = z.infer<typeof HomepageWithoutSalesDTOSchema>;
export type HomepageDTO = z.infer<typeof HomepageDTOSchema>;
