import { Flavor } from '@bellepoque/shared';
import { z } from 'zod';
import { StringSchema, URLNullableSchema, URLSchema, UUIDNullableSchema, UUIDSchema } from './shared';

export const InventoryPolicySchema = z.union([z.literal('deny'), z.literal('continue'), z.null()]);
export type InventoryPolicy = z.infer<typeof InventoryPolicySchema>;

export type ProductId = Flavor<string, 'Product'>;
export type ProductOptionId = Flavor<string, 'ProductOption'>;
export type ProductOptionValueId = Flavor<string, 'ProductOptionValue'>;
export type VariantId = Flavor<string, 'Variant'>;


const ProductOptionValueSchema = z.object({
  id: StringSchema,
  name: StringSchema,
})

export type ProductOptionValue = z.infer<typeof ProductOptionValueSchema>;


const ProductVariantSchema = z.object({
  coverUrl: URLNullableSchema,
  crossedOutPrice: z.number().nullable(),
  inventoryPolicy: InventoryPolicySchema.optional(),
  inventoryQuantity: z.number().optional(),
  label: StringSchema,
  option1ValueId: UUIDSchema,
  option2ValueId: UUIDNullableSchema,
  option3ValueId: UUIDNullableSchema,
  price: z.number(),
  removed: z.boolean(),
  variantId: StringSchema,
})

export type ProductVariant = z.infer<typeof ProductVariantSchema>;

const ProductOptionSchema = z.object({
  allValues: ProductOptionValueSchema.array(),
  id: UUIDSchema,
  name: StringSchema,
  selectedValues: ProductOptionValueSchema.array(),
  type: StringSchema,
})

export type ProductOption = z.infer<typeof ProductOptionSchema>;

export const ProductOptionsSchema = z.object({
  option1: ProductOptionSchema,
  option2: ProductOptionSchema.nullable(),
  option3: ProductOptionSchema.nullable(),
  variantsHaveSamePrice: z.boolean(),
  variants: ProductVariantSchema.array(),
})

export type ProductOptions = z.infer<typeof ProductOptionsSchema>;

export const ProductDTOSchema = z.object({
  crossedOutPrice: z.number().nullable(),
  customerInternalId: StringSchema,
  description: StringSchema,
  id: UUIDSchema,
  imageUrls: StringSchema.array(),
  inventoryPolicy: InventoryPolicySchema.optional(),
  inventoryQuantity: z.number().optional().nullable(),
  options: ProductOptionsSchema.nullable(),
  price: z.number().nullable(),
  title: StringSchema,
  url: URLNullableSchema,
})

export type ProductDTO = z.infer<typeof ProductDTOSchema>;

const CatalogProductDTO = ProductDTOSchema.extend({
  isSynchronizedAutomatically: z.boolean(),
  originalId: UUIDSchema,
  status: z.string(),
  deletedAt: StringSchema.datetime().nullable(), // TODO remove this type CatalogProduct and use only the Entity and its state
})

export type CatalogProductDTO = z.infer<typeof CatalogProductDTO>;

