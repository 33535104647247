import { z } from 'zod';
import { UUIDSchema, URLNullableSchema, StringSchema, URLSchema, UUIDNullableSchema } from '../../shared/schemas';
import { InventoryPolicySchema, ProductOptionsSchema } from '../../product';

export const constraints = {
  description : {
    minLength: 5,
    maxLength: 2000
  },
  title: {
    minLength: 3,
    maxLength: 60,
  }
}

const CreateProductDTOVariantSchema = z.object({
  coverUrl: URLNullableSchema,
  crossedOutPrice: z.number().nullable(),
  label: StringSchema,
  option1ValueId: UUIDSchema,
  option2ValueId: UUIDNullableSchema,
  option3ValueId: UUIDNullableSchema,
  price: z.number(),
  removed: z.boolean(),
  variantId: StringSchema,
});

const CreateProductDTOOptionValueSchema = z.object({
  id: UUIDSchema,
  name: StringSchema,
});

const CreateProductDTOOptionSchema = z.object({
  allValues: CreateProductDTOOptionValueSchema.array(),
  id: UUIDSchema,
  name: StringSchema,
  selectedValues: CreateProductDTOOptionValueSchema.array(),
  type: StringSchema,
});

const CreateProductDTOOptionsSchema = z.object({
  option1: CreateProductDTOOptionSchema,
  option2: CreateProductDTOOptionSchema.nullable(),
  option3: CreateProductDTOOptionSchema.nullable(),
  variants: CreateProductDTOVariantSchema.array(),
  variantsHaveSamePrice: z.boolean(),
});
export const CreateProductDTOSchema = z.object({
  crossedOutPrice: z.number().nullable(),
  customerInternalId: StringSchema,
  description: StringSchema,
  id: UUIDSchema,
  imageUrls: URLSchema.array(),
  options: CreateProductDTOOptionsSchema.nullable(),
  price: z.number().nullable(),
  title: StringSchema,
  url: URLNullableSchema,
});

export const DisplayProductDTOSchema = z.object({
  productId: UUIDSchema
})

export const ProductDTOSchema = z.object({
  crossedOutPrice: z.number().nullable(),
  customerInternalId: StringSchema,
  description: StringSchema,
  id: UUIDSchema,
  imageUrls: StringSchema.array(),
  inventoryPolicy: InventoryPolicySchema.optional(),
  inventoryQuantity: z.number().optional().nullable(),
  options: ProductOptionsSchema.nullable(),
  price: z.number().nullable(),
  title: StringSchema,
  url: URLNullableSchema,
})

export const ReorderProductsLineupDTOSchema = z.object({
  newProductsOrder: UUIDSchema.array(),
})

export const UpdateProductDTOSchema = ProductDTOSchema.omit({ createdAt:true, id: true });

export type CreateProductDTO = z.infer<typeof CreateProductDTOSchema>; 
export type DisplayProductDTO = z.infer<typeof DisplayProductDTOSchema>;
export type ProductDTO = z.infer<typeof ProductDTOSchema>;
export type ReorderProductsLineupDTO = z.infer<typeof ReorderProductsLineupDTOSchema>;
export type UpdateProductDTO = z.infer<typeof UpdateProductDTOSchema>;



