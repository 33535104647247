import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CBOCurrency } from '../../core/domain/CBOCurrency';
import { CBOMediaCollection } from '../../core/domain/CBOMediaCollection';
import { shoppableVideoListItemReadModelToMediaCollectionMedia } from '../../core/domain/CBOMediaCollectionMedia';
import {
  CBOShoppableVideoListItemReadModel,
  CBOShoppableVideoListReadModel,
} from '../../core/domain/CBOShoppableVideoReadModel';
import { CBOShoppablesSettings, CBOShoppablesSettingsFileUploadUrls } from '../../core/domain/CBOShoppablesSettings';
import { ShoppablesSettingsFilesUploads } from '../../core/gateways/shoppables-gateway';
import { CommandStatus, QueryStatus } from '../../core/store/state/utils';
import { UpdateMediaCollectionSettingsRequest } from '../../core/usecases/media-collections/update-media-collection-settings';
import { UpdateShoppablesSettingsPayload } from '../../core/usecases/shoppables/update-shoppables-settings';
import FixedContentHeader from '../components/molecules/FixedContentHeader';
import ShoppablesHeader from '../components/molecules/shoppables/ShoppablesHeader';
import PlayerPreviewDialog from '../components/organisms/PlayerPreviewDialog';
import UpgradePlanDialog from '../components/organisms/UpgradePlanDialog';
import SelectMediaCollectionDialog from '../components/organisms/media-collections/select-media-collection-dialog/SelectMediaCollectionDialog';
import AddVideoToShoppableDialog from '../components/organisms/shoppables/AddVideoToShoppableDialog';
import CannotPublishShoppableModal from '../components/organisms/shoppables/CannotPublishShoppableModal';
import PlayerPlaylistsBlockModal from '../components/organisms/shoppables/PlayerPlaylistsBlockModal';
import ShoppableList from '../components/organisms/shoppables/ShoppableList';
import ShoppableSettingsFormDialog from '../components/organisms/shoppables/ShoppableSettingsFormDialog';
import ShoppableFormDialog from '../components/organisms/shoppables/shoppable-form-dialog/ShoppableFormDialog';

export const SHOULD_SHOW_ADD_VIDEO_MODAL_QUERY_PARAM = 'show-add-video-modal';

const PREFIX = 'ShoppableListGrid';

const classes = {
  gridRow: `${PREFIX}-grid-row`,
};

const ShoppablesTemplateRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  overflow: 'hidden',
  [`& .${classes.gridRow}`]: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.light}2B !important`,
      cursor: 'pointer',
    },
  },
}));

export type ShoppablesTemplateProps = {
  canPublishShoppables: boolean;
  canUploadFile: boolean;
  creationStatus: CommandStatus;
  currentShoppable: CBOShoppableVideoListItemReadModel | null;
  deletionStatus: CommandStatus;
  fileUploadStatus: CommandStatus;
  fileUploadUrl: string | null;
  maxPublishedShoppablesCount: number | null;
  mediaCollectionSettingsUpdateStatus: CommandStatus;
  mediaCollections: CBOMediaCollection[];
  onCopyIdShoppable: (shoppableId: string) => void;
  onDeleteShoppable: (shoppableId: string) => void;
  onFetchShoppable: (shoppableId: string) => void;
  onGetShoppablesSettingsSecuredFileUploadUrls: () => void;
  onGoToMediaCollection: (collectionId: string) => void;
  onGoToNewMediaCollection: (shoppableId: string) => void;
  onUpdateMediaCollectionSettings: (
    collectionId: string,
    payload: UpdateMediaCollectionSettingsRequest['payload'],
  ) => void;
  onUpdateShoppablesSettings: (payload: UpdateShoppablesSettingsPayload) => void;
  onUploadShoppablesSettingsFiles: (files: ShoppablesSettingsFilesUploads) => void;
  onUploadVideo: (file: File) => void;
  publishedShoppablesCount: number;
  resetUploadFile: () => void;
  shoppables: CBOShoppableVideoListReadModel;
  shoppablesFetchingStatus: QueryStatus;
  shoppablesSettings: CBOShoppablesSettings | null;
  shoppablesSettingsFileUploadUrls: CBOShoppablesSettingsFileUploadUrls | null;
  shoppablesSettingsFileUploadUrlsFetchingStatus: QueryStatus;
  shoppablesSettingsFileUploadingStatus: CommandStatus;
  tenantCurrency: CBOCurrency;
  tenantId: string;
  tenantName: string;
  updateShoppablesSettingsStatus: CommandStatus;
};

// TODO: Move shoppable settings props to a view model
const ShoppablesTemplate: FC<ShoppablesTemplateProps> = ({
  canPublishShoppables,
  canUploadFile,
  creationStatus,
  currentShoppable,
  deletionStatus,
  fileUploadStatus,
  fileUploadUrl,
  maxPublishedShoppablesCount,
  mediaCollections,
  mediaCollectionSettingsUpdateStatus,
  shoppablesFetchingStatus,
  onCopyIdShoppable,
  onDeleteShoppable,
  onFetchShoppable,
  onGetShoppablesSettingsSecuredFileUploadUrls,
  onGoToMediaCollection,
  onGoToNewMediaCollection,
  onUpdateMediaCollectionSettings,
  onUpdateShoppablesSettings,
  onUploadShoppablesSettingsFiles,
  onUploadVideo,
  publishedShoppablesCount,
  resetUploadFile,
  shoppables,
  shoppablesSettings,
  shoppablesSettingsFileUploadingStatus,
  shoppablesSettingsFileUploadUrls,
  shoppablesSettingsFileUploadUrlsFetchingStatus,
  tenantCurrency,
  tenantId,
  tenantName,
  updateShoppablesSettingsStatus,
}) => {
  const { search: queryParams } = useLocation();

  const [currentlyPlayedShoppableId, setCurrentlyPlayedShoppableId] = useState<string | null>(null);
  const [isAddVideoToShoppableDialogOpen, setIsAddVideoToShoppableDialogOpen] = useState<boolean>(false);
  const [isCannotPublishShoppableModalOpen, setIsCannotPublishShoppableModalOpen] = useState<boolean>(false);
  const [isEditShoppableDialogOpen, setIsEditShoppableDialogOpen] = useState<boolean>(false);
  const [isCreateShoppableModalOpen, setIsCreateShoppableModalOpen] = useState<boolean>(false);
  const [isPlayerShoppableModalOpen, setIsPlayerShoppableModalOpen] = useState<boolean>(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState<boolean>(false);
  const [isPlayerPlaylistsBlockModalShown, setIsPlayerPlaylistsBlockModalOpen] = useState<boolean>(false);
  const [isAddShoppableToCollectionModalOpen, setIsAddShoppableToCollectionModalOpen] = useState<boolean>(false);
  const [isUpgradePlanDialogOpen, setIsUpgradePlanDialogOpen] = useState(false);

  const currentMediaCollectionWaitingForRedirect = useRef<CBOMediaCollection | null>(null);

  const hasExceededPlanConstraints =
    !!maxPublishedShoppablesCount && publishedShoppablesCount > maxPublishedShoppablesCount;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const shouldShowAddVideoModal = queryParams.get(SHOULD_SHOW_ADD_VIDEO_MODAL_QUERY_PARAM);
    if (shouldShowAddVideoModal) {
      setIsAddVideoToShoppableDialogOpen(true);
    }
  }, [queryParams]);

  useEffect(() => {
    if (updateShoppablesSettingsStatus === 'success') {
      setIsSettingsModalOpen(false);
    }
  }, [updateShoppablesSettingsStatus]);

  useEffect(() => {
    if (creationStatus === 'success') {
      setIsAddVideoToShoppableDialogOpen(true);
    }
  }, [creationStatus]);

  useEffect(() => {
    if (fileUploadStatus === 'success' || fileUploadStatus === 'error') {
      resetUploadFile();
    }
  }, [isAddVideoToShoppableDialogOpen]);

  useEffect(() => {
    if (mediaCollectionSettingsUpdateStatus === 'success') {
      setIsAddShoppableToCollectionModalOpen(false);

      if (currentMediaCollectionWaitingForRedirect.current)
        onGoToMediaCollection(currentMediaCollectionWaitingForRedirect.current.id);
    }
  }, [mediaCollectionSettingsUpdateStatus]);

  const handleShoppableAddToCollectionClick = (shoppableId: string) => {
    onFetchShoppable(shoppableId);
    setIsAddShoppableToCollectionModalOpen(true);
  };

  const handleCloseShoppableFormDialog = () => {
    setIsCreateShoppableModalOpen(false);
    setIsEditShoppableDialogOpen(false);
  };

  const handleShoppableContinueClick = (shoppableId: string) => {
    const shoppable = shoppables.find((shoppable) => shoppable.id === shoppableId);
    if (!shoppable) return;

    onFetchShoppable(shoppableId);
    if (!shoppable?.videoUrl) setIsAddVideoToShoppableDialogOpen(true);
  };

  const handleShoppablePreviewClick = (shoppableId: string) => {
    setCurrentlyPlayedShoppableId(shoppableId);
    setIsPlayerShoppableModalOpen(true);
  };

  const handleShoppableNewCollectionClick = (shoppableId: string) => {
    onGoToNewMediaCollection(shoppableId);
  };

  const handleShoppableEditClick = (shoppableId: string) => {
    onFetchShoppable(shoppableId);
    setIsEditShoppableDialogOpen(true);
  };

  const handleDisplayUpdgradePlanDialog = () => {
    setIsUpgradePlanDialogOpen(true);
  };

  const handleCloseUpgradePlanDialog = () => {
    setIsUpgradePlanDialogOpen(false);
  };

  const handleSelectMediaCollections = (mediaCollections: CBOMediaCollection[]) => {
    if (hasExceededPlanConstraints) {
      handleDisplayUpdgradePlanDialog();
      return;
    }

    if (!currentShoppable) return;

    currentMediaCollectionWaitingForRedirect.current = mediaCollections.length === 1 ? mediaCollections[0] : null;

    mediaCollections.forEach((mediaCollection) => {
      const payload: UpdateMediaCollectionSettingsRequest['payload'] = {
        ...mediaCollection,
        medias: [...mediaCollection.medias, shoppableVideoListItemReadModelToMediaCollectionMedia(currentShoppable)],
      };

      onUpdateMediaCollectionSettings(mediaCollection.id, payload);
    });
  };

  return (
    <>
      <ShoppablesTemplateRoot id="shoppables-list">
        <FixedContentHeader isDrawerOpen isShadowHidden>
          <ShoppablesHeader
            isSettingsButtonShown={!!shoppablesSettings}
            loading={shoppablesFetchingStatus === 'pending'}
            maxPublishedShoppablesCount={maxPublishedShoppablesCount}
            onOpenSettings={() => setIsSettingsModalOpen(true)}
            onOpenShoppableCreator={() => setIsCreateShoppableModalOpen(true)}
            publishedShoppablesCount={publishedShoppablesCount}
            tenantName={tenantName}
          />
        </FixedContentHeader>
        <Box flexGrow={1} mt={1}>
          <ShoppableList
            canPublishShoppables={canPublishShoppables}
            deletionStatus={deletionStatus}
            mediaCollections={mediaCollections}
            onCopyIdShoppable={onCopyIdShoppable}
            onDeleteShoppable={onDeleteShoppable}
            onShoppableAddToMediaCollectionClick={handleShoppableAddToCollectionClick}
            onShoppableContinueClick={handleShoppableContinueClick}
            onShoppableEditClick={handleShoppableEditClick}
            onShoppableNewMediaCollectionClick={handleShoppableNewCollectionClick}
            onShoppablePreviewClick={handleShoppablePreviewClick}
            shoppables={shoppables}
          />
        </Box>
      </ShoppablesTemplateRoot>

      {shoppablesSettings && (
        <ShoppableSettingsFormDialog
          onClose={() => setIsSettingsModalOpen(false)}
          onGetSecuredFileUploadUrls={onGetShoppablesSettingsSecuredFileUploadUrls}
          onSubmit={onUpdateShoppablesSettings}
          onUploadShoppablesSettingsFiles={onUploadShoppablesSettingsFiles}
          open={isSettingsModalOpen}
          shoppablesSettings={shoppablesSettings}
          shoppablesSettingsFileUploadUrls={shoppablesSettingsFileUploadUrls}
          shoppablesSettingsFileUploadUrlsFetchingStatus={shoppablesSettingsFileUploadUrlsFetchingStatus}
          shoppablesSettingsFileUploadingStatus={shoppablesSettingsFileUploadingStatus}
          submissionInProgress={
            updateShoppablesSettingsStatus === 'pending' ||
            shoppablesSettingsFileUploadingStatus === 'pending' ||
            shoppablesSettingsFileUploadUrlsFetchingStatus === 'pending'
          }
          tenantCurrency={tenantCurrency}
        />
      )}

      <CannotPublishShoppableModal
        onClose={() => setIsCannotPublishShoppableModalOpen(false)}
        open={isCannotPublishShoppableModalOpen}
      />

      <ShoppableFormDialog
        formType={isCreateShoppableModalOpen ? 'create' : 'update'}
        onClose={handleCloseShoppableFormDialog}
        open={isCreateShoppableModalOpen || isEditShoppableDialogOpen}
      />

      <SelectMediaCollectionDialog
        isOpen={isAddShoppableToCollectionModalOpen}
        onClose={() => setIsAddShoppableToCollectionModalOpen(false)}
        onSelectMediaCollections={handleSelectMediaCollections}
      />

      {maxPublishedShoppablesCount && (
        <UpgradePlanDialog
          isOpen={isUpgradePlanDialogOpen}
          maxPublishedShoppablesCount={maxPublishedShoppablesCount}
          onClose={handleCloseUpgradePlanDialog}
          tenantName={tenantName}
        />
      )}

      {currentShoppable?.id && !!fileUploadUrl && (
        <AddVideoToShoppableDialog
          fileUploadStatus={fileUploadStatus}
          fileUploadUrl={fileUploadUrl}
          isOpen={isAddVideoToShoppableDialogOpen}
          loading={!canUploadFile}
          onClose={() => setIsAddVideoToShoppableDialogOpen(false)}
          onUploadVideo={onUploadVideo}
          shoppableId={currentShoppable.id}
          shoppableVideoUrl={currentShoppable.videoUrl}
          tenantId={tenantId}
        />
      )}
      {!!currentlyPlayedShoppableId && (
        <PlayerPreviewDialog
          onClose={() => setIsPlayerShoppableModalOpen(false)}
          open={isPlayerShoppableModalOpen}
          videoId={currentlyPlayedShoppableId}
        />
      )}
      <PlayerPlaylistsBlockModal
        isOpen={isPlayerPlaylistsBlockModalShown}
        onClose={() => setIsPlayerPlaylistsBlockModalOpen(false)}
        tenantName={tenantName}
      />
    </>
  );
};

export default ShoppablesTemplate;
