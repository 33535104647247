import { z } from 'zod';
import { StringSchema, URLSchema } from '../../shared/schemas';

export const EventThemeDTOSchema = z.object({
  addToCartBackgroundColor: StringSchema,
  adminMessageBackgroundColor: StringSchema,
  adminMessageIconUrl: URLSchema,
  brandLogoUrl: URLSchema,
  cartIconUrl: URLSchema,
  mainFontName: StringSchema,
  postEventCoverUrl: URLSchema,
  preEventCoverUrl: URLSchema,
  removeFromCartBackgroundColor: StringSchema,
  storeIconUrl: URLSchema,
  validateCartBackgroundColor: StringSchema,
});

export const CustomiseEventThemeDTOSchema = EventThemeDTOSchema.omit({
  adminMessageIconUrl: true,
  brandLogoUrl: true,
  cartIconUrl: true,
  postEventCoverUrl: true,
  preEventCoverUrl: true,
  storeIconUrl: true,
}).extend({
  adminMessageIconUploaded: z.boolean(),
  brandLogoUploaded: z.boolean(),
  cartIconUploaded: z.boolean(),
  postEventCoverUploaded: z.boolean(),
  preEventCoverUploaded: z.boolean(),
  storeIconUploaded: z.boolean(),
});

export const ThemeFileUploadUrlsDTOSchema = z.object({
  adminMessageIconUrl: URLSchema,
  brandLogoUrl: URLSchema,
  cartIconUrl: URLSchema,
  postEventCoverUrl: URLSchema,
  preEventCoverUrl: URLSchema,
  storeIconUrl: URLSchema,
});

export type CustomiseEventThemeDTO = z.infer<typeof CustomiseEventThemeDTOSchema>;
export type EventThemeDTO = z.infer<typeof EventThemeDTOSchema>;
export type ThemeFileUploadUrlsDTO = z.infer<typeof ThemeFileUploadUrlsDTOSchema>;
