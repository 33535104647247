export enum ErrorCodes {
  FrozenEvent = '60001',
  PublisherPhoneRequired = '60002',
  NotStreaming = '60003',
  ActiveSubscriptionRequired = '60004',
  StreamingRequiredForRestreaming = '60005',
  ExpiredFacebookToken = '60006',
  ExpiredYoutubeToken = '60007',
}

export const ErrorsByCode: Record<string, string> = {
  [ErrorCodes.ActiveSubscriptionRequired]: 'ActiveSubscriptionRequired',
  [ErrorCodes.FrozenEvent]: 'FrozenEvent',
  [ErrorCodes.NotStreaming]: 'NotStreaming',
  [ErrorCodes.PublisherPhoneRequired]: 'PublisherPhoneRequired',
  [ErrorCodes.StreamingRequiredForRestreaming]: 'StreamingRequiredForRestreaming',
  [ErrorCodes.ExpiredFacebookToken]: 'ExpiredFacebookToken',
  [ErrorCodes.ExpiredYoutubeToken]: 'ExpiredYoutubeToken',
};
