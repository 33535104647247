import { z } from "zod";
import { StringSchema, URLSchema } from "./shared";

// TODO: Move to V1.api.reminders
export const CreateEventReminderDTOSchema = z.object({
  commercialInfoConsent: z.boolean(),
  eventURL: StringSchema,
  firstName: StringSchema,
  locale: StringSchema,
  phoneNumber: StringSchema,
})

export type CreateEventReminderDTO = z.infer<typeof CreateEventReminderDTOSchema>;