export default {
  Integration: 'Integration',
  IntegrationNotEnabled:
    'Only offered with the "Business" and "Enterprise" plans.\nPlease visit your Shopify admin to upgrade your plan and collect participants’ emails.',
  Klaviyo: {
    ApiKey: 'API key',
    ApiKeyHelper:
      'Provide a Klaviyo Private API Key with a full access to the lists, profiles and subscriptions scopes. You can create an API key in your Klaviyo account under Account > Settings > API Keys.',
    ApiKeyInvalid: 'No lists were found. Please check your API key and access levels and try again.',
    ApiKeyInvalidInput: 'Please enter a valid API key',
    Info: 'The mails captured from your Live Events with be saved in the selected list.',
    Klaviyo: 'Klaviyo',
    ListInvalid: 'The previously selected list does not exist anymore. Please select another one.',
  },
  Tapcart: {
    Intro: 'Before proceeding, <EnableIntegrationLink>enable LiveMeUp integration on Tapcart</EnableIntegrationLink>.',
    LiveShoppingPageConfiguration: {
      Step1: '<Step>Step 1:</Step> Copy the URL below',
      Step2:
        '<Step>Step 2:</Step> <CreateWebBasedScreenLink>Create a Web-based custom screen</CreateWebBasedScreenLink> in your tapcart app and paste this URL in it.',
      Step3: '<Step>Step 3:</Step> Make that custom page visible in your Tapcart App navbar.',
      Title: 'Display your Live Shopping Page',
    },
    PlaylistsBlockConfiguration: {
      Step1: '<Step>Step 1:</Step> Copy the URL below',
      Step2:
        '<Step>Step 2: </Step><CreateWebBasedScreenLink>Create a Web-based custom screen</CreateWebBasedScreenLink> in your tapcart app and paste this URL in it.',
      Step3: '<Step>Step 3:</Step> Copy the Screen ID from Tapcart and paste it here:',
      Step4: '<Step>Step 4:</Step> You can now add the Widget stories block on your product pages in Tapcart.',
      Title: 'Display your "Widget stories" Block',
    },
    ScreenId: 'Screen ID',
    Tapcart: 'Tapcart',
    URL: 'URL',
  },
};
