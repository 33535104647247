import { ProductId } from '@bellepoque/api-contracts';
import { Box, Pagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

import { CBOCatalogProduct } from '../../core/domain/CBOCatalogProduct';
import { CBOCurrency } from '../../core/domain/CBOCurrency';
import { CatalogSortType } from '../../core/store/state/catalog';
import { CommandStatus, QueryStatus } from '../../core/store/state/utils';
import Toolbar from '../components/molecules/catalog/Toolbar';
import AddSelectedProductsToEventDialog from '../components/organisms/catalog/AddSelectedProductsToEventDialog';
import ProductList from '../components/organisms/catalog/ProductList';
import ConfirmDeleteDialog from './dialog/ConfirmDeleteDialog';

const PREFIX = 'Catalog';

const classes = {
  gridRow: `${PREFIX}-grid-row`,
};

const Root = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  [`& .${classes.gridRow}`]: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.light}2B !important`,
      cursor: 'pointer',
    },
  },
}));

interface CatalogTemplateProps {
  apiKey: string;
  canRenewApiKey: boolean;
  currency: CBOCurrency;
  currentPage: number;
  deleteStatus: CommandStatus;
  onChangePage: (page: number) => void;
  onChangeSort: (sort: CatalogSortType) => void;
  onClearSelection: () => void;
  onDeleteProducts: (productsIds: ProductId[]) => void;
  onRenewApiKey: () => void;
  onSearchProducts: (search: string) => void;
  onSelectProduct: (product: CBOCatalogProduct) => void;
  onUnselectProduct: (product: CBOCatalogProduct) => void;
  pageCount: number;
  products: CBOCatalogProduct[];
  productsFetchingStatus: QueryStatus;
  renewApiKeyStatus: CommandStatus;
  selectedProducts: CBOCatalogProduct[];
  sort: CatalogSortType;
}

export default function CatalogTemplate(props: CatalogTemplateProps) {
  const {
    apiKey,
    canRenewApiKey,
    currency,
    currentPage,
    deleteStatus,
    onChangePage,
    onChangeSort,
    onClearSelection,
    onDeleteProducts,
    onRenewApiKey,
    onSearchProducts,
    onSelectProduct,
    onUnselectProduct,
    pageCount,
    products,
    productsFetchingStatus,
    renewApiKeyStatus,
    selectedProducts,
    sort,
  } = props;

  const [isAddProductsToEventModalOpen, setIsAddProductsToEventModalOpen] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

  const [productsToAddToEvent, setProductsToAddToEvent] = useState<CBOCatalogProduct[]>([]);
  const [productsIdsToDelete, setProductsIdsToDelete] = useState<ProductId[]>([]);

  const handleAddProductsToEvent = (products: CBOCatalogProduct[]) => {
    setProductsToAddToEvent(products);
    setIsAddProductsToEventModalOpen(true);
  };

  const handleCloseAddProductsToEventModal = () => {
    setIsAddProductsToEventModalOpen(false);
  };

  const handleCloseConfirmDeleteModal = () => {
    setIsConfirmDeleteModalOpen(false);
  };

  const handleDeleteProducts = (productsIds: ProductId[]) => {
    setIsConfirmDeleteModalOpen(true);
    setProductsIdsToDelete(productsIds);
  };

  return (
    <Root
      id="product-list"
      sx={{
        flexGrow: 1,
      }}
    >
      <Toolbar
        canRenewApiKey={canRenewApiKey}
        onRenewApiKey={onRenewApiKey}
        onSearchProduct={onSearchProducts}
        renewApiKeyStatus={renewApiKeyStatus}
        tenantApiKey={apiKey}
      />
      <ProductList
        currency={currency}
        onAddToEvent={handleAddProductsToEvent}
        onChangeSort={onChangeSort}
        onClearSelection={onClearSelection}
        onDelete={handleDeleteProducts}
        onRefresh={() => onChangePage(1)}
        onSelect={onSelectProduct}
        onUnselect={onUnselectProduct}
        products={products}
        productsFetchingStatus={productsFetchingStatus}
        selectedProducts={selectedProducts}
        sort={sort}
      />

      <Box style={{ display: 'flex', flex: 1, justifyContent: 'center', marginTop: 20, paddingBottom: 20 }}>
        <Pagination
          count={pageCount}
          hidden={pageCount <= 1 || productsFetchingStatus === 'pending'}
          onChange={(_e, page) => onChangePage(page)}
          page={currentPage}
        />
      </Box>

      {isConfirmDeleteModalOpen && (
        <ConfirmDeleteDialog
          deleteCount={productsIdsToDelete.length}
          deleteFunction={() => onDeleteProducts(productsIdsToDelete)}
          deleteStatus={deleteStatus}
          onClose={handleCloseConfirmDeleteModal}
          open={isConfirmDeleteModalOpen}
        />
      )}

      {isAddProductsToEventModalOpen && (
        <AddSelectedProductsToEventDialog
          onClose={handleCloseAddProductsToEventModal}
          open={isAddProductsToEventModalOpen}
          selectedProducts={productsToAddToEvent}
        />
      )}
    </Root>
  );
}
