import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import OpenInBrowser from '@mui/icons-material/OpenInBrowser';
import PlayIcon from '@mui/icons-material/PlayArrow';
import { Box, Button, IconButton, Menu, MenuItem, Typography, menuClasses, paperClasses, styled } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOMediaCollection } from '../../../../core/domain/CBOMediaCollection';
import {
  CBOShoppableVideoListItemReadModel,
  CBOShoppableVideoListReadModel,
} from '../../../../core/domain/CBOShoppableVideoReadModel';
import { CommandStatus } from '../../../../core/store/state/utils';
import ShoppablesIcon from '../../../../icons/ShoppablesIcon';
import { secondsToFormatedTime } from '../../../../utils/date-format';
import CustomLoadingGridOverlay from '../../atoms/CustomLoadingGridOverlay';
import ShoppableStatusChip from '../../atoms/shoppables/ShoppableStatusChip';

const SHOPPABLE_LIST_CONTAINER_OFFSET = 14;

const ShoppableListContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
  // be sure to maintain marginTop === paddingBottom for virtual scroll shoppables works properly
  marginTop: theme.spacing(SHOPPABLE_LIST_CONTAINER_OFFSET),
  paddingBottom: theme.spacing(SHOPPABLE_LIST_CONTAINER_OFFSET),
}));

const ShoppableListRoot = styled(DataGrid)(({ theme }) => ({
  [`.MuiDataGrid-columnHeaderTitleContainer`]: {
    justifyContent: 'center',
    paddingLeft: 0,
  },
  [`.MuiDataGrid-columnSeparator`]: {
    visibility: 'hidden',
  },
  [`.MuiDataGrid-columnsContainer`]: {
    border: 'none',
  },

  border: 'none',
  fontFamily: theme.typography.h1.fontFamily,
  height: '100%',
  [`& .${classes.gridRow} > .${classes.cell}`]: {
    border: 'none',
    cursor: 'default',
    outline: 'none',
    padding: `0 ${theme.spacing(2)}`,
  },
  [`& .${classes.boldCell}`]: {
    fontWeight: 'bold',
  },
  [`& .${classes.gridRow}`]: {
    '&:nth-child(2n-1)': {
      background: theme.palette.common.white,
    },
    cursor: 'pointer',
  },
  [`& .${classes.mediumCell}`]: {
    fontWeight: 500,
  },
  [`& .${classes.hidden}`]: {
    display: 'none',
  },
}));

const PREFIX = 'ShoppableList';

const classes = {
  boldCell: `${PREFIX}-bold-cell`,
  cell: `${PREFIX}-cell`,
  columnHeader: `${PREFIX}-column-header`,
  gridRow: `${PREFIX}-grid-row`,
  hidden: `${PREFIX}-hidden`,
  mediumCell: `${PREFIX}-medium-cell`,
};

const Image = styled('img')({
  height: '100%',
  objectFit: 'cover',
  width: '100%',
});

const ImageContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '50%',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  display: 'flex',
  flex: 1,
  height: '60px',
  justifyContent: 'start',
  lineHeight: 'initial',
  margin: `0 ${theme.spacing(2)}`,
  overflow: 'hidden',
  position: 'relative',
  width: '60px',
}));

const PlayIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.35)',
  },
  background: 'rgba(0, 0, 0, 0.2)',
  color: theme.palette.common.white,
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
}));

const EditIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.black,
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  gap: theme.spacing(1),
  justifyContent: 'end',
  lineHeight: 'initial',
  marginRight: theme.spacing(1),
}));

const DisplayButton = styled(Button)({
  borderRadius: '12px',
});

const DisplayMenu = styled(Menu)({
  [`& .${paperClasses.root}`]: {
    border: '1px solid #999999',
    borderRadius: '12px',
  },
  [`& .${menuClasses.list}`]: {
    padding: 0,
  },
});

const DisplayMenuItem = styled(MenuItem)({
  justifyContent: 'space-between',
  minWidth: 200,
});

interface ShoppableListProps {
  canPublishShoppables: boolean;
  deletionStatus: CommandStatus;
  mediaCollections: CBOMediaCollection[];
  onCopyIdShoppable: (shoppableId: string) => void;
  onDeleteShoppable: (shoppableId: string) => void;
  onShoppableAddToMediaCollectionClick: (shoppableId: string) => void;
  onShoppableContinueClick: (shoppableId: string) => void;
  onShoppableEditClick: (shoppableId: string) => void;
  onShoppableNewMediaCollectionClick: (shoppableId: string) => void;
  onShoppablePreviewClick: (shoppableId: string) => void;
  shoppables: CBOShoppableVideoListReadModel;
}

const sortByCreatedAt = (a: CBOShoppableVideoListItemReadModel, b: CBOShoppableVideoListItemReadModel) =>
  a.createdAt > b.createdAt ? -1 : 1;

const ShoppableList: FC<ShoppableListProps> = ({
  canPublishShoppables,
  mediaCollections,
  deletionStatus,
  onCopyIdShoppable,
  onDeleteShoppable,
  onShoppableAddToMediaCollectionClick,
  onShoppableNewMediaCollectionClick,
  onShoppableEditClick,
  onShoppablePreviewClick,
  onShoppableContinueClick,
  shoppables,
}) => {
  const { t } = useTranslation('shoppables');
  const { enqueueSnackbar } = useSnackbar();

  const defaultSortedShoppables = useMemo(() => [...shoppables].sort(sortByCreatedAt), [shoppables]);

  const [optionsOpenedForShoppableId, setOptionsOpenedForShoppableId] = React.useState<string | null>(null);

  const areOptionActionsDisabled = deletionStatus === 'pending';

  const currentShoppableId = useRef<string>('');

  const availableMediaCollectionsForCurrentShoppableId = useMemo(() => {
    if (!currentShoppableId.current) {
      return [];
    }

    return mediaCollections.filter(
      (mediaCollection) => !mediaCollection.medias.some((media) => media.id === currentShoppableId.current),
    );
  }, [currentShoppableId.current, mediaCollections]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    currentShoppableId.current = id;
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseOptions = () => {
    setOptionsOpenedForShoppableId(null);
  };

  const handleDeleteOptionClick = () => {
    if (optionsOpenedForShoppableId) {
      onDeleteShoppable(optionsOpenedForShoppableId);
    }
  };

  const handleOptionsClick = (event: React.MouseEvent<HTMLElement>, shoppableId: string) => {
    event.stopPropagation();
    event.preventDefault();
    setOptionsOpenedForShoppableId(shoppableId);
  };

  const handleShoppableNewMediaCollectionClick = useCallback(() => {
    handleClose();
    onShoppableNewMediaCollectionClick(currentShoppableId.current);
  }, [onShoppableNewMediaCollectionClick]);

  const handleShoppableAddToMediaCollectionClick = useCallback(() => {
    handleClose();
    onShoppableAddToMediaCollectionClick(currentShoppableId.current);
  }, [onShoppableAddToMediaCollectionClick]);

  const handleShoppableEdit = useCallback(
    (shoppableId: string) => () => {
      onShoppableEditClick(shoppableId);
    },
    [onShoppableEditClick],
  );

  const handleShoppablePreviewClick = useCallback(
    (shoppableId: string) => () => {
      onShoppablePreviewClick(shoppableId);
    },
    [onShoppablePreviewClick],
  );

  const handleCopyIdShoppable = useCallback(
    (shoppableId: string) => () => {
      onCopyIdShoppable(shoppableId);
      enqueueSnackbar(t('notifications.ShoppableIdSuccessfullyCopied'), { variant: 'success' });
    },
    [onCopyIdShoppable, t],
  );

  const renderShoppableActionsCell = useCallback(
    ({ row }: GridRenderCellParams<any, CBOShoppableVideoListItemReadModel>) => {
      const { id, status, videoUrl } = row;
      const isReadyStatuses = ['READY', 'PUBLISHING', 'PUBLISHED'];
      const isReady = isReadyStatuses.includes(status);

      if (!isReady) {
        if (status === 'DRAFT') {
          return (
            <ButtonsContainer>
              <Button color="primary" onClick={() => onShoppableContinueClick(row.id)} variant="contained">
                {t('Continue')}
              </Button>
            </ButtonsContainer>
          );
        } else {
          return null;
        }
      }

      if (canPublishShoppables) {
        return (
          <ButtonsContainer>
            <DisplayButton onClick={handleClick(id)} startIcon={<OpenInBrowser />} variant="outlined">
              {t('Display')}
            </DisplayButton>
          </ButtonsContainer>
        );
      }

      if (videoUrl) {
        return (
          <ButtonsContainer>
            <Button color="primary" onClick={handleCopyIdShoppable(id)} variant="contained">
              {t('CopyVideoId')}
            </Button>
          </ButtonsContainer>
        );
      }

      return null;
    },
    [
      handleDeleteOptionClick,
      areOptionActionsDisabled,
      handleShoppableNewMediaCollectionClick,
      handleOptionsClick,
      handleCloseOptions,
      optionsOpenedForShoppableId,
      t,
    ],
  );

  const renderShoppableStatusCell = useCallback(
    ({ row }: GridRenderCellParams<any, CBOShoppableVideoListItemReadModel>) => (
      <Box alignItems="center" display="flex" flex={1} flexDirection="column" gap={1}>
        <Box alignItems="center" display="flex" flex={1} gap={1}>
          <ShoppableStatusChip status={row.status} />
        </Box>
        {row.status === 'OPTIMIZING' && (
          <Typography fontSize="10px" whiteSpace="break-spaces">
            {t('OptimizingDescription')}
          </Typography>
        )}
      </Box>
    ),
    [handleShoppablePreviewClick],
  );

  const renderShoppableThumbnailAndTitleCell = useCallback(
    (shoppableListItem: CBOShoppableVideoListItemReadModel) => {
      const status = shoppableListItem.status;
      const isPreviewButtonShown = status === 'READY' || status === 'PUBLISHING' || status === 'PUBLISHED';

      return (
        <Box alignItems="center" display="flex">
          <EditIconButton onClick={handleShoppableEdit(shoppableListItem.id)}>
            <EditIcon fontSize="small" />
          </EditIconButton>
          {shoppableListItem.thumbnailUrl && (
            <ImageContainer>
              <Image src={shoppableListItem.thumbnailUrl} />
              {isPreviewButtonShown && (
                <PlayIconButton onClick={handleShoppablePreviewClick(shoppableListItem.id)}>
                  <PlayIcon />
                </PlayIconButton>
              )}
            </ImageContainer>
          )}
          <Typography>{shoppableListItem.title}</Typography>
        </Box>
      );
    },
    [handleShoppableEdit],
  );

  const defaultHeaderProps: Partial<GridColDef> = {
    flex: 1,
    headerClassName: classes.columnHeader,
    sortable: true,
  };

  const columns: GridColDef[] = [
    {
      ...defaultHeaderProps,
      cellClassName: classes.mediumCell,
      field: 'title',
      headerName: t('Title'),
      renderCell: (params: GridRenderCellParams<any, CBOShoppableVideoListItemReadModel>) =>
        renderShoppableThumbnailAndTitleCell(params.row),
    },
    {
      ...defaultHeaderProps,
      cellClassName: classes.boldCell,
      field: 'productTitle',
      headerName: t('Product'),
    },
    {
      ...defaultHeaderProps,
      align: 'left',
      cellClassName: classes.mediumCell,
      field: 'duration',
      flex: 0,
      headerName: t('Duration'),
      valueGetter: (params: GridValueGetterParams) =>
        `${
          (params.row as CBOShoppableVideoListItemReadModel).videoDuration
            ? secondsToFormatedTime(params.row.videoDuration / 1000)
            : '-'
        }`,
    },
    {
      ...defaultHeaderProps,
      cellClassName: classes.boldCell,
      field: 'status',
      headerName: t('Status.Status'),
      renderCell: renderShoppableStatusCell,
    },
    {
      align: 'center',
      field: '',
      headerClassName: classes.columnHeader,
      headerName: '',
      minWidth: 150,
      renderCell: renderShoppableActionsCell,
      sortable: false,
    },
  ];

  return (
    <ShoppableListContainer>
      <ShoppableListRoot
        columns={columns}
        components={{
          LoadingOverlay: CustomLoadingGridOverlay,
        }}
        disableColumnMenu
        disableSelectionOnClick
        getCellClassName={() => classes.cell}
        getRowClassName={() => classes.gridRow}
        hideFooter
        hideFooterPagination
        paginationMode="server"
        rowHeight={100}
        rows={defaultSortedShoppables}
      />
      <DisplayMenu
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        elevation={0}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <DisplayMenuItem onClick={handleShoppableNewMediaCollectionClick}>
          {t('NewMediaCollection')} <AddIcon />
        </DisplayMenuItem>
        {availableMediaCollectionsForCurrentShoppableId.length > 0 && (
          <DisplayMenuItem onClick={handleShoppableAddToMediaCollectionClick}>
            {t('AddToMediaCollection')} <ShoppablesIcon sx={{ color: '#000' }} />
          </DisplayMenuItem>
        )}
      </DisplayMenu>
    </ShoppableListContainer>
  );
};

export default ShoppableList;
