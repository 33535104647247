import { z } from 'zod';
import { MessageId, MessageSchema, MessageTypes, SerializableMessage } from '../Message';
import { StringSchema } from '../../shared';

export const ShopifySubscriptionCancelledDataSchema = z.object({
  storeId: StringSchema,
  subscriptionId: StringSchema
});


const ShopifySubscriptionCancelledMessageSchema = MessageSchema.extend({data: ShopifySubscriptionCancelledDataSchema});

export type ShopifySubscriptionCancelledData = z.infer<typeof ShopifySubscriptionCancelledDataSchema>;
export type ShopifySubscriptionCancelledMessage = z.infer<typeof ShopifySubscriptionCancelledMessageSchema>;

export class ShopifySubscriptionCancelled implements SerializableMessage<ShopifySubscriptionCancelledData> {
  constructor(public readonly id: MessageId, private time: string, private data: ShopifySubscriptionCancelledData) {}

  toJSON(): ShopifySubscriptionCancelledMessage {
    return {
      data: this.data,
      datacontenttype: 'application/json;charset=utf-8',
      id: this.id,
      partitionkey: this.data.storeId,
      source: 'shopify',
      time: this.time,
      type: MessageTypes.Shopify_SubscriptionCancelled,
    };
  }
}
