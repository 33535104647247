import { Cancel } from '@mui/icons-material';
import { Button, ButtonProps, alpha, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

type CloseButtonProps = Omit<ButtonProps, 'startIcon' | 'variant'>;

const StyledButton = styled(Button)(({ theme }) => ({
  [`&:hover`]: {
    borderColor: alpha(theme.palette.common.black, 0.5),
  },
  border: `solid 1px ${alpha(theme.palette.common.black, 0.1)}`,
  borderRadius: '10px',
  color: theme.palette.common.black,
  padding: `${theme.spacing(0.75)} ${theme.spacing(2)}`,
  textTransform: 'initial',
}));

export default function CloseButton(props: CloseButtonProps) {
  const { t } = useTranslation('common');
  return (
    <StyledButton
      {...props}
      startIcon={<Cancel sx={{ color: (theme) => theme.palette.error.light }} />}
      variant="outlined"
    >
      {t('Close')}
    </StyledButton>
  );
}
