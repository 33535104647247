import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlayIcon from '@mui/icons-material/PlayArrow';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Switch,
  Typography,
  accordionSummaryClasses,
  styled,
} from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventListReadModel } from '../../../../core/domain/CBOEventListReadModel';
import '../../../../core/domain/CBOMediaCollectionMedia';
import {
  CBOMediaCollectionMedia,
  eventListReadModelToMediaCollectionMedia,
  replayChapterToMediaCollectionMedia,
  shoppableVideoListItemReadModelToMediaCollectionMedia,
} from '../../../../core/domain/CBOMediaCollectionMedia';
import { CBOReplayChapter } from '../../../../core/domain/CBOReplaysChapters';
import {
  CBOShoppableVideoListItemReadModel,
  CBOShoppableVideoListReadModel,
} from '../../../../core/domain/CBOShoppableVideoReadModel';
import { secondsToFormatedTime } from '../../../../utils/date-format';
import CreateButton from '../../atoms/CreateButton';

const MediaCollectionItemListContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: theme.spacing(2),
  minHeight: '100%',
}));

const MediaCollectionMediaListRoot = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  width: '100%',
});

const MediaCollectionMediaDetails = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
  height: '80px',
  width: '100%',
}));

const MediaCollectionMediaThumbnailContainer = styled(Box)({
  aspectRatio: '1/1',
  borderRadius: '50%',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
});

const MediaCollectionMediaThumbnail = styled('img')({
  height: '80px',
  objectFit: 'cover',
  width: '80px',
});

const MediaCollectionMediaLabelContainer = styled(Box)({
  alignItems: 'start',
  display: 'flex',
  flexDirection: 'column',
});

const NoContentContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

// TODO: Theme colors
const PlayIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.35)',
  },
  background: 'rgba(0, 0, 0, 0.2)',
  color: theme.palette.common.white,
  height: '28px',
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '28px',
}));

const StyledAccordion = styled(Accordion)({
  [`&:before`]: {
    display: 'none',
  },

  border: 'none',
});

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  minHeight: 0,

  [`& .${accordionSummaryClasses.root}`]: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },

  [`& .${accordionSummaryClasses.content}`]: {
    margin: 0,
  },

  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    left: `-${theme.spacing(2)}`,
    position: 'absolute',
  },
}));

export type MediaCollectionMediaListProps = {
  maxItems: number;
  onGoToEventCreationPage: () => void;
  onGoToShoppableCreationPage: () => void;
  onMediaPreviewClick: (media: CBOMediaCollectionMedia) => void;
  onSelectMedia: (media: CBOMediaCollectionMedia, isChecked: boolean) => void;
  selectedMedias: CBOMediaCollectionMedia[];
} & (
  | { replays: CBOEventListReadModel[]; type: 'replays' }
  | { shoppables: CBOShoppableVideoListReadModel; type: 'shoppables' }
);

const MediaCollectionMediaList: FC<MediaCollectionMediaListProps> = (props) => {
  const { t } = useTranslation('mediaCollections');
  const {
    maxItems,
    onGoToEventCreationPage,
    onGoToShoppableCreationPage,
    onMediaPreviewClick,
    onSelectMedia,
    selectedMedias,
    type,
  } = props;

  const selectedMediasIds = selectedMedias.map(({ id }) => id);

  const [expandedReplays, setExpandedReplays] = useState<string[]>([]);

  useEffect(() => {
    const ids: string[] = [];

    selectedMedias.forEach((selectedMedia) => {
      if (selectedMedia.type === 'replay-chapter') {
        if (!ids.includes(selectedMedia.replayId)) {
          ids.push(selectedMedia.replayId);
        }
      }
    });

    setExpandedReplays(ids);
  }, []);

  const handleAccordionChange = (replayId: string, isExpanded: boolean) => {
    if (isExpanded) {
      setExpandedReplays([...expandedReplays, replayId]);
    } else {
      setExpandedReplays(expandedReplays.filter((id) => id !== replayId));
    }
  };

  const handleMediaPreviewClick = useCallback(
    (media: CBOMediaCollectionMedia) => () => {
      onMediaPreviewClick(media);
    },
    [onMediaPreviewClick],
  );

  const renderShoppable = useCallback(
    (shoppable: CBOShoppableVideoListItemReadModel) => {
      const isSelected = selectedMediasIds.includes(shoppable.id);
      const media = shoppableVideoListItemReadModelToMediaCollectionMedia(shoppable);

      return (
        <MediaCollectionMediaListRoot key={shoppable.id}>
          <MediaCollectionMediaDetails>
            {shoppable.thumbnailUrl && (
              <MediaCollectionMediaThumbnailContainer>
                <MediaCollectionMediaThumbnail src={shoppable.thumbnailUrl} />
                <PlayIconButton onClick={handleMediaPreviewClick(media)}>
                  <PlayIcon />
                </PlayIconButton>
              </MediaCollectionMediaThumbnailContainer>
            )}
            <MediaCollectionMediaLabelContainer>
              <Typography fontWeight="bold">{shoppable.title}</Typography>
              {!!shoppable.videoDuration && (
                <Typography>{secondsToFormatedTime(shoppable.videoDuration / 1000)}</Typography>
              )}
            </MediaCollectionMediaLabelContainer>
          </MediaCollectionMediaDetails>
          <Box>
            <Switch
              checked={isSelected}
              disabled={selectedMediasIds.length === maxItems && !isSelected}
              onChange={() => onSelectMedia(media, !isSelected)}
            />
          </Box>
        </MediaCollectionMediaListRoot>
      );
    },
    [selectedMediasIds],
  );

  const renderReplayChapter = useCallback(
    (chapter: CBOReplayChapter) => {
      const isChapterSelected = selectedMediasIds.includes(chapter.id);
      const durationInSeconds = (chapter.endAt - chapter.startAt) / 1000;
      const media = replayChapterToMediaCollectionMedia(chapter);

      return (
        <MediaCollectionMediaListRoot key={chapter.id}>
          <MediaCollectionMediaDetails>
            {!!chapter.image && (
              <MediaCollectionMediaThumbnailContainer>
                <MediaCollectionMediaThumbnail src={chapter.image} />
                <PlayIconButton onClick={handleMediaPreviewClick(media)}>
                  <PlayIcon />
                </PlayIconButton>
              </MediaCollectionMediaThumbnailContainer>
            )}
            <MediaCollectionMediaLabelContainer>
              <Typography fontWeight="bold">{chapter.title}</Typography>
              <Typography>{secondsToFormatedTime(durationInSeconds)}</Typography>
            </MediaCollectionMediaLabelContainer>
          </MediaCollectionMediaDetails>
          <Box>
            <Switch
              checked={isChapterSelected}
              disabled={selectedMediasIds.length === maxItems && !isChapterSelected}
              onChange={() => onSelectMedia(media, !isChapterSelected)}
            />
          </Box>
        </MediaCollectionMediaListRoot>
      );
    },
    [selectedMediasIds],
  );

  const renderReplay = useCallback(
    (replay: CBOEventListReadModel) => {
      const isReplaySelected = selectedMediasIds.includes(replay.id);
      const isExpanded = expandedReplays.includes(replay.id);
      const hasChapters = replay.chapters.length > 0;
      const media = eventListReadModelToMediaCollectionMedia(replay);

      return (
        <StyledAccordion
          disableGutters
          expanded={isExpanded}
          key={replay.id}
          onChange={() => (hasChapters ? handleAccordionChange(replay.id, !isExpanded) : null)}
          variant="outlined"
        >
          <StyledAccordionSummary expandIcon={hasChapters && <ArrowDropDownIcon />}>
            <MediaCollectionMediaListRoot key={replay.id}>
              <MediaCollectionMediaDetails>
                {!!replay.image && (
                  <MediaCollectionMediaThumbnailContainer>
                    <MediaCollectionMediaThumbnail src={replay.image} />
                    <PlayIconButton onClick={handleMediaPreviewClick(media)}>
                      <PlayIcon />
                    </PlayIconButton>
                  </MediaCollectionMediaThumbnailContainer>
                )}
                <MediaCollectionMediaLabelContainer>
                  <Typography fontWeight="bold">{replay.title}</Typography>
                  {!!replay.videoDuration && (
                    <Typography>{secondsToFormatedTime(replay.videoDuration / 1000)}</Typography>
                  )}
                </MediaCollectionMediaLabelContainer>
              </MediaCollectionMediaDetails>
              <Box>
                <Switch
                  checked={isReplaySelected}
                  disabled={selectedMediasIds.length === maxItems && !isReplaySelected}
                  onChange={() => onSelectMedia(media, !isReplaySelected)}
                />
              </Box>
            </MediaCollectionMediaListRoot>
          </StyledAccordionSummary>
          <AccordionDetails>
            {/* TODO: Styled component */}
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              p={2}
              sx={{ backgroundColor: (theme) => theme.palette.background.default }}
            >
              {replay.chapters.map(renderReplayChapter)}
            </Box>
          </AccordionDetails>
        </StyledAccordion>
      );
    },
    [selectedMediasIds, expandedReplays],
  );

  const renderContent = () => {
    if (type === 'replays') {
      if (props.replays.length === 0) {
        return (
          <NoContentContainer>
            <Typography>{t('NoReplaysFound')}</Typography>
            <CreateButton onClick={() => onGoToEventCreationPage()} sx={{ mt: 1 }} text="Create event" />
          </NoContentContainer>
        );
      }
      return props.replays.map(renderReplay);
    }

    if (props.shoppables.length === 0) {
      return (
        <NoContentContainer>
          <Typography>{t('NoShoppableVideosFound')}</Typography>
          <CreateButton onClick={() => onGoToShoppableCreationPage()} sx={{ mt: 1 }} text="Create shoppable video" />
        </NoContentContainer>
      );
    }

    return props.shoppables.map(renderShoppable);
  };

  return <MediaCollectionItemListContainer>{renderContent()}</MediaCollectionItemListContainer>;
};

export default MediaCollectionMediaList;
