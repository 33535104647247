import { z } from 'zod';
import { StringSchema, URLSchema, StringNonEmptySchema } from '../../shared/schemas';

export const RestreamingPlatformSchema = z.enum([
  'Facebook',
  'Facebook_RTMP',
  'Instagram',
  'Other_RTMP_Source',
  'Youtube',
  'Youtube_RTMP',
]);
export const RestreamingConfigurationDTOSchema = z.discriminatedUnion('enabled', [
  z.object({
    enabled: z.literal(true),
    expiresAt: z.string().datetime().nullable(),
    key: StringNonEmptySchema,
    type: RestreamingPlatformSchema,
    url: URLSchema,
  }),
  z.object({
    enabled: z.literal(false),
    expiresAt: z.null(),
    key: StringSchema,
    type: RestreamingPlatformSchema,
    url: StringSchema,
  }),
]);

export const FacebookRestreamingAccountType = ['PAGE', 'USER'] as const;
export const FacebookRestreamingAccountTypeSchema = z.enum(FacebookRestreamingAccountType);
export const FacebookRestreamingConfigurationDTOSchema = z.object({
  accessToken: StringSchema,
  expiresAt: z.string().datetime(),
  id: StringSchema,
  name: StringSchema,
  picture: StringSchema,
  type: FacebookRestreamingAccountTypeSchema,
});

export const YoutubeRestreamingConfigurationDTOSchema = z.object({
  accessToken: StringSchema,
  expiresAt: z.string().datetime(),
  id: StringSchema,
  picture: StringSchema,
  refreshToken: StringSchema,
  title: StringSchema,
});

export type FacebookRestreamingConfigurationDTO = z.infer<typeof FacebookRestreamingConfigurationDTOSchema>;
export type FacebookRestreamingAccountType = z.infer<typeof FacebookRestreamingAccountTypeSchema>;
export type RestreamingPlatform = z.infer<typeof RestreamingPlatformSchema>;
export type RestreamingConfigurationDTO = z.infer<typeof RestreamingConfigurationDTOSchema>;
export type YoutubeRestreamingConfigurationDTO = z.infer<typeof YoutubeRestreamingConfigurationDTOSchema>;
