import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface ConfirmationDialogRawProps {
  confirm?: string;
  content?: React.ReactNode;
  id: string;
  keepMounted: boolean;
  loading?: boolean;
  onClose: (yesOrNo: boolean) => void;
  open: boolean;
  refuse?: string;
  title: string;
}

export default function ConfirmationDialog(props: ConfirmationDialogRawProps) {
  const { content, confirm, onClose, loading, open, refuse, title, ...other } = props;
  const { t } = useTranslation('common');

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  const renderProgress = () => {
    if (loading) {
      return (
        <LinearProgress
          style={{
            marginTop: '-4px',
          }}
        />
      );
    }
  };

  return (
    <Dialog
      aria-labelledby="confirmation-dialog-title"
      disableEscapeKeyDown
      fullWidth
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText whiteSpace="pre-line">{content ?? t('AreYouSure')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="primary" disabled={loading} onClick={handleCancel} variant="text">
          {refuse ?? t('No')}
        </Button>
        <Button color="primary" disabled={loading} onClick={handleOk} variant="contained">
          {confirm ?? t('Yes')}
        </Button>
      </DialogActions>
      {renderProgress()}
    </Dialog>
  );
}
