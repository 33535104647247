import { Flavor } from '@bellepoque/shared';
import { z } from 'zod';

export type TenantId = Flavor<string, 'Tenant'>;

export const Cms = {
  'Custom': 'CUSTOM',
  'PrestaShop1.6': 'PRESTASHOP1.6',
  'PrestaShop1.7': 'PRESTASHOP1.7',
  'PrestaShop8': 'PRESTASHOP8',
  'SFCC': 'SFCC',
  'Shopify': 'SHOPIFY',
  'Unknown': 'UNKNOWN',
  'WooCommerce': 'WOOCOMMERCE',
} as const;

export const CmsSchema = z.nativeEnum(Cms);
export type TCms = z.infer<typeof CmsSchema>;

export const CMS_WITH_SALES_TRACKING: TCms[] = [Cms.Shopify];

export interface RenewApiKeyResponse {
  apiKey: string;
}
