import { z } from 'zod';
import { ShoppableVideoDTOSchema } from '../api/shoppable-videos';
import { StringNullableSchema, StringSchema, URLNullableSchema } from '../../shared';

export const CBOShoppableVideoListItemReadModelDTOSchema = ShoppableVideoDTOSchema.pick({
  createdAt: true,
  id: true,
  status: true,
  thumbnailUrl: true,
  title: true,
  videoDuration: true,
  videoUrl: true,
}).merge(
  z.object({
    productId: z.string().uuid(),
    productImage: URLNullableSchema,
    productOriginalId: StringSchema,
    productTitle: z.string(),
  }),
);

export type CBOShoppableVideoListItemReadModelDTO = z.infer<typeof CBOShoppableVideoListItemReadModelDTOSchema>;
export type CBOShoppableVideoListReadModelDTO = CBOShoppableVideoListItemReadModelDTO[];
