import { z } from 'zod';

export const StringSchema = z.string().trim();
export const StringNonEmptySchema = z.string().trim().nonempty();
export const StringNullableSchema = StringSchema.nullable();
export const URLSchema = z.string().trim().url();
export const URLNullableSchema = URLSchema.nullable();

export const UUIDSchema = StringSchema.uuid();
export const UUIDNullableSchema = UUIDSchema.nullable();

const SafeFloatSchema = z.number().finite().safe();
const SafeIntegerSchema = SafeFloatSchema.int()
export const SafeNonNegativeIntegerSchema = SafeIntegerSchema.nonnegative();
export const SafeNonNegativeFloatSchema = SafeFloatSchema.nonnegative();