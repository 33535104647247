import { Box, styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

import { CBOMediaCollection } from '../../../core/domain/CBOMediaCollection';
import { CBOMediaCollectionMedia } from '../../../core/domain/CBOMediaCollectionMedia';
import { CommandStatus } from '../../../core/store/state/utils';
import FixedContentHeader from '../../components/molecules/FixedContentHeader';
import MediaCollectionsHeader from '../../components/molecules/media-collections/MediaCollectionsHeader';
import PlayerPreviewDialog, { PlayerPreviewParams } from '../../components/organisms/PlayerPreviewDialog';
import UpgradePlanDialog from '../../components/organisms/UpgradePlanDialog';
import MediaCollectionList from '../../components/organisms/media-collections/MediaCollectionList';
import AddPublishedPagesToMediaCollectionDialog from '../../components/organisms/media-collections/add-published-pages-to-media-collection-dialog/AddPublishedPagesToMediaCollectionDialog';

const PREFIX = 'MediaCollectionListGrid';

const classes = {
  gridRow: `${PREFIX}-grid-row`,
};

const MediaCollectionsTemplateRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  overflow: 'hidden',

  [`& .${classes.gridRow}`]: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.light}2B !important`,
      cursor: 'pointer',
    },
  },
}));

const MEDIA_COLLECTION_LIST_CONTAINER_OFFSET = 14;

const MediaCollectionListContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
  // be sure to maintain marginTop === paddingBottom for virtual scroll shoppables works properly
  marginTop: theme.spacing(MEDIA_COLLECTION_LIST_CONTAINER_OFFSET),
  paddingBottom: theme.spacing(MEDIA_COLLECTION_LIST_CONTAINER_OFFSET),
}));

export type MediaCollectionsTemplateProps = {
  currentMediaCollection: CBOMediaCollection | null;
  loading: boolean;
  maxPublishedShoppablesCount: number | null;
  mediaCollectionPagesUpdateStatus: CommandStatus;
  mediaCollections: CBOMediaCollection[];
  onFetchMediaCollection: (collectionId: string) => void;
  onGoToMediaCollectionEditor: () => void;
  publishedShoppablesCount: number;
  tenantName: string;
};

type CurrentlyPlayedMedia = PlayerPreviewParams & {
  videoId: string;
};

const MediaCollectionsTemplate: FC<MediaCollectionsTemplateProps> = ({
  currentMediaCollection,
  loading,
  maxPublishedShoppablesCount,
  mediaCollectionPagesUpdateStatus,
  mediaCollections,
  onFetchMediaCollection,
  onGoToMediaCollectionEditor,
  publishedShoppablesCount,
  tenantName,
}) => {
  const [isAddPublishedPagesToMediaCollectionDialogOpen, setIsAddPublishedPagesToMediaCollectionDialogOpen] =
    useState(false);
  const [isUpgradePlanDialogOpen, setIsUpgradePlanDialogOpen] = useState(false);
  const [currentlyPlayedMedia, setCurrentlyPlayedMedia] = useState<CurrentlyPlayedMedia | null>(null);
  const [isPlayerMediaCollectionModalOpen, setIsPlayerMediaCollectionModalOpen] = useState<boolean>(false);

  const handleCloseAddPublishedPagesToMediaCollectionDialog = () => {
    setIsAddPublishedPagesToMediaCollectionDialogOpen(false);
  };

  const handleOpenLocationsEditor = (mediaCollectionId: string) => {
    onFetchMediaCollection(mediaCollectionId);
    setIsAddPublishedPagesToMediaCollectionDialogOpen(true);
  };

  const handleDisplayUpdgradePlanDialog = () => {
    setIsUpgradePlanDialogOpen(true);
  };

  const handleCloseUpgradePlanDialog = () => {
    setIsUpgradePlanDialogOpen(false);
  };

  const handleMediaPreviewClick = (media: CBOMediaCollectionMedia) => {
    const videoId = media.type === 'replay-chapter' ? media.replayId : media.id;
    const startTimeStamp = media.type === 'replay-chapter' ? media.startTimeStamp / 1000 : undefined;
    const endTimeStamp = media.type === 'replay-chapter' ? media.endTimeStamp / 1000 : undefined;

    setCurrentlyPlayedMedia({ endTimeStamp, startTimeStamp, videoId });
    setIsPlayerMediaCollectionModalOpen(true);
  };

  useEffect(() => {
    if (mediaCollectionPagesUpdateStatus === 'success') handleCloseAddPublishedPagesToMediaCollectionDialog();
  }, [mediaCollectionPagesUpdateStatus]);

  return (
    <MediaCollectionsTemplateRoot>
      <FixedContentHeader isDrawerOpen isShadowHidden>
        <MediaCollectionsHeader
          loading={loading}
          maxPublishedShoppablesCount={maxPublishedShoppablesCount}
          onGoToMediaCollectionEditor={onGoToMediaCollectionEditor}
          publishedShoppablesCount={publishedShoppablesCount}
          tenantName={tenantName}
        />
      </FixedContentHeader>
      <MediaCollectionListContainer>
        <MediaCollectionList
          loading={loading}
          mediaCollections={mediaCollections}
          onGoToMediaCollectionEditor={onGoToMediaCollectionEditor}
          onMediaPreviewClick={handleMediaPreviewClick}
          onOpenPublishedPagesEditor={handleOpenLocationsEditor}
        />
      </MediaCollectionListContainer>

      {currentMediaCollection && (
        <AddPublishedPagesToMediaCollectionDialog
          isOpen={isAddPublishedPagesToMediaCollectionDialogOpen}
          onClose={handleCloseAddPublishedPagesToMediaCollectionDialog}
          onDisplayUpdgradePlanDialog={handleDisplayUpdgradePlanDialog}
        />
      )}

      {maxPublishedShoppablesCount && (
        <UpgradePlanDialog
          isOpen={isUpgradePlanDialogOpen}
          maxPublishedShoppablesCount={maxPublishedShoppablesCount}
          onClose={handleCloseUpgradePlanDialog}
          tenantName={tenantName}
        />
      )}

      {!!currentlyPlayedMedia?.videoId && (
        <PlayerPreviewDialog
          isCollection
          onClose={() => setIsPlayerMediaCollectionModalOpen(false)}
          open={isPlayerMediaCollectionModalOpen}
          {...currentlyPlayedMedia}
        />
      )}
    </MediaCollectionsTemplateRoot>
  );
};

export default MediaCollectionsTemplate;
