import { TenantId } from '@bellepoque/api-contracts';

import { CBOShoppableVideoThumbnailFileUploadUrl } from '../../domain/CBOShoppableVideo';
import {
  CBOShoppableVideoListItemReadModel,
  CBOShoppableVideoListReadModel,
} from '../../domain/CBOShoppableVideoReadModel';
import { CBOShoppablesSettings, CBOShoppablesSettingsFileUploadUrls } from '../../domain/CBOShoppablesSettings';
import { CommandResult, QueryResult } from './utils';

export interface ShoppablesCommandsQueriesState {
  fileUploadUrlFetching: QueryResult;
  fileUploading: CommandResult;
  playerPlaylistsBlockIsAddedFetching: QueryResult;
  shoppableCreation: CommandResult;
  shoppableDeletion: CommandResult;
  shoppableFetching: QueryResult;
  shoppablePublishing: CommandResult;
  shoppableThumbnailFileUploading: CommandResult;
  shoppableUnpublishing: CommandResult;
  shoppableVideoThumbnailFileUploadUrlFetching: QueryResult;
  shoppableVideoUpdate: CommandResult;
  shoppablesFetching: QueryResult;
  shoppablesSettingsFetching: QueryResult;
  shoppablesSettingsFileUploadUrlsFetching: QueryResult;
  shoppablesSettingsFileUploading: CommandResult;
  shoppablesSettingsUpdate: CommandResult;
}

export interface ShoppablesState extends ShoppablesCommandsQueriesState {
  currentShoppable: CBOShoppableVideoListItemReadModel | null;
  fileUploadUrl: string | null;
  playerPlaylistsBlockIsAdded: boolean;
  shoppableVideoThumbnailFileUploadUrl: CBOShoppableVideoThumbnailFileUploadUrl | null;
  shoppables: CBOShoppableVideoListReadModel;
  shoppablesSettings: CBOShoppablesSettings | null;
  shoppablesSettingsFileUploadUrls: CBOShoppablesSettingsFileUploadUrls | null;
  shoppablesTenantId: TenantId;
}

export const INITIAL_SHOPPABLES_STATE: ShoppablesState = {
  currentShoppable: null,
  fileUploadUrl: null,
  fileUploadUrlFetching: {
    errors: [],
    status: 'notLoaded',
  },
  fileUploading: {
    errors: [],
    status: 'notRequested',
  },
  playerPlaylistsBlockIsAdded: true,
  playerPlaylistsBlockIsAddedFetching: {
    errors: [],
    status: 'notLoaded',
  },
  shoppableCreation: {
    errors: [],
    status: 'notRequested',
  },
  shoppableDeletion: {
    errors: [],
    status: 'notRequested',
  },
  shoppableFetching: {
    errors: [],
    status: 'notLoaded',
  },
  shoppablePublishing: {
    errors: [],
    status: 'notRequested',
  },
  shoppableThumbnailFileUploading: {
    errors: [],
    status: 'notRequested',
  },
  shoppableUnpublishing: {
    errors: [],
    status: 'notRequested',
  },
  shoppableVideoThumbnailFileUploadUrl: null,
  shoppableVideoThumbnailFileUploadUrlFetching: {
    errors: [],
    status: 'notLoaded',
  },
  shoppableVideoUpdate: {
    errors: [],
    status: 'notRequested',
  },
  shoppables: [],
  shoppablesFetching: {
    errors: [],
    status: 'notLoaded',
  },
  shoppablesSettings: null,
  shoppablesSettingsFetching: {
    errors: [],
    status: 'notLoaded',
  },
  shoppablesSettingsFileUploadUrls: null,
  shoppablesSettingsFileUploadUrlsFetching: {
    errors: [],
    status: 'notLoaded',
  },

  shoppablesSettingsFileUploading: {
    errors: [],
    status: 'notRequested',
  },
  shoppablesSettingsUpdate: {
    errors: [],
    status: 'notRequested',
  },
  shoppablesTenantId: '',
};
