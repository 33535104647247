import { z } from 'zod';
import { StringNonEmptySchema, StringSchema, URLNullableSchema, URLSchema, UUIDSchema } from '../../shared/schemas';
import { Flavor } from '@bellepoque/shared';

export type ShoppableVideoId = Flavor<string, 'ShoppableVideo'>;

export const constraints = {
  title: {
    minLength: 1,
    maxLength: 30,
  },
};

export const CreateShoppableVideoDTOSchema = z.object({
  id: UUIDSchema,
  productId: UUIDSchema,
  title: StringNonEmptySchema.min(constraints.title.minLength).max(constraints.title.maxLength),
});

export const ShoppableVideoStatus = [
  'DRAFT',
  'OPTIMIZATION_FAILED',
  'OPTIMIZING',
  'PUBLISHED',
  'PUBLISHING',
  'READY',
  'UNPUBLISHING',
] as const;
export const ShoppableVideoStatusSchema = z.enum(ShoppableVideoStatus);

export const ShoppableVideoDTOSchema = z.object({
  createdAt: z.string().datetime(),
  id: UUIDSchema,
  productId: UUIDSchema,
  status: ShoppableVideoStatusSchema,
  tenantId: UUIDSchema,
  thumbnailUrl: URLSchema.nullable(),
  title: StringNonEmptySchema,
  videoDuration: z.number().nullable(),
  videoUrl: URLNullableSchema,
});

export const ShoppableVideoThumbnailFileUploadUrlDTOSchema = z.object({
  url: URLSchema,
});

export const ShoppableVideoFileUploadUrlDTOSchema = z.object({
  url: URLSchema,
});

export const ShoppableVideoFileUploadCompletedDTOSchema = z.object({
  fileUrl: URLSchema,
});

export const ShoppableThemeFileUploadUrlsDTOSchema = z.object({
  brandLogoUrl: URLSchema,
});

export const ShoppablesSettingsDTOSchema = z.object({
  theme: z.object({
    addToCartBackgroundColor: StringSchema,
    brandLogoUrl: URLSchema,
    mainFontName: StringSchema,
  }),
});

export const UpdateShoppableVideoDTOSchema = z.object({
  title: StringNonEmptySchema,
  thumbnailUploaded: z.boolean(),
});

export const UpdateShoppablesSettingsDTOSchema = z.object({
  theme: z.object({
    addToCartBackgroundColor: StringSchema,
    brandLogoUploaded: z.boolean(),
    mainFontName: StringSchema,
  }),
});

export type CreateShoppableVideoDTO = z.infer<typeof CreateShoppableVideoDTOSchema>;
export type ShoppablesSettingsDTO = z.infer<typeof ShoppablesSettingsDTOSchema>;
export type ShoppableThemeFileUploadUrlsDTO = z.infer<typeof ShoppableThemeFileUploadUrlsDTOSchema>;
export type ShoppableVideoDTO = z.infer<typeof ShoppableVideoDTOSchema>;
export type ShoppableVideoFileUploadCompletedDTO = z.infer<typeof ShoppableVideoFileUploadCompletedDTOSchema>;
export type ShoppableVideoFileUploadUrlDTO = z.infer<typeof ShoppableVideoFileUploadUrlDTOSchema>;
export type ShoppableVideoStatus = z.infer<typeof ShoppableVideoStatusSchema>;
export type ShoppableVideoThumbnailFileUploadUrlDTO = z.infer<typeof ShoppableVideoThumbnailFileUploadUrlDTOSchema>;
export type UpdateShoppablesSettingsDTO = z.infer<typeof UpdateShoppablesSettingsDTOSchema>;
export type UpdateShoppableVideoDTO = z.infer<typeof UpdateShoppableVideoDTOSchema>;
