import { AppDispatch, State } from '../../../../core/store';
import { resetAddProductsToEvent } from '../../../../core/store/slices/catalog.slice';
import { addCatalogProductsToEvent } from '../../../../core/usecases/catalog/add-catalog-products-to-event';
import { fetchEvents } from '../../../../core/usecases/event/crud/fetch-events';
import { hasBeenLiveEvent } from '../../../../utils/has-been-live-event';

export const createAddSelectedProductsToEventDialogViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const { currency, id: tenantId } = state.tenants.currentTenant;
    const addProductsToEventStatus = state.catalog.addProductsToEvent.status;
    const events = state.events.events;
    const eventsFetchingStatus = state.events.eventsFetching.status;
    const notOpenedToPublicEvents = events.filter(({ status }) => !hasBeenLiveEvent(status));

    return {
      addProductsToEvent: (productsIds: string[], eventId: string) => {
        dispatch(addCatalogProductsToEvent({ eventId, productsIds }));
      },
      addProductsToEventStatus,
      currency,
      fetchEvents: () => {
        dispatch(fetchEvents({ eventsFetchingStatus, tenantId }));
      },
      isFetchingEvents: eventsFetchingStatus === 'pending',
      notOpenedToPublicEvents,
      resetAddProductsToEvent: () => {
        dispatch(resetAddProductsToEvent());
      },
      tenantId,
    };
  };
