import { TenantId, V1 } from '@bellepoque/api-contracts';
import { firstValueFrom } from 'rxjs';

import {
  CreateMediaCollectionParams,
  FetchOneMediaCollectionParams,
  MediaCollectionsGateway,
  UpdateMediaCollectionPagesParams,
  UpdateMediaCollectionSettingsParams,
} from '../../core/gateways/media-collections-gateway';
import { ApiGateway } from '../ApiGateway';

export class ApiMediaCollectionsGateway extends ApiGateway implements MediaCollectionsGateway {
  async createMediaCollection({ dto, tenantId }: CreateMediaCollectionParams): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: dto,
        url: `${this.apiEndpoint}/tenants/${tenantId}/media-collections`,
        verb: 'post',
      }),
    );
  }

  fetchOne({ mediaCollectionId, tenantId }: FetchOneMediaCollectionParams): Promise<V1.api.MediaCollectionDTO> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.MediaCollectionDTO>({
        url: `${this.apiEndpoint}/tenants/${tenantId}/media-collections/${mediaCollectionId}`,
      }),
    );
  }

  fetchMany(tenantId: TenantId): Promise<V1.api.MediaCollectionDTO[]> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.MediaCollectionDTO[]>({
        url: `${this.apiEndpoint}/tenants/${tenantId}/media-collections`,
      }),
    );
  }

  async updateMediaCollectionSettings({
    mediaCollectionId,
    dto,
    tenantId,
  }: UpdateMediaCollectionSettingsParams): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand<void>({
        body: dto,
        url: `${this.apiEndpoint}/tenants/${tenantId}/media-collections/${mediaCollectionId}`,
        verb: 'put',
      }),
    );
  }

  async updateMediaCollectionPages({
    mediaCollectionId,
    dto,
    tenantId,
  }: UpdateMediaCollectionPagesParams): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: dto,
        url: `${this.apiEndpoint}/tenants/${tenantId}/media-collections/${mediaCollectionId}/pages`,
        verb: 'put',
      }),
    );
  }
}
