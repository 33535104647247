import { z } from 'zod';

import { allowedMessageSources, allowedDataContentTypes, MessageTypesSchema } from '../messaging/Message';
import { CoerceNotNullDateSchema, CoerceNullableDateSchema } from '../utils';

export const OutboxMessageSchema = z.object({
  data: z.record(z.string(), z.any()),
  datacontenttype: z.enum(allowedDataContentTypes),
  id: z.string().uuid(),
  partitionkey: z.string().optional(),
  source: z.enum(allowedMessageSources),
  time: z.string().datetime(),
  type: MessageTypesSchema,
});

export type OutboxMessage = z.infer<typeof OutboxMessageSchema>;

export const OutboxItemStateSchema = z.object({
  createdAt: CoerceNotNullDateSchema,
  message: OutboxMessageSchema,
  id: z.string().uuid(),
  sentAt: CoerceNullableDateSchema,
});

export type OutboxItemState = z.infer<typeof OutboxItemStateSchema>;

export const NotYetSentOutboxItemStateSchema = OutboxItemStateSchema.merge(
  z.object({
    sentAt: z.null(),
  }),
);
export type NotYetSentOutboxItemState = z.infer<typeof NotYetSentOutboxItemStateSchema>;

export const SentOutboxItemStateSchema = OutboxItemStateSchema.merge(
  z.object({
    sentAt: z.date(),
  }),
);
export type SentOutboxItemState = z.infer<typeof SentOutboxItemStateSchema>;
