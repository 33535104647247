import { UUIDSchema, StringSchema } from '../../shared';
import { z } from 'zod';

export const BaseMagicContentDTOSchema = z.object({
  createdAt: z.string().datetime(),
  id: StringSchema,
  mediaId: UUIDSchema,
  tenantId: UUIDSchema,
});

export const MagicContentPendingDTOSchema = BaseMagicContentDTOSchema.extend({
  status: z.literal('pending'),
});
export const MagicContentSucceedDTOSchema = BaseMagicContentDTOSchema.extend({
  content_html: StringSchema,
  meta_description_snippet: StringSchema,
  title: StringSchema,
  status: z.literal('succeed'),
  updatedAt: z.string().datetime(),
});
export const MagicContentFailedDTOSchema = BaseMagicContentDTOSchema.extend({
  error: z.string(),
  status: z.literal('failed'),
  updatedAt: z.string().datetime(),
});

export const MagicContentDTOSchema = z.discriminatedUnion('status', [
  MagicContentPendingDTOSchema,
  MagicContentSucceedDTOSchema,
  MagicContentFailedDTOSchema,
]);

export const GenerateMagicContentResponseDTOSchema = z.object({
  id: UUIDSchema,
});

export const GetMagicContentsDTOSchema = z.object({
  magicContents: z.array(MagicContentDTOSchema),
  transcriptAvailable: z.boolean(),
});

export type GenerateMagicContentResponseDTO = z.infer<typeof GenerateMagicContentResponseDTOSchema>;
export type MagicContentPendingDTO = z.infer<typeof MagicContentPendingDTOSchema>;
export type MagicContentSucceedDTO = z.infer<typeof MagicContentSucceedDTOSchema>;
export type MagicContentFailedDTO = z.infer<typeof MagicContentFailedDTOSchema>;

export type MagicContentDTO = z.infer<typeof MagicContentDTOSchema>;

export type GetMagicContentsDTO = z.infer<typeof GetMagicContentsDTOSchema>;
