export default {
  AddProduct: {
    SelectProduct: 'Select your product',
  },
  AddToMediaCollection: 'Add to playlist',
  BrandLogo: 'Brand Logo',
  CannotPublishModal: {
    Text: 'You already have a shoppable video published for this product. Please unpublish it first and come back here to display this new one instead. Publishing multiple videos per product page will be released soon!',
    Title: `You can't publish this shoppable video`,
  },
  CopyVideoId: 'Copy video ID',
  CreateShoppable: 'Create shoppable',
  Delete: 'Delete',
  Duplicate: 'Duplicate',
  Duration: 'Duration',
  Edit: 'Edit',
  EditShoppable: 'Edit shoppable',
  Helpers: {
    Font: 'Select your brand’s font that will be loaded in our video player',
    ShoppableHeaderLogo: 'Upload your logo that will show up in the top left corner of the Shoppable Videos',
  },
  NewMediaCollection: 'New playlist',
  NewShoppable: 'New shoppable',
  OptimizingDescription: 'Optimization may take over a minute.',
  PlayerPlaylistsBlockModal: {
    Line1: '1. Go to your',
    Line2: '2. Select your product template.',
    Line3: `3. Activate and position the <Underlined>Player playlists</Underlined> block.`,
    Line4: '4. Save and come back here.',
    ShopifyThemeEditor: 'Shopify Theme Editor',
    Title: 'Enable block on your Store',
    Tutorial: `If needed, you can find a video tutorial <Link> here.</Link>`,
  },
  Preview: 'Preview',
  PreviewTitle: 'Video title',
  Product: 'Product',
  PublishedShoppablesCount: '{{count}}/{{maxCount}} Shoppables on-site',
  Settings: 'Shoppable Video Settings',
  Shoppable: 'Shoppable',
  ShoppableVideoManager: 'Shoppable Video Manager',
  Status: {
    Draft: 'Draft',
    NotPublished: 'Not displayed',
    OptimizationFailed: 'Optimization failed',
    Optimizing: 'Optimizing',
    Published: 'Displayed',
    Ready: 'Ready',
    Status: 'Status',
  },
  Thumbnail: 'Thumbnail',
  Title: 'Title',
  TitleIsRequired: 'Title is required',
  TitleLabel: 'Name your shoppable',
  UploadImage: {
    Action: 'Upload image',
  },
  UploadVideo: {
    Action: 'Select',
    Description: 'Drag and drop your video here*',
    HelperText: '*Vertical format video only',
    ImportDescription: 'Import from your mobile media library.',
    ImportVideo: 'Import video',
    UploadFromMobile: 'Upload from your mobile',
  },
  Video: 'Video',
  notifications: {
    ShoppableFailedCreation: 'An error occurred while creating the shoppable. Please retry.',
    ShoppableFailedDeleting: 'An error occurred while deleting the shoppable. Please retry.',
    ShoppableFailedPublishing: 'An error occurred while publishing the shoppable. Please retry.',
    ShoppableFailedUpdate: 'An error occurred while updating the shoppable. Please retry.',
    ShoppableIdSuccessfullyCopied: 'Shoppable video ID successfully copied to your clipboard!',
    ShoppableSuccessfullyCreated: 'Shoppable successfully created!',
    ShoppableSuccessfullyDeleted: 'Shoppable successfully deleted!',
    ShoppableSuccessfullyPublished: 'Shoppable successfully published!',
    ShoppableSuccessfullyUpdated: 'Shoppable successfully updated!',
    ShoppableVideoSuccessfullyImported: 'Video successfully imported!',
    ThumbnailConfigurationFailed: 'An error occurred while configuring the thumbnail. Please retry.',
  },
};
