import { MessageId, MessageTypes, SerializableMessage, MessageSchema } from '../Message';
import { z } from 'zod';
import { StringSchema } from '../../shared';

export const ShopifyAppUninstalledDataSchema = z.object({
  storeId: StringSchema,
});

const ShopifyAppUninstalledMessageSchema = MessageSchema.extend({ data: ShopifyAppUninstalledDataSchema });

export type ShopifyAppUninstalledData = z.infer<typeof ShopifyAppUninstalledDataSchema>;
export type ShopifyStoreUninstalledMessage = z.infer<typeof ShopifyAppUninstalledMessageSchema>;

export class ShopifyAppUninstalled implements SerializableMessage<ShopifyAppUninstalledData> {
  constructor(public readonly id: MessageId, private time: string, private data: ShopifyAppUninstalledData) {}

  toJSON(): ShopifyStoreUninstalledMessage {
    return {
      data: this.data,
      datacontenttype: 'application/json;charset=utf-8',
      id: this.id,
      partitionkey: this.data.storeId,
      source: 'shopify',
      time: this.time,
      type: MessageTypes.Shopify_AppUninstalled,
    };
  }
}
