import {
  Box,
  Dialog,
  DialogContent,
  LinearProgress,
  Table,
  TableBody,
  Theme,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CBOCatalogProduct } from '../../../../../core/domain/CBOCatalogProduct';
import { CBOEventReadModel, EVENT_PRODUCTS_COUNT_LIMIT } from '../../../../../core/domain/CBOEventReadModel';
import { FetchProductsFilters } from '../../../../../core/gateways/catalog-gateway';
import { State } from '../../../../../core/store';
import { clearSelectedProducts, resetAddProductsToEvent } from '../../../../../core/store/slices/catalog.slice';
import { CatalogSortType, EVENT_PRODUCTS_PAGINATION_LIMIT } from '../../../../../core/store/state/catalog';
import { addCatalogProductsToEvent } from '../../../../../core/usecases/catalog/add-catalog-products-to-event';
import { fetchProducts } from '../../../../../core/usecases/catalog/fetch-products';
import { selectProduct } from '../../../../../core/usecases/catalog/select-product';
import ActionsFooterWithPagination from '../../../molecules/event/product-settings/add-product-to-event-dialog/ActionsFooterWithPagination';
import ProductListActionsHeader from '../../../molecules/event/product-settings/add-product-to-event-dialog/ProductListActionsHeader';
import ProductRow, {
  rowHeight,
} from '../../../molecules/event/product-settings/add-product-to-event-dialog/ProductRow';
import TitleWithSelectedProductsCount from '../../../molecules/event/product-settings/add-product-to-event-dialog/TitleWithSelectedProductsCount';

interface AddProductsToEventDialogProps {
  event: CBOEventReadModel;
  isOpen: boolean;
  onClose: () => void;
}

const NUMBER_OF_ROWS = EVENT_PRODUCTS_PAGINATION_LIMIT;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  borderRadius: '20px',
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
}));

const ProductListContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: `${rowHeight * NUMBER_OF_ROWS}px`,
  paddingTop: theme.spacing(2),
}));

export default function AddProductsToEventDialog({ event, isOpen, onClose }: AddProductsToEventDialogProps) {
  const { t } = useTranslation('catalog');
  const dispatch = useDispatch();
  const { currentTenantId: tenantId, currentTenant } = useSelector((state: State) => state.tenants);
  const { currency } = currentTenant;
  const {
    products,
    selectedProducts,
    pagination: { offset, totalCount },
    search,
    sort,
  } = useSelector((state: State) => state.catalog);
  const { status: addProductsToEventStatus } = useSelector((state: State) => state.catalog.addProductsToEvent);
  const { status: productsFetchingStatus } = useSelector((state: State) => state.catalog.productsFetching);
  const eventId = event.id;
  const mdScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const currentPage = Math.floor(offset / EVENT_PRODUCTS_PAGINATION_LIMIT) + 1;
  const pageCount = Math.ceil(totalCount / EVENT_PRODUCTS_PAGINATION_LIMIT);

  useEffect(() => {
    changeFilters({ offset: 0, search: undefined });
  }, [tenantId, eventId]);

  useEffect(() => {
    if (productsFetchingStatus !== 'loaded') {
      changeFilters({ offset: 0, search: undefined });
    }
    dispatch(clearSelectedProducts());
  }, [tenantId, isOpen]);

  useEffect(() => {
    if (addProductsToEventStatus === 'success') {
      dispatch(resetAddProductsToEvent());
      changeFilters({});
      onClose();
    }
  }, [addProductsToEventStatus]);

  const eventProductsIds = useMemo(() => event.products.map(({ id }) => id), [event.products]);
  const selectedProductsIds = useMemo(() => selectedProducts.map(({ id }) => id), [selectedProducts]);
  const productsLoading = useMemo(() => productsFetchingStatus === 'pending', [productsFetchingStatus]);
  const productsNotInEvent = useMemo(
    () => products.filter(({ id }) => !eventProductsIds.includes(id)),
    [eventProductsIds, products],
  );

  const areAllProductsSelected = useMemo(() => {
    if (selectedProductsIds.length === 0 || productsNotInEvent.length === 0) return false;

    return productsNotInEvent.every((p) => selectedProductsIds.includes(p.id));
  }, [selectedProductsIds, productsNotInEvent]);

  const changeFilters = (newFilters: Partial<FetchProductsFilters>) => {
    const filters = { eventId, limit: EVENT_PRODUCTS_PAGINATION_LIMIT, offset, search, sort, tenantId };
    dispatch(fetchProducts({ ...filters, ...newFilters }));
  };

  const handleAddProductsToEvent = () => {
    if (selectedProducts.length > 0) {
      dispatch(addCatalogProductsToEvent({ eventId: event.id, productsIds: selectedProductsIds }));
    }
  };

  const handleChangePage = (page: number) => {
    const offset = (page - 1) * EVENT_PRODUCTS_PAGINATION_LIMIT;
    changeFilters({ offset });
  };

  const handleChangeSort = (sort: CatalogSortType) => {
    changeFilters({ sort });
  };

  const handleClearSelection = () => {
    dispatch(clearSelectedProducts());
  };

  const handleRefresh = () => {
    changeFilters({});
  };

  const handleSelectAll = () => {
    const shouldSelectProducts = !areAllProductsSelected;
    productsNotInEvent.forEach((product) => handleSelectProduct(product, shouldSelectProducts));
  };

  const handleSelectProduct = (product: CBOCatalogProduct, isSelected: boolean) => {
    dispatch(selectProduct({ isSelected, product }));
  };

  const handleSearch = (search: string) => {
    changeFilters({ offset: 0, search });
  };

  const isProductSelected = useCallback(
    (product: CBOCatalogProduct): boolean => selectedProducts.findIndex(({ id }) => product.id === id) !== -1,
    [selectedProducts],
  );

  const hasSelectedTooManyProducts = useMemo(
    () => selectedProducts.length + event.products.length > EVENT_PRODUCTS_COUNT_LIMIT,
    [selectedProducts.length, event.products.length],
  );

  return (
    <StyledDialog
      PaperProps={{ sx: { borderRadius: '20px', maxWidth: mdScreen ? '80%' : '60%' } }}
      fullWidth
      onClose={onClose}
      open={isOpen}
    >
      <TitleWithSelectedProductsCount
        onClearSelectedProducts={handleClearSelection}
        selectedProductsCount={selectedProducts.length}
      />
      <DialogContent>
        <ProductListActionsHeader
          areAllProductsSelected={areAllProductsSelected}
          currentSort={sort}
          onChangeSort={handleChangeSort}
          onRefresh={handleRefresh}
          onSearch={handleSearch}
          onSelectAll={handleSelectAll}
          searchValue={search}
        />
        <ProductListContainer>
          {!productsLoading && products.length === 0 && (
            <Box display="flex" flex={1} justifyContent="center">
              <Typography>{t('NoProducts')}</Typography>
            </Box>
          )}
          {!productsLoading && (
            <Table>
              <TableBody>
                {products.map((product, index) => (
                  <ProductRow
                    currency={currency}
                    index={index}
                    isAlreadyAdded={eventProductsIds.includes(product.id)}
                    isSelected={isProductSelected(product)}
                    key={product.id}
                    onAddToEvent={handleAddProductsToEvent}
                    onSelect={() => handleSelectProduct(product, true)}
                    onUnselect={() => handleSelectProduct(product, false)}
                    product={product}
                  />
                ))}
              </TableBody>
            </Table>
          )}
        </ProductListContainer>
      </DialogContent>
      <Box height="4px" visibility={productsLoading ? 'visible' : 'hidden'}>
        <LinearProgress />
      </Box>
      <ActionsFooterWithPagination
        currentPage={currentPage}
        hasSelectedTooManyProducts={hasSelectedTooManyProducts}
        isPaginationHidden={pageCount <= 1 || productsLoading}
        isSubmitButtonDisabled={
          selectedProducts.length === 0 || addProductsToEventStatus === 'pending' || hasSelectedTooManyProducts
        }
        onChangePage={handleChangePage}
        onClose={onClose}
        onSubmit={handleAddProductsToEvent}
        pageCount={pageCount}
      />
    </StyledDialog>
  );
}
