import { ContentCopy, Refresh, Search } from '@mui/icons-material';
import { Box, Button, Grid, InputAdornment, TextField, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommandStatus } from '../../../../core/store/state/utils';

interface ToolbarProps {
  canRenewApiKey: boolean;
  onRenewApiKey: () => void;
  onSearchProduct: (search: string) => void;
  renewApiKeyStatus: CommandStatus;
  tenantApiKey: string;
}

const Title = styled(Typography)(({ theme }) => ({
  color: 'black',
  fontSize: '2.5em',
  marginRight: theme.spacing(7),
  paddingBottom: theme.spacing(1),
}));

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(5),
  paddingTop: theme.spacing(2),
  textAlign: 'initial',
}));

export default function Toolbar({ canRenewApiKey, onRenewApiKey, onSearchProduct, tenantApiKey }: ToolbarProps) {
  const { t } = useTranslation('catalog');

  let debounceTimer: NodeJS.Timeout | null = null;

  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(tenantApiKey);
  };

  const handleSearch = (search: string) => {
    const callback = () => onSearchProduct(search);

    if (debounceTimer) clearTimeout(debounceTimer);

    debounceTimer = setTimeout(callback, 500);
  };

  return (
    <Container boxShadow={2}>
      <Grid container gap={3}>
        <Grid item xs={12}>
          <Title variant="h4">{t('Catalog')}</Title>
        </Grid>
        <Grid display="flex" item xs={12}>
          <Box>
            <TextField
              InputProps={{ style: { fontFamily: 'monospace', width: 380 } }}
              disabled
              label={t('ApiKey')}
              size="small"
              value={tenantApiKey}
            />
          </Box>
          <Box marginLeft="10px">
            <Button color="primary" onClick={handleCopyApiKey} startIcon={<ContentCopy />} variant="contained">
              <span>{t('common:Copy')}</span>
            </Button>
          </Box>
          {canRenewApiKey && (
            <Box marginLeft="10px">
              <Button color="primary" onClick={onRenewApiKey} startIcon={<Refresh />} variant="contained">
                <span>{t('RenewApiKey')}</span>
              </Button>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                style: { width: 625 },
              }}
              label={t('common:Search')}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              size="small"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
