import { z } from "zod";
import { StringSchema, UUIDSchema } from "./shared";
import { CoerceNotNullDateSchema, CoerceNullableDateSchema } from "./utils";

export const PinnedMessageSchema = z.object({
  content: StringSchema,
  id: UUIDSchema,
  messageId: StringSchema,
  pinnedAt: CoerceNotNullDateSchema,
  unpinnedAt: CoerceNullableDateSchema,
})

export const RealtimePinnedMessageSchema = PinnedMessageSchema.pick({
  content:true,
  messageId:true,
}).extend({
  pinnedAt: StringSchema.datetime(),
  unpinnedAt: StringSchema.datetime().nullable(),
})

export type PinnedMessage = z.infer<typeof PinnedMessageSchema>;
export type RealtimePinnedMessage = z.infer<typeof RealtimePinnedMessageSchema>;