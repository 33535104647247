import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, Checkbox, IconButton, MenuItem, Select, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCatalogProduct } from '../../../../core/domain/CBOCatalogProduct';
import { CatalogSortType } from '../../../../core/store/state/catalog';

const SelectedElementsText = styled('span')`
  color: #636161;
  font-weight: bold;
  font-size: 0.9em;
  text-transform: uppercase;
`;

interface ProductListHeaderProps {
  onAddToEvent: (products: CBOCatalogProduct[]) => void;
  onChangeSort: (sort: CatalogSortType) => void;
  onClearSelection: () => void;
  onDelete: (productsIds: string[]) => void;
  onRefresh: () => void;
  onSelectProduct: (product: CBOCatalogProduct) => void;
  onUnselectProduct: (product: CBOCatalogProduct) => void;

  products: CBOCatalogProduct[];
  selectedProducts: CBOCatalogProduct[];
  sort: CatalogSortType;
}

export default function ProductListHeader({
  onAddToEvent,
  onChangeSort,
  onClearSelection,
  onDelete,
  onRefresh,
  onSelectProduct,
  onUnselectProduct,
  products,
  selectedProducts,
  sort,
}: ProductListHeaderProps) {
  const { t } = useTranslation(['catalog', 'common', 'product']);
  const selectedProductsIds = selectedProducts.map(({ id }) => id);
  const sortValues: { label: string; value: string }[] = [
    {
      label: t('common:Sort.Label', {
        field: t('products:title_capitalized'),
        order: t('common:Sort.Order.asc_alpha'),
      }),
      value: 'product.title',
    },
    {
      label: t('common:Sort.Label', {
        field: t('products:title_capitalized'),
        order: t('common:Sort.Order.desc_alpha'),
      }),
      value: '-product.title',
    },
    {
      label: t('common:Sort.Label', { field: t('common:Price'), order: t('common:Sort.Order.asc') }),
      value: 'minPrice',
    },
    {
      label: t('common:Sort.Label', { field: t('common:Price'), order: t('common:Sort.Order.desc') }),
      value: '-minPrice',
    },
  ];

  const areAllProductsSelected = () => {
    return selectedProducts.length > 0 && products.every((p) => selectedProductsIds.includes(p.id));
  };

  const handleAddToEvent = () => {
    onAddToEvent(selectedProducts);
  };

  const handleDelete = () => {
    onDelete(selectedProductsIds);
  };

  const handleSelectAll = () => {
    products.forEach((p) => (areAllProductsSelected() ? onUnselectProduct(p) : onSelectProduct(p)));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        margin: '0 24px',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flex: 1,
        }}
      >
        <Box sx={{ ml: 2 }}>
          <Checkbox checked={areAllProductsSelected()} onChange={handleSelectAll} />
        </Box>

        <IconButton aria-label="delete" onClick={onRefresh} size="large">
          <RefreshIcon />
        </IconButton>

        <Box sx={{ ml: '10px' }}>
          <Button
            color="primary"
            disabled={selectedProducts.length === 0}
            onClick={handleAddToEvent}
            variant="contained"
          >
            <span>{t('AddToEvent')}</span>
          </Button>
        </Box>

        <Box sx={{ ml: '10px' }}>
          <Button color="error" disabled={selectedProducts.length === 0} onClick={handleDelete} variant="contained">
            <span>{t('common:Delete')}</span>
          </Button>
        </Box>

        <Box sx={{ ml: '10px' }}>
          <Select
            defaultValue={sort}
            onChange={(e) => onChangeSort(e.target.value as CatalogSortType)}
            size="small"
            style={{ height: '36.5px' }}
          >
            {sortValues.map(({ label, value }, i) => (
              <MenuItem key={i} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      {selectedProducts.length > 0 && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            gap: '15px',
            justifyContent: 'flex-end',
          }}
        >
          <Box>
            <SelectedElementsText>{t('SelectedItems', { count: selectedProducts.length })}</SelectedElementsText>
          </Box>
          <Box>
            <Button onClick={onClearSelection} startIcon={<ClearIcon />} variant="outlined">
              <span>{t('ClearSelection')}</span>
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
