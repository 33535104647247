const basePaths = {
  administration: '/administration',
  analytics: '/analytics',
  events: '/events',
  integrations: '/integrations',
  playlists: '/playlists',
  replays: '/replays',
};

type EventAndProductIdParams = EventIdParam & { productId: string };
type EventIdParam = { eventId: string };
type MediaCollectionIdParam = { mediaCollectionId: string };

export const routes = {
  administration: {
    createTenant: `${basePaths.administration}/create-tenant`,
    integrations: `${basePaths.administration}/integrations`,
    invitations: `${basePaths.administration}/invitations`,
    root: basePaths.administration,
    tools: `${basePaths.administration}/tools`,
  },
  analytics: {
    event: ({ eventId }: EventIdParam) => `${basePaths.analytics}/${eventId}`,
    liveAndReplays: `${basePaths.analytics}/live-and-replays`,
    root: basePaths.analytics,
    shoppableVideos: `${basePaths.analytics}/shoppable-videos`,
    traffic: `${basePaths.analytics}/traffic`,
    usage: `${basePaths.analytics}/usage`,
  },
  catalog: '/catalog',
  events: {
    details: ({ eventId }: EventIdParam) => `${basePaths.events}/${eventId}`,
    liveWithChat: ({ eventId }: EventIdParam) => `${basePaths.events}/${eventId}/live-with-chat`,
    multistream: ({ eventId }: EventIdParam) => `${basePaths.events}/${eventId}/multistream`,
    newProduct: ({ eventId }: EventIdParam) => `${basePaths.events}/${eventId}/products/new`,
    preview: ({ eventId }: EventIdParam) => `${basePaths.events}/${eventId}/preview`,
    product: ({ eventId, productId }: EventAndProductIdParams) =>
      `${basePaths.events}/${eventId}/products/${productId}`,
    products: ({ eventId }: EventIdParam) => `${basePaths.events}/${eventId}/products`,
    root: basePaths.events,
    settings: ({ eventId }: EventIdParam) => `${basePaths.events}/${eventId}/settings`,
    theme: ({ eventId }: EventIdParam) => `${basePaths.events}/${eventId}/theme`,
  },
  helpAndSupport: (params: { slug?: string }) =>
    params.slug ? `/help-and-support/${params.slug}` : '/help-and-support',
  home: '/home',
  integrations: {
    klaviyo: `${basePaths.integrations}/klaviyo`,
    root: basePaths.integrations,
    tapcart: `${basePaths.integrations}/tapcart`,
  },
  liveShoppingPage: '/live-shopping-page',
  onboarding: '/onboarding',
  playlists: {
    mediaCollection: ({ mediaCollectionId }: MediaCollectionIdParam) => `${basePaths.playlists}/${mediaCollectionId}`,
    new: (params: { mediaCollectionMediaId?: string }) =>
      `${basePaths.playlists}/new/${params.mediaCollectionMediaId ?? ''}`,
    root: basePaths.playlists,
  },
  replays: {
    analytics: ({ eventId }: EventIdParam) => `${basePaths.replays}/${eventId}/analytics`,
    details: ({ eventId }: EventIdParam) => `${basePaths.replays}/${eventId}`,
    magicContents: ({ eventId }: EventIdParam) => `${basePaths.replays}/${eventId}/magic-contents`,
    preview: ({ eventId }: EventIdParam) => `${basePaths.replays}/${eventId}/preview`,
    replayLinks: ({ eventId }: EventIdParam) => `${basePaths.replays}/${eventId}/replay-links`,
    root: basePaths.replays,
  },
  shoppables: '/shoppables',
  youtubeLogin: '/youtube-login',
};
