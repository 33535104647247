import InfoIcon from '@mui/icons-material/Info';
import { Tooltip, TooltipProps, useTheme } from '@mui/material';
import React from 'react';

export interface InputTooltipProps {
  TooltipProps?: TooltipProps;
  content: string;
  iconSize?: number;
}

export default function InputTooltip({ content, iconSize = 20, TooltipProps }: InputTooltipProps) {
  const theme = useTheme();
  return (
    <Tooltip
      {...TooltipProps}
      placement="right"
      sx={{ bottom: 0, margin: 'auto', position: 'absolute', right: `${-(iconSize + 15)}px`, top: 0 }}
      title={content}
    >
      <InfoIcon htmlColor={theme.palette.primary.main} style={{ fontSize: iconSize }} />
    </Tooltip>
  );
}
