import { Box, CircularProgress, Grid, Pagination, Paper, Typography, styled } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventListReadModel } from '../../../../../core/domain/CBOEventListReadModel';
import '../../../../../core/domain/CBOMediaCollectionMedia';
import { CBOMediaCollectionMedia } from '../../../../../core/domain/CBOMediaCollectionMedia';
import { CBOShoppableVideoListReadModel } from '../../../../../core/domain/CBOShoppableVideoReadModel';
import MediaCollectionMediaList from '../../../molecules/media-collections/MediaCollectionMediaList';

const MediaCollectionMediasSectionRoot = styled(Paper)(({ theme }) => ({
  borderRadius: '25px',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  position: 'relative',
  width: '900px',
}));

// TODO: Use theme values
const MediaCollectionMediasSectionContainer = styled(Grid)({
  '&:after': {
    backgroundColor: '#c6c6c6',
    content: `''`,
    height: 'calc(100% - 48px)',
    left: '50%',
    position: 'absolute',
    top: 48,
    transform: 'translate(-50%)',
    width: '1px',
  },
  '&:before': {
    backgroundColor: '#c6c6c6',
    content: `''`,
    height: '1px',
    left: 0,
    position: 'absolute',
    top: 48,
    width: '100%',
  },
});

const Column = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'initial',
}));

const MediaCollectionMediaListContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  maxHeight: 500,
  overflow: 'auto',
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
}));

const MediaCollectionMediaListTitleContainer = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(1)}`,
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  borderTop: `solid ${theme.palette.grey[200]} 1px`,
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

export type MediaCollectionMediasSelectorProps = {
  areReplayChaptersFetched: boolean;
  areReplaysFetched: boolean;
  areShoppablesFetched: boolean;
  maxItems: number;
  onChangeMedias: (medias: CBOMediaCollectionMedia[]) => void;
  onFetchReplayChapters: (replaysIds: string[]) => void;
  onGoToEventCreationPage: () => void;
  onGoToShoppableCreationPage: () => void;
  onMediaPreviewClick: (media: CBOMediaCollectionMedia) => void;
  replays: CBOEventListReadModel[];
  selectedMedias: CBOMediaCollectionMedia[];
  shoppables: CBOShoppableVideoListReadModel;
};

const ITEMS_PER_PAGE = 10;

const MediaCollectionMediasSelector: FC<MediaCollectionMediasSelectorProps> = ({
  areReplayChaptersFetched,
  areShoppablesFetched,
  areReplaysFetched,
  maxItems,
  onChangeMedias,
  onGoToEventCreationPage,
  onGoToShoppableCreationPage,
  onFetchReplayChapters,
  onMediaPreviewClick,
  replays: allReplays,
  selectedMedias,
  shoppables: allShoppables,
}) => {
  const { t } = useTranslation('mediaCollections');
  const [replayPage, setReplayPage] = useState(1);
  const [shoppablePage, setShoppablePage] = useState(1);
  const [shownReplays, setShownReplays] = useState<CBOEventListReadModel[]>([]);
  const [shownShoppables, setShownShoppables] = useState<CBOShoppableVideoListReadModel>([]);

  const shownReplaysIdsString = shownReplays.map(({ id }) => id).join(',');

  const maxReplayPage = useMemo(() => Math.ceil(allReplays.length / ITEMS_PER_PAGE), [allReplays.length]);
  const maxShoppablePage = useMemo(() => Math.ceil(allShoppables.length / ITEMS_PER_PAGE), [allShoppables.length]);

  useEffect(() => {
    refreshShownShoppables();
  }, [areShoppablesFetched, shoppablePage]);

  useEffect(() => {
    refreshShownReplays();
  }, [areReplayChaptersFetched, allReplays, replayPage]);

  useEffect(() => {
    const shownReplaysIds = shownReplaysIdsString.split(',');
    if (shownReplaysIds.length > 0) {
      onFetchReplayChapters(shownReplaysIds);
    }
  }, [shownReplaysIdsString]);

  const handleChangeReplayPage = (newPage: number) => {
    setReplayPage(newPage);
  };

  const handleChangeShoppablePage = (newPage: number) => {
    setShoppablePage(newPage);
  };

  const handleSelectMedia = useCallback(
    (media: CBOMediaCollectionMedia, isChecked: boolean) => {
      const newSelectedMedias = isChecked
        ? [...selectedMedias, media]
        : selectedMedias.filter((selectedMedia) => selectedMedia.id !== media.id);

      onChangeMedias(newSelectedMedias);
    },
    [selectedMedias],
  );

  const refreshShownReplays = useCallback(() => {
    const newShownReplays = allReplays
      .sort((a, b) => a.title.localeCompare(b.title))
      .slice((replayPage - 1) * ITEMS_PER_PAGE, replayPage * ITEMS_PER_PAGE);
    setShownReplays(newShownReplays);
  }, [allReplays, replayPage]);

  const refreshShownShoppables = () => {
    const newShownShoppables = [...allShoppables]
      .sort((a, b) => a.title.localeCompare(b.title))
      .slice((shoppablePage - 1) * ITEMS_PER_PAGE, shoppablePage * ITEMS_PER_PAGE);
    setShownShoppables(newShownShoppables);
  };

  const renderLoader = () => (
    <Box alignItems="center" display="flex" flex={1} height="100%" justifyContent="center">
      <CircularProgress />
    </Box>
  );

  return (
    <MediaCollectionMediasSectionRoot elevation={3}>
      <MediaCollectionMediasSectionContainer container>
        <Column display="flex" flexDirection="column" item md={6}>
          <MediaCollectionMediaListTitleContainer>
            <Typography fontWeight="bold" variant="h6">
              {t('Shoppables')}
            </Typography>
          </MediaCollectionMediaListTitleContainer>
          <MediaCollectionMediaListContainer flex={1}>
            {areShoppablesFetched ? (
              <MediaCollectionMediaList
                maxItems={maxItems}
                onGoToEventCreationPage={onGoToEventCreationPage}
                onGoToShoppableCreationPage={onGoToShoppableCreationPage}
                onMediaPreviewClick={onMediaPreviewClick}
                onSelectMedia={handleSelectMedia}
                selectedMedias={selectedMedias}
                shoppables={shownShoppables}
                type="shoppables"
              />
            ) : (
              renderLoader()
            )}
          </MediaCollectionMediaListContainer>
          {maxShoppablePage > 1 && (
            <StyledPagination
              count={maxShoppablePage}
              hidden={maxShoppablePage === 1}
              hideNextButton={shoppablePage === maxShoppablePage}
              hidePrevButton={shoppablePage === 1}
              onChange={(_e, page) => handleChangeShoppablePage(page)}
              page={shoppablePage}
            />
          )}
        </Column>
        <Column display="flex" flexDirection="column" item md={6}>
          <MediaCollectionMediaListTitleContainer>
            <Typography fontWeight="bold" variant="h6">
              {t('Replays')}
            </Typography>
          </MediaCollectionMediaListTitleContainer>
          <MediaCollectionMediaListContainer flex={1}>
            {areReplaysFetched ? (
              <MediaCollectionMediaList
                maxItems={maxItems}
                onGoToEventCreationPage={onGoToEventCreationPage}
                onGoToShoppableCreationPage={onGoToShoppableCreationPage}
                onMediaPreviewClick={onMediaPreviewClick}
                onSelectMedia={handleSelectMedia}
                replays={shownReplays}
                selectedMedias={selectedMedias}
                type="replays"
              />
            ) : (
              renderLoader()
            )}
          </MediaCollectionMediaListContainer>
          {maxReplayPage > 1 && (
            <StyledPagination
              count={maxReplayPage}
              hideNextButton={replayPage === maxReplayPage}
              hidePrevButton={replayPage === 1}
              onChange={(_e, page) => handleChangeReplayPage(page)}
              page={replayPage}
            />
          )}
        </Column>
      </MediaCollectionMediasSectionContainer>
    </MediaCollectionMediasSectionRoot>
  );
};

export default MediaCollectionMediasSelector;
