import { Flavor } from '@bellepoque/shared';
import { z } from 'zod';
import { PublicEventDepictionDTOSchema } from './V1/api';
import { StringSchema } from './shared';

// TODO: Move to V1.api
export const LandingPageWithPublicEventDepictionsDTOSchema = z.object({
  askEmail: z.boolean(),
  highlightedEvent: PublicEventDepictionDTOSchema.nullable(),
  publishedReplayEvents: PublicEventDepictionDTOSchema.array(),
  replaySectionTitle: StringSchema,
})

export type LandingPageWithPublicEventDepictionsDTO =z.infer<typeof LandingPageWithPublicEventDepictionsDTOSchema>;

export type LandingPageDTO = {
  highlightedEventId: string | null;
  landingPageUrl: string;
  publishedReplayEventsIds: string[];
  replaySectionTitle: string;
};


export type LandingPageId = Flavor<string, 'LandingPageData'>;
