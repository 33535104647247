import { z } from 'zod';
import { Flavor } from '@bellepoque/shared';
import { EventStatus } from '../event';
import { UUIDSchema } from '../shared';

export const MessageDataSchema = z.record(z.any());
export type MessageData = z.infer<typeof MessageDataSchema>;

export const allowedDataContentTypes = ['application/json;charset=utf-8'] as const;
export const allowedMessageSources = [
  'api',
  'player',
  'event-platform',
  'landing-page',
  'replay-processing',
  'shopify',
] as const;

export type MessageId = Flavor<string, 'Message'>;

export const MessageTypes = {
  Landing_Page_Reminder_Created: 'io.bellepoque.landing_page.reminder.Created',
  Player_Cart_ProductAdded: 'io.bellepoque.player.cart.ProductAdded',
  Player_Cart_ProductRemoved: 'io.bellepoque.player.cart.ProductRemoved',
  Player_Cart_QuantityUpdated: 'io.bellepoque.player.cart.QuantityUpdated',
  Player_Cart_Validated: 'io.bellepoque.player.cart.Validated',
  Player_Chat_Joined: 'io.bellepoque.player.chat.Joined',
  Player_Chat_Liked: 'io.bellepoque.player.chat.Liked',
  Player_Chat_MessagePinned: 'io.bellepoque.player.chat.MessagePinned',
  Player_Chat_MessageSent: 'io.bellepoque.player.chat.MessageSent',
  Player_Chat_MessageUnpinned: 'io.bellepoque.player.chat.MessageUnpinned',
  Player_Player_Ping: 'io.bellepoque.player.player.Ping',
  Player_Player_PlayerStarted: 'io.bellepoque.player.player.PlayerStarted',
  Player_Player_PlayerStopped: 'io.bellepoque.player.player.PlayerStopped',
  Player_Reminder_Created: 'io.bellepoque.player.reminder.Created',
  Player_UI_ProductPageOpened: 'io.bellepoque.player.ui.ProductPageOpened',
  Server_EventManagement_CatalogProductsAddedToEvent:
    'io.bellepoque.server.event_management.CatalogProductsAddedToEvent',
  Server_EventManagement_EventCreated: 'io.bellepoque.server.event_management.EventCreated',
  Server_EventManagement_EventDeleted: 'io.bellepoque.server.event_management.EventDeleted',
  Server_EventManagement_EventProductDeleted: 'io.bellepoque.server.event_management.EventProductDeleted',
  Server_EventManagement_EventProductUpdated: 'io.bellepoque.server.event_management.EventProductUpdated',
  Server_EventManagement_EventProductsReordered: 'io.bellepoque.server.event_management.EventProductsReordered',
  Server_EventManagement_EventPublished: 'io.bellepoque.server.event_management.EventPublished',
  Server_EventManagement_EventUnPublished: 'io.bellepoque.server.event_management.EventUnPublished',
  Server_EventManagement_EventUpdated: 'io.bellepoque.server.event_management.EventUpdated',
  Server_EventManagement_LiveStarted: 'io.bellepoque.server.event_management.LiveStarted',
  Server_EventManagement_LiveStopped: 'io.bellepoque.server.event_management.LiveStopped',
  Server_EventManagement_ProductAddedToEvent: 'io.bellepoque.server.event_management.ProductAddedToEvent',
  Server_EventManagement_ProductDisplayed: 'io.bellepoque.server.event_management.ProductDisplayed',
  Server_EventManagement_ProductOverrideDeletedFromEvents:
    'io.bellepoque.server.event_management.ProductOverrideDeletedFromEvents',
  Server_EventManagement_ReplayOpened: 'io.bellepoque.server.event_management.ReplayOpened',
  Server_EventManagement_ReplayPublished: 'io.bellepoque.server.event_management.ReplayPublished',
  Server_EventManagement_ReplayUpdated: 'io.bellepoque.server.event_management.ReplayUpdated',
  Server_IAM_MasterAddedToTenant: 'io.bellepoque.server.iam.MasterAddedToTenant',
  Server_IAM_TenantCreated: 'io.bellepoque.server.iam.TenantCreated',
  Server_IAM_TenantIntegrationsUpdated: 'io.bellepoque.server.iam.TenantIntegrationsUpdated',
  Server_MediaCollectionManagement_MediaCollectionCreated:
    'io.bellepoque.server.media_collection_management.MediaCollectionCreated',
  Server_MediaCollectionManagement_MediaCollectionPagesUpdated:
    'io.bellepoque.server.media_collection_management.MediaCollectionPagesUpdated',
  Server_MediaCollectionManagement_MediaCollectionSettingsUpdated:
    'io.bellepoque.server.media_collection_management.MediaCollectionSettingsUpdated',
  Server_ProductManagement_CatalogToImportCreated: 'io.bellepoque.server.product_management.CatalogToImportCreated',
  Server_ProductManagement_SyncedCatalogProductActivated:
    'io.bellepoque.server.product_management.SyncedCatalogProductActivated',
  Server_ProductManagement_SyncedCatalogProductCreated:
    'io.bellepoque.server.product_management.SyncedCatalogProductCreated',
  Server_ProductManagement_SyncedCatalogProductDeactivated:
    'io.bellepoque.server.product_management.SyncedCatalogProductDeactivated',
  Server_ProductManagement_SyncedCatalogProductUpdated:
    'io.bellepoque.server.product_management.SyncedCatalogProductUpdated',
  Server_Replaying_AudioTranscriptionFailed: 'io.bellepoque.server.replaying.AudioTranscriptionFailed',
  Server_Replaying_AudioTranscriptionSucceeded: 'io.bellepoque.server.replaying.AudioTranscriptionSucceeded',
  Server_Replaying_ReplayFailed: 'io.bellepoque.server.replaying.ReplayFailed',
  Server_Replaying_ResyncReplayCommand: 'io.bellepoque.server.replaying.ResyncReplayCommand',
  Server_Replaying_Mp4Published: 'io.bellepoque.server.replaying.Mp4Published',
  Server_ShoppableVideoManagement_ShoppablesSettingsUpdated:
    'io.bellepoque.server.shoppable_video_management.ShoppablesSettingsUpdated',
  Server_ShoppableVideoManagement_ShoppableVideoCreated:
    'io.bellepoque.server.shoppable_video_management.ShoppableVideoCreated',
  Server_ShoppableVideoManagement_ShoppableVideoDeleted:
    'io.bellepoque.server.shoppable_video_management.ShoppableVideoDeleted',
  Server_ShoppableVideoManagement_ShoppableVideoFileUploaded:
    'io.bellepoque.server.shoppable_video_management.ShoppableVideoFileUploaded',
  Server_ShoppableVideoManagement_ShoppableVideoPublicationRequested:
    'io.bellepoque.server.shoppable_video_management.ShoppableVideoPublicationRequested',
  Server_ShoppableVideoManagement_ShoppableVideoUnpublicationRequested:
    'io.bellepoque.server.shoppable_video_management.ShoppableVideoUnpublicationRequested',
  Server_ShoppableVideoManagement_ShoppableVideoUpdated:
    'io.bellepoque.server.shoppable_video_management.ShoppableVideoUpdated',
  Server_ShoppableVideoManagement_ShoppableVideoProcessed:
    'io.bellepoque.server.shoppable_video_management.ShoppableVideoProcessed',
  Server_ShoppableVideoManagement_ShoppableVideoProcessingFailed:
    'io.bellepoque.server.shoppable_video_management.ShoppableVideoProcessingFailed',
  ReplayProcessing_VideoProcessingFailed: 'io.bellepoque.replay_processing.VideoProcessingFailed',
  ReplayProcessing_VideoProcessedHls: 'io.bellepoque.replay_processing.VideoProcessedHls',
  ReplayProcessing_VideoProcessedMp4: 'io.bellepoque.replay_processing.VideoProcessedMp4',
  ReplayProcessing_AudioProcessed: 'io.bellepoque.replay_processing.AudioProcessed',
  Server_Statistics_ReplayEventStatisticsUpdated: 'io.bellepoque.server.statistics.ReplayEventStatisticsUpdated',
  Server_Statistics_EventStatisticsUpdated: 'io.bellepoque.server.statistics.EventStatisticsUpdated',
  Server_Statistics_PlayerStartedCreated: 'io.bellepoque.server.statistics.PlayerStartedCreated',
  Server_Statistics_ReminderCreated: 'io.bellepoque.server.statistics.ReminderCreated',
  Server_Statistics_VodRankingsGenerated: 'io.bellepoque.server.statistics.VodRankingsGenerated',
  Shopify_AppInstalled: 'io.bellepoque.shopify.AppInstalled',
  Shopify_AppUninstalled: 'io.bellepoque.shopify.AppUninstalled',
  Shopify_ExportCompleted: 'io.bellepoque.shopify.ExportCompleted',
  Shopify_StoreClosed: 'io.bellepoque.shopify.StoreClosed',
  Shopify_SubscriptionCancelled: 'io.bellepoque.shopify.SubscriptionCancelled',
  Shopify_SubscriptionCreated: 'io.bellepoque.shopify.SubscriptionCreated',
  ShopifyPlugin_ProductManagement_ProductUpserted: 'io.bellepoque.shopify.product_management.ProductUpserted',
} as const;

export type TMessageTypes = typeof MessageTypes[keyof typeof MessageTypes];
export const MessageTypesSchema = z.nativeEnum(MessageTypes);


// https://github.com/cloudevents/spec/blob/master/spec.md
export interface Message<TData extends MessageData = MessageData> {
  // correlationId: '995dea55-2fca-4e94-afbd-8286a8041b30';
  data: TData;
  datacontenttype: typeof allowedDataContentTypes[number];
  id: string;
  // https://github.com/cloudevents/spec/blob/v1.0.1/extensions/partitioning.md
  partitionkey?: string | undefined; // | undefined to be usable with typescript-is assertType
  source: typeof allowedMessageSources[number];
  // subject?: string; // (could be the eventId ?)
  time: string; // ISO String
  type: TMessageTypes;
}


export const StatisticEventMessageDataSchema = z.object({
  eventId: UUIDSchema,
  status: z.nativeEnum(EventStatus),
  timecode: z.number().nonnegative().finite().safe().optional(),
}).catchall(z.any());

const StatisticShoppableMessageDataSchema = z.object({
  shoppableId: UUIDSchema,
}).catchall(z.any());


export const MessageSchema = z.object({
  datacontenttype: z.enum(allowedDataContentTypes),
  id: z.string(),
  partitionkey: z.string().optional(),
  source: z.enum(allowedMessageSources),
  time: z.string().datetime(),
  type: MessageTypesSchema,
});


export const StatisticEventMessageSchema = MessageSchema.extend({ data: StatisticEventMessageDataSchema });
export const StatisticShoppableMessageSchema = MessageSchema.extend({ data: StatisticShoppableMessageDataSchema });
export const StatisticMessageSchema = z.union([StatisticEventMessageSchema, StatisticShoppableMessageSchema]);


export interface ApiMessage<T extends MessageData = MessageData> extends Message<T> {
  source: 'api';
}

export type StatisticEventMessage = z.infer<typeof StatisticEventMessageSchema>;
export type StatisticShoppableMessage = z.infer<typeof StatisticShoppableMessageSchema>;
export type StatisticMessage = z.infer<typeof StatisticMessageSchema>;

export interface SerializableMessage<TData extends MessageData> {
  // readonly id: MessageId;
  // readonly partitionKey?: string;

  toJSON(): Message<TData>; // Serialized<TData>
}
