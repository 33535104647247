import { EventId } from "./event";
import { Cms, TenantId } from "./tenant";
import { Flavor } from "@bellepoque/shared";

export interface ReplaysCollection {
  id: ReplaysCollectionId;
  replays: {
    replayId: EventId;
    timestamp?: number;
  }
  tenantId: TenantId;
  title: string;
}

export interface ReplaysCollectionBlock {
  id: ReplaysCollectionBlockId;
  replaysCollectionId: ReplaysCollectionId;
}

export type ReplaysCollectionId = Flavor<string, 'ReplaysCollection'>;
export type ReplaysCollectionBlockId = Flavor<string, 'ReplaysCollectionBlock'>;

export const ALLOWED_REPLAYS_COLLECTIONS_CMS = [Cms.Shopify] as const;