import { EventId } from '@bellepoque/api-contracts';
import { Box, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EventBillingReport, ShoppableTrafficReport, TrafficReport } from '../../../../core/domain/Reports';

export type BillingMonthReportingReport = {
  eventId: EventId;
  report: EventBillingReport;
};

export type BillingMonthReportingProps = {
  reports: BillingMonthReportingReport[];
};

export type TrafficMonthReportingReport = {
  eventId: EventId;
  report: TrafficReport;
};

export type TrafficMonthReportingProps = {
  reports: TrafficMonthReportingReport[];
};

const ReportDate = styled(Typography)({
  flexShrink: 0,
  pl: 3,
  textAlign: 'left',
  width: '40%',
});

export const BillingMonthReporting = ({ reports }: BillingMonthReportingProps) => {
  return (
    <>
      {reports.map(({ eventId, report }) => (
        <Box key={eventId} sx={{ display: 'flex', flexGrow: 1 }}>
          <ReportDate>
            {new Date(report.showTime).toLocaleDateString()} - {report.title}
          </ReportDate>
          <Typography sx={{ width: '20%' }}>
            {report.liveUsageInMin === 'NA' ? 'NA' : Math.ceil(report.liveUsageInMin)}
          </Typography>
          <Typography sx={{ width: '20%' }}>
            {report.replayUsageInMin === 'NA' ? 'NA' : Math.ceil(report.replayUsageInMin)}
          </Typography>
          <Typography sx={{ width: '20%' }}>{report.nbSentSMS}</Typography>
        </Box>
      ))}
    </>
  );
};

export const TrafficMonthReporting = ({ reports }: TrafficMonthReportingProps) => {
  const { t } = useTranslation('shoppables');

  const isShoppableVideoReport = (report: TrafficReport): report is ShoppableTrafficReport =>
    !('showTime' in report) && 'productTitle' in report;

  const getReportName = (report: TrafficReport) => {
    if (isShoppableVideoReport(report)) {
      return `${t('Shoppable')} - ${report.title} - ${report.productTitle}`;
    }

    const showTime = new Date(report.showTime);
    return `${showTime.toLocaleDateString()} - ${report.title}`;
  };

  return (
    <>
      {reports.map(({ eventId, report }) => (
        <Box key={eventId} sx={{ display: 'flex', flexGrow: 1 }}>
          <ReportDate>{getReportName(report)}</ReportDate>
          <Typography sx={{ width: '20%' }}>{report.nbUniqueLiveViewers}</Typography>
          <Typography sx={{ width: '20%' }}>{report.nbUniqueReplayViewers}</Typography>
          <Typography sx={{ width: '20%' }}>{report.nbReplaysViews}</Typography>
        </Box>
      ))}
    </>
  );
};
