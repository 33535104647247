import { ProductId } from '@bellepoque/api-contracts';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CBOCatalogProduct } from '../../../core/domain/CBOCatalogProduct';
import { CatalogSortType } from '../../../core/store/state/catalog';
import CatalogTemplate from '../../templates/CatalogTemplate';
import { createCatalogViewModel } from './Catalog.viewmodel';

export default function Catalog() {
  const viewModel = useSelector(createCatalogViewModel({ dispatch: useDispatch() }));

  const {
    apiKey,
    canRenewApiKey,
    changePage,
    clearSelectedProducts,
    currency,
    currentPage,
    deleteProducts,
    deleteStatus,
    fetchProductsWithoutFilters,
    pageCount,
    products,
    productsFetchingStatus,
    renewApiKey,
    renewApiKeyStatus,
    resetDeleteProducts,
    resetRenewApiKey,
    searchProducts,
    selectedProducts,
    selectProduct,
    sort,
    sortProducts,
    tenantId,
    tenantName,
    trackUserJourneyEvent,
  } = viewModel;

  useEffect(() => {
    trackUserJourneyEvent({
      data: { 'Tenant id': tenantId, 'Tenant name': tenantName },
      name: 'Catalog',
    });
  }, []);

  useEffect(() => {
    fetchProductsWithoutFilters();
    clearSelectedProducts();
  }, [tenantId]);

  useEffect(() => {
    if (renewApiKeyStatus === 'success') {
      resetRenewApiKey();
    }
  }, [renewApiKeyStatus]);

  useEffect(() => {
    if (deleteStatus === 'success') {
      refreshProducts();
      resetDeleteProducts();
    }
  }, [deleteStatus]);

  const handleChangePage = (page: number) => {
    changePage(page);
  };

  const handleChangeSort = (sort: CatalogSortType) => {
    sortProducts(sort);
  };

  const handleClearSelection = () => {
    clearSelectedProducts();
  };

  const handleDeleteProducts = (productsIds: ProductId[]) => {
    deleteProducts(productsIds);
  };

  const handleRenewApiKey = () => {
    renewApiKey();
  };

  const handleSearchProducts = (search: string) => {
    searchProducts(search);
  };

  const handleToggleSelectProduct = (product: CBOCatalogProduct, isSelected: boolean) => {
    selectProduct(product, isSelected);
  };

  const refreshProducts = () => {
    fetchProductsWithoutFilters();
  };

  return (
    <CatalogTemplate
      apiKey={apiKey}
      canRenewApiKey={canRenewApiKey}
      currency={currency}
      currentPage={currentPage}
      deleteStatus={deleteStatus}
      onChangePage={handleChangePage}
      onChangeSort={handleChangeSort}
      onClearSelection={handleClearSelection}
      onDeleteProducts={handleDeleteProducts}
      onRenewApiKey={handleRenewApiKey}
      onSearchProducts={handleSearchProducts}
      onSelectProduct={(product: CBOCatalogProduct) => handleToggleSelectProduct(product, true)}
      onUnselectProduct={(product: CBOCatalogProduct) => handleToggleSelectProduct(product, false)}
      pageCount={pageCount}
      products={products}
      productsFetchingStatus={productsFetchingStatus}
      renewApiKeyStatus={renewApiKeyStatus}
      selectedProducts={selectedProducts}
      sort={sort}
    />
  );
}
