import { LiveEventStatistics } from '../../live-event-statistics';

import { z } from 'zod';
import { SafeNonNegativeFloatSchema, SafeNonNegativeIntegerSchema, URLSchema } from '../../shared';
import { EventDTOSchema } from './events';

export const LiveReplayCountsDTOSchema = z.object({
  live: SafeNonNegativeFloatSchema,
  replay: SafeNonNegativeFloatSchema,
});

export const LiveReplayIntegerCountsDTOSchema = z.object({
  live: SafeNonNegativeIntegerSchema,
  replay: SafeNonNegativeIntegerSchema,
});

export const ECommerceStatisticsDTOSchema = z.object({
  addedProducts: LiveReplayIntegerCountsDTOSchema,
  cartsAmounts: LiveReplayCountsDTOSchema.nullable(),
  filledCarts: LiveReplayIntegerCountsDTOSchema,
  validatedCarts: LiveReplayIntegerCountsDTOSchema,
  validatedCartsAmounts: LiveReplayCountsDTOSchema.nullable(),
  validatedProducts: LiveReplayIntegerCountsDTOSchema,
});

const ECommerceStatisticsWithSalesDTOSchema = ECommerceStatisticsDTOSchema.extend({
  purchasedAmounts: LiveReplayCountsDTOSchema,
  purchasedCarts: LiveReplayIntegerCountsDTOSchema,
});

export const EngagementStatisticsDTOSchema = z.object({
  chatMessages: SafeNonNegativeIntegerSchema,
  chatUsers: SafeNonNegativeIntegerSchema,
  likes: LiveReplayIntegerCountsDTOSchema,
  reminders: z.object({
    calendar: SafeNonNegativeIntegerSchema,
    sms: SafeNonNegativeIntegerSchema,
  }),
  seenProductPages: LiveReplayIntegerCountsDTOSchema,
});

export const TrafficStatisticsDTOSchema = z.object({
  averageAttendancePercentage: LiveReplayCountsDTOSchema,
  averageAttendanceTime: LiveReplayIntegerCountsDTOSchema,
  deviceCounts: z.object({
    desktop: SafeNonNegativeIntegerSchema,
    mobile: SafeNonNegativeIntegerSchema,
    tablet: SafeNonNegativeIntegerSchema,
  }),
  maxSimultaneousViewers: SafeNonNegativeIntegerSchema,
  totalAttendanceTime: LiveReplayIntegerCountsDTOSchema,
  totalLiveDuration: SafeNonNegativeIntegerSchema,
  views: LiveReplayIntegerCountsDTOSchema,
  uniqueViewers: LiveReplayIntegerCountsDTOSchema,
});

const ProductStatisticsWithoutSalesDTOSchema = z.object({
  cart: SafeNonNegativeIntegerSchema,
  validated: SafeNonNegativeIntegerSchema,
  views: SafeNonNegativeIntegerSchema,
});

const ProductStatisticsWithSalesDTOSchema = ProductStatisticsWithoutSalesDTOSchema.extend({
  purchased: SafeNonNegativeIntegerSchema,
});

const ProductStatisticsDTOSchema = z.union([
  ProductStatisticsWithoutSalesDTOSchema,
  ProductStatisticsWithSalesDTOSchema,
]);

const ProductsStatisticsWithSalesDTOSchema = z.record(ProductStatisticsWithSalesDTOSchema);
const ProductsStatisticsWithoutSalesDTOSchema = z.record(ProductStatisticsWithoutSalesDTOSchema);

const ProductsStatisticsDTOSchema = z.union([
  ProductsStatisticsWithoutSalesDTOSchema,
  ProductsStatisticsWithSalesDTOSchema,
]);

export const EventStatisticsDTOWithoutSalesSchema = z.object({
  eCommerce: ECommerceStatisticsDTOSchema,
  engagement: EngagementStatisticsDTOSchema,
  traffic: TrafficStatisticsDTOSchema,
  productsStatistics: ProductsStatisticsWithoutSalesDTOSchema,
});

export const EventStatisticsDTOWithSalesSchema = z.object({
  eCommerce: ECommerceStatisticsWithSalesDTOSchema,
  engagement: EngagementStatisticsDTOSchema,
  traffic: TrafficStatisticsDTOSchema,
  productsStatistics: ProductsStatisticsWithSalesDTOSchema,
});

export const EventStatisticsDTOSchema = z.union([
  EventStatisticsDTOWithoutSalesSchema,
  EventStatisticsDTOWithSalesSchema,
]);

export const EventStatisticsPreviewDTOSchema = EventDTOSchema.pick({
  id: true,
  status: true,
  title: true,
}).extend({
  productsCount: SafeNonNegativeIntegerSchema,
  imageUrl: URLSchema,
  showTime: z.string().datetime(),
});

export type ECommerceStatisticsDTO = z.infer<typeof ECommerceStatisticsDTOSchema>;
export type EngagementStatisticsDTO = z.infer<typeof EngagementStatisticsDTOSchema>;
export type EventStatisticsDTO = z.infer<typeof EventStatisticsDTOSchema>;
export type EventStatisticsWithSalesDTO = z.infer<typeof EventStatisticsDTOWithSalesSchema>;
export type EventStatisticsWithoutSalesDTO = z.infer<typeof EventStatisticsDTOWithoutSalesSchema>;
export type EventStatisticsPreviewDTO = z.infer<typeof EventStatisticsPreviewDTOSchema>;
export type LiveEventStatisticsDTO = LiveEventStatistics;
export type ProductsStatisticsDTO = z.infer<typeof ProductsStatisticsDTOSchema>;
export type ProductStatisticsDTO = z.infer<typeof ProductStatisticsDTOSchema>;
export type TrafficStatisticsDTO = z.infer<typeof TrafficStatisticsDTOSchema>;
