import { z } from 'zod';
import { SafeNonNegativeFloatSchema, StringSchema, URLNullableSchema, UUIDSchema } from '../../shared';

export const constraints = {
  maxMediasCount: 6,
  title: {
    minLength: 3,
    maxLength: 100,
  },
};

const MediaCollectionReplayMediaPublicDTOSchema = z.object({
  id: UUIDSchema,
  type: z.literal('replay'),
});

const MediaCollectionReplayChapterMediaPublicDTOSchema = z.object({
  endTimeStamp: z.number(),
  id: UUIDSchema,
  startTimeStamp: z.number(),
  type: z.literal('replay-chapter'),
});

const MediaCollectionShoppableMediaPublicDTOSchema = z.object({
  id: UUIDSchema,
  type: z.literal('shoppable'),
});

export const MediaCollectionMediaPublicDTOSchema = z.union([
  MediaCollectionShoppableMediaPublicDTOSchema,
  MediaCollectionReplayMediaPublicDTOSchema,
  MediaCollectionReplayChapterMediaPublicDTOSchema,
]);

const MediaCollectionPublishedCollectionPagePublicDTOSchema = z.object({
  type: z.literal('collection'),
  id: StringSchema,
});
const MediaCollectionPublishedHomePagePublicDTOSchema = z.object({
  type: z.literal('home'),
});

const MediaCollectionPublishedProductPagePublicDTOSchema = z.object({
  id: StringSchema,
  type: z.literal('product'),
});

const MediaCollectionPublishedPagePublicDTOSchema = z.union([
  MediaCollectionPublishedHomePagePublicDTOSchema,
  MediaCollectionPublishedProductPagePublicDTOSchema,
  MediaCollectionPublishedCollectionPagePublicDTOSchema,
]);

const MediaCollectionPublicDTOSchema = z.object({
  id: UUIDSchema,
  medias: MediaCollectionMediaPublicDTOSchema.array(),
  publishedPages: MediaCollectionPublishedPagePublicDTOSchema.array(),
  tenantId: UUIDSchema,
  title: StringSchema,
});

export type MediaCollectionMediaPublicDTO = z.infer<typeof MediaCollectionMediaPublicDTOSchema>;
export type MediaCollectionPublicDTO = z.infer<typeof MediaCollectionPublicDTOSchema>;

const MediaCollectionMediaBaseDTOSchema = z.object({
  image: URLNullableSchema,
  title: StringSchema,
});

const MediaCollectionReplayChapterMediaDTOSchema = MediaCollectionReplayChapterMediaPublicDTOSchema.merge(
  MediaCollectionMediaBaseDTOSchema,
);
const MediaCollectionReplayMediaDTOSchema = MediaCollectionReplayMediaPublicDTOSchema.merge(
  MediaCollectionMediaBaseDTOSchema,
);
const MediaCollectionShoppableMediaDTOSchema = MediaCollectionShoppableMediaPublicDTOSchema.merge(
  MediaCollectionMediaBaseDTOSchema,
).extend({
  duration: SafeNonNegativeFloatSchema.nullable(),
  productId: UUIDSchema,
  productOriginalId: StringSchema,
  productImage: URLNullableSchema,
  productTitle: StringSchema,
});

export const MediaCollectionMediaDTOSchema = z.union([
  MediaCollectionShoppableMediaDTOSchema,
  MediaCollectionReplayMediaDTOSchema,
  MediaCollectionReplayChapterMediaDTOSchema,
]);

const MediaCollectionPublishedCollectionPageDTOSchema = MediaCollectionPublishedCollectionPagePublicDTOSchema;

const MediaCollectionPublishedHomePageDTOSchema = MediaCollectionPublishedHomePagePublicDTOSchema;

const MediaCollectionPublishedProductPageDTOSchema = MediaCollectionPublishedProductPagePublicDTOSchema.extend({
  image: URLNullableSchema,
  title: StringSchema,
});

const MediaCollectionPublishedPageDTOSchema = z.union([
  MediaCollectionPublishedHomePageDTOSchema,
  MediaCollectionPublishedProductPageDTOSchema,
  MediaCollectionPublishedCollectionPageDTOSchema,
]);

const MediaCollectionDTOSchema = MediaCollectionPublicDTOSchema.extend({
  medias: MediaCollectionMediaDTOSchema.array(),
  publishedPages: MediaCollectionPublishedPageDTOSchema.array(),
});

export const CreateMediaCollectionDTOSchema = z.object({
  id: UUIDSchema,
  medias: MediaCollectionMediaPublicDTOSchema.array(),
  title: StringSchema,
});

export const UpdateMediaCollectionPagesDTOSchema = z.object({
  publishedPages: MediaCollectionPublishedPagePublicDTOSchema.array(),
});

export const UpdateMediaCollectionSettingsDTOSChema = CreateMediaCollectionDTOSchema.omit({ id: true });

export type CreateMediaCollectionDTO = z.infer<typeof CreateMediaCollectionDTOSchema>;
export type MediaCollectionDTO = z.infer<typeof MediaCollectionDTOSchema>;
export type MediaCollectionPublishedPageDTO = z.infer<typeof MediaCollectionPublishedPageDTOSchema>;
export type MediaCollectionMediaDTO = z.infer<typeof MediaCollectionMediaDTOSchema>;
export type UpdateMediaCollectionPagesDTO = z.infer<typeof UpdateMediaCollectionPagesDTOSchema>;
export type UpdateMediaCollectionSettingsDTO = z.infer<typeof UpdateMediaCollectionSettingsDTOSChema>;
