import { StringSchema } from '../../shared';
import { z } from 'zod';

const HomePage = z.object({
  type: z.literal('home'),
});

const ProductPage = z.object({
  id: StringSchema,
  name: StringSchema,
  type: z.literal('product'),
});

const CollectionPage = z.object({
  id: StringSchema,
  name: StringSchema,
  type: z.literal('collection'),
});

const ShopifyPageDTOSchema = z.union([HomePage, ProductPage, CollectionPage]);

export type ShopifyPageDTO = z.infer<typeof ShopifyPageDTOSchema>;

const ShopifyPagesForMediaCollectionsDTOSchema = z.object({ pages: ShopifyPageDTOSchema.array() });

export type ShopifyPagesForMediaCollectionsDTO = z.infer<typeof ShopifyPagesForMediaCollectionsDTOSchema>;
