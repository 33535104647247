export default {
  CmsRequired: 'CMS is required',
  CountryCodeRequired: 'Country code is required',
  CreateTenant: 'Create',
  EmailValidationError: 'Please enter a valid email address',
  EventFinder: 'Event finder',
  EventId: 'Event id',
  InviteMaster: 'Invite',
  MasterEmail: 'Your team member email',
  MasterEmailRequired: 'Master email is required',
  MasterInvitation: 'Add Team Members',
  Name: 'Name',
  RenameTenant: 'Rename tenant',
  TenantName: 'Store',
  TenantNameRequired: 'Tenant name is required',
  Tools: 'Tools',
  notifications: {
    AliasFailedUpdating: 'An error occurred while updating the tenant alias. Please retry.',
    AliasSuccessfullyUpdated: 'Tenant alias successfully updated!',
    IntegrationsFailedUpdating: 'An error occurred while updating the integrations. Please retry.',
    IntegrationsSuccessfullyUpdated: 'Integrations successfully updated!',
  },
};
