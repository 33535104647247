import { Flavor } from '@bellepoque/shared';

export type EventId = Flavor<string, 'Event'>;

// idea of wording:
// Construction > Preparation > diffusion
// Construction: DRAFT > PLANNED
// Preparation: PRIVATE_PREVIEW
// Diffusion: ON_AIR > ...

// Details of state:
// DRAFT > PLANNED (event.showTime set) > PRIVATE_PREVIEW
// > ON_AIR > FINISHED (aka. live closed) > REPLAY_READY (video url & duration set)
// > PRIVATE_REPLAY > REPLAY (replay open to public)
// an event is frozen when status is PRIVATE_PREVIEW or later
export enum EventStatus {
  DRAFT = 'DRAFT',
  FINISHED = 'FINISHED',
  ON_AIR = 'ON_AIR',
  PLANNED = 'PLANNED',
  PRIVATE_PREVIEW = 'PRIVATE_PREVIEW',
  PRIVATE_REPLAY = 'PRIVATE_REPLAY',
  REPLAY = 'REPLAY',
  REPLAY_READY = 'REPLAY_READY',
  REPLAY_FAILED = 'REPLAY_FAILED',
}

export const HAS_BEEN_LIVE_EVENT_STATUSES = [
  EventStatus.FINISHED,
  EventStatus.ON_AIR,
  EventStatus.PRIVATE_REPLAY,
  EventStatus.REPLAY,
  EventStatus.REPLAY_FAILED,
  EventStatus.REPLAY_READY,
] as const;

export const TEST_OR_LATER_EVENT_STATUSES = [
  EventStatus.FINISHED,
  EventStatus.ON_AIR,
  EventStatus.PRIVATE_PREVIEW,
  EventStatus.PRIVATE_REPLAY,
  EventStatus.REPLAY_FAILED,
  EventStatus.REPLAY_READY,
  EventStatus.REPLAY,
] as const;

export const FINISHED_OR_LATER_EVENT_STATUSES = [
  EventStatus.FINISHED,
  EventStatus.PRIVATE_REPLAY,
  EventStatus.REPLAY,
  EventStatus.REPLAY_FAILED,
  EventStatus.REPLAY_READY,
];

export const PUBLISHABLE_REPLAY_EVENT_STATUSES = [
  EventStatus.FINISHED,
  EventStatus.PRIVATE_REPLAY,
  EventStatus.REPLAY_FAILED,
  EventStatus.REPLAY_READY,
];

export const REPLAY_STATUSES = [
  EventStatus.PRIVATE_REPLAY,
  EventStatus.REPLAY,
  EventStatus.REPLAY_FAILED,
  EventStatus.REPLAY_READY,
] as const;

export const HIGHLIGHTABLE_STATUSES = [EventStatus.PLANNED, EventStatus.ON_AIR, EventStatus.PRIVATE_PREVIEW];
