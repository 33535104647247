import { Button, Link, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ENDPOINTS } from '../../../../endpoints';
import { getUrlToTenantShopifyThemeEditor } from '../../../../utils/getUrlToTenantShopifyThemeEditor';
import { routes } from '../../../routes';
import StyledDialog from '../../../templates/dialog/StyledDialog';

interface PlayerPlaylistsBlockModalProps {
  isOpen: boolean;
  onClose: () => void;
  tenantName: string;
}

const Line = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
}));

const DialogContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginTop: theme.spacing(3),
}));

export default function PlayerPlaylistsBlockModal({ isOpen, onClose, tenantName }: PlayerPlaylistsBlockModalProps) {
  const theme = useTheme();
  const { t } = useTranslation(['shoppables', 'common']);

  const getDialogContent = () => {
    return (
      <DialogContentContainer>
        <Line>
          {t('PlayerPlaylistsBlockModal.Line1')}
          <Link href={getUrlToTenantShopifyThemeEditor(tenantName)} target="_blank">
            <Button color="primary" variant="contained">
              {t('PlayerPlaylistsBlockModal.ShopifyThemeEditor')}
            </Button>
          </Link>
        </Line>
        <Typography>{t('PlayerPlaylistsBlockModal.Line2')}</Typography>
        <Typography>
          <Trans
            components={{ Underlined: <Typography sx={{ display: 'inline', textDecoration: 'underline' }} /> }}
            i18nKey="PlayerPlaylistsBlockModal.Line3"
            ns="shoppables"
          />
        </Typography>
        <Typography>{t('PlayerPlaylistsBlockModal.Line4')}</Typography>
        <Typography sx={{ marginTop: theme.spacing(2) }}>
          <Trans
            components={{
              Link: (
                <Link
                  href={routes.helpAndSupport({
                    slug: encodeURIComponent(ENDPOINTS.support.enableShoppableVideoPlayer),
                  })}
                  target="_blank"
                />
              ),
            }}
            i18nKey="PlayerPlaylistsBlockModal.Tutorial"
            ns="shoppables"
          />
        </Typography>
      </DialogContentContainer>
    );
  };

  return (
    <StyledDialog
      ButtonsProps={[
        {
          children: t('common:OK'),
          onClick: onClose,
          sx: { marginRight: theme.spacing(2) },
          variant: 'outlined',
        },
      ]}
      content={getDialogContent()}
      onClose={onClose}
      open={isOpen}
      title={t('PlayerPlaylistsBlockModal.Title')}
    />
  );
}
