import DomainAddIcon from '@mui/icons-material/DomainAdd';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';

import { State } from '../../../core/store';
import { trackUserJourneyEvent } from '../../../core/usecases/user-journey-tracing/track-user-journey-event';
import { TabProps } from '../../components/molecules/Tabs';
import AdminTools from '../../components/organisms/administration/AdminTools';
import CreateTenant from '../../components/organisms/administration/CreateTenant';
import MasterInvitations from '../../components/organisms/administration/MasterInvitations';
import { routes } from '../../routes';
import AdministrationTemplate from '../../templates/AdministrationTemplate';

enum TabsNumber {
  CreateTenant = 1,
  Tools = 2,
  Invitations = 3,
}

// TODO: Use view model
export default function Administration() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['administration', 'common']);
  const isCreateTenantPageActive = useMatch(routes.administration.createTenant);
  const isInvitationsPageActive = useMatch(routes.administration.invitations);
  const isToolsPageActive = useMatch(routes.administration.tools);
  const { isAdmin } = useSelector((state: State) => state.authentication.currentUser);
  const currentTenant = useSelector((state: State) => state.tenants.currentTenant);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<number>(() => {
    if (isInvitationsPageActive) return TabsNumber.Invitations;
    if (isCreateTenantPageActive && isAdmin) return TabsNumber.CreateTenant;
    if (isToolsPageActive && isAdmin) return TabsNumber.Tools;
    return isAdmin ? TabsNumber.CreateTenant : TabsNumber.Invitations;
  });

  useEffect(() => {
    dispatch(
      trackUserJourneyEvent({
        data: { 'Tenant id': currentTenant.id, 'Tenant name': currentTenant.name },
        name: 'Administration',
      }),
    );
  }, []);

  useEffect(() => {
    const tab = tabs.find((tab) => tab.index === activeTab);
    if (tab) navigate(tab.to);
  }, [activeTab]);

  const tabs: TabProps[] = [
    isAdmin && {
      component: <CreateTenant />,
      enabled: true,
      icon: <DomainAddIcon />,
      index: TabsNumber.CreateTenant,
      label: t('common:CreateTenant'),
      to: routes.administration.createTenant,
    },
    isAdmin && {
      component: <AdminTools />,
      enabled: true,
      icon: <HomeRepairServiceIcon />,
      index: TabsNumber.Tools,
      label: t('common:AdminTools'),
      to: routes.administration.tools,
    },
    {
      component: <MasterInvitations />,
      enabled: true,
      icon: <PersonAddIcon />,
      index: TabsNumber.Invitations,
      label: t('common:Invitations'),
      to: routes.administration.invitations,
    },
  ].filter((x) => x);

  return <AdministrationTemplate activeTab={activeTab} onChangeTab={setActiveTab} tabs={tabs} />;
}
