import administration from './administration';
import analytics from './analytics';
import catalog from './catalog';
import common from './common';
import events from './events';
import home from './home';
import integrations from './integrations';
import liveShoppingPage from './live-shopping-page';
import mediaCollections from './media-collections';
import navigation from './navigation';
import onboarding from './onboarding';
import plan from './plan';
import products from './products';
import shopifyScopes from './shopify-scopes';
import shoppables from './shoppables';
import tutorial from './tutorial';
import users from './users';

export default {
  administration,
  analytics,
  catalog,
  common,
  events,
  home,
  integrations,
  liveShoppingPage,
  mediaCollections,
  navigation,
  onboarding,
  plan,
  products,
  shopifyScopes,
  shoppables,
  tutorial,
  users,
};
