import { z } from 'zod';
import {
  SafeNonNegativeFloatSchema,
  SafeNonNegativeIntegerSchema,
  StringSchema,
  URLNullableSchema,
  UUIDSchema,
} from '../../shared';
import {
  EventStatisticsPreviewDTOSchema,
  TrafficStatisticsDTOSchema,
  EngagementStatisticsDTOSchema,
  ECommerceStatisticsDTOSchema,
  LiveReplayIntegerCountsDTOSchema,
  LiveReplayCountsDTOSchema,
} from './event-statistics';
import { EventDTOSchema } from './events';

export const ShoppableVideoStatisticsPreviewWithoutSalesDTOSchema = z.object({
  id: UUIDSchema,
  imageUrl: URLNullableSchema,
  productTitle: StringSchema,
  title: StringSchema,
  views: SafeNonNegativeIntegerSchema,
});

export const ShoppableVideoStatisticsPreviewWithSalesDTOSchema =
  ShoppableVideoStatisticsPreviewWithoutSalesDTOSchema.extend({
    purchasedAmounts: SafeNonNegativeFloatSchema,
    purchasedCarts: SafeNonNegativeIntegerSchema,
  });

export const LiveReplayShoppableCountsDTOSchema = LiveReplayIntegerCountsDTOSchema.extend({
  shoppable: SafeNonNegativeIntegerSchema,
});

const BaseTenantStatisticsDTOSchema = z.object({
  events: z.object({
    engagement: EngagementStatisticsDTOSchema,
    eventStatisticsPreviews: EventStatisticsPreviewDTOSchema.array(),
    liveCount: SafeNonNegativeIntegerSchema,
    traffic: TrafficStatisticsDTOSchema.extend({
      views: LiveReplayIntegerCountsDTOSchema,
    }),
  }),
  shoppables: z.object({
    traffic: z.object({
      views: SafeNonNegativeIntegerSchema,
    }),
  }),
});

const TenantStatisticsWithSalesDTOSchema = BaseTenantStatisticsDTOSchema.extend({
  events: BaseTenantStatisticsDTOSchema.shape.events.extend({
    eCommerce: ECommerceStatisticsDTOSchema.extend({
      purchasedAmounts: LiveReplayCountsDTOSchema,
      purchasedCarts: LiveReplayIntegerCountsDTOSchema,
    }),
  }),
  shoppables: BaseTenantStatisticsDTOSchema.shape.shoppables.extend({
    eCommerce: z.object({
      purchasedAmounts: SafeNonNegativeFloatSchema,
      purchasedCarts: SafeNonNegativeIntegerSchema,
    }),
    shoppableVideoStatisticsPreviews: ShoppableVideoStatisticsPreviewWithSalesDTOSchema.array(),
  }),
});

const TenantStatisticsWithoutSalesDTOSchema = BaseTenantStatisticsDTOSchema.extend({
  events: BaseTenantStatisticsDTOSchema.shape.events.extend({
    eCommerce: ECommerceStatisticsDTOSchema,
  }),
  shoppables: BaseTenantStatisticsDTOSchema.shape.shoppables.extend({
    shoppableVideoStatisticsPreviews: ShoppableVideoStatisticsPreviewWithoutSalesDTOSchema.array(),
  }),
});

const TenantStatisticsDTOSchema = z.union([TenantStatisticsWithSalesDTOSchema, TenantStatisticsWithoutSalesDTOSchema]);

export type ShoppableVideoStatisticsPreviewWithoutSalesDTO = z.infer<
  typeof ShoppableVideoStatisticsPreviewWithoutSalesDTOSchema
>;
export type ShoppableVideoStatisticsPreviewWithSalesDTO = z.infer<
  typeof ShoppableVideoStatisticsPreviewWithSalesDTOSchema
>;
export type TenantStatisticsWithSalesDTO = z.infer<typeof TenantStatisticsWithSalesDTOSchema>;
export type TenantStatisticsWithoutSalesDTO = z.infer<typeof TenantStatisticsWithoutSalesDTOSchema>;
export type TenantStatisticsDTO = z.infer<typeof TenantStatisticsDTOSchema>;
