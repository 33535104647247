import { z } from 'zod';
import { CmsSchema } from '../../tenant';
import { StringNullableSchema, StringSchema, UUIDSchema } from '../../shared';
import { CurrencyDTOSchema } from './currency';
import { BillingPlanIdSchema } from '../../plans';
import { ISO31661Alpha2Schema } from '../../utils';

export const constraints = {
  alias: {
    minLength: 3,
    maxLength: 200,
  },
};
export const CreateTenantDTOSchema = z.object({
  countryCode: ISO31661Alpha2Schema,
  cms: CmsSchema,
  currencyId: UUIDSchema,
  masterEmail: StringSchema.email(),
  tenantName: StringSchema,
});

const KlaviyoIntegrationDTOSchema = z.object({
  apiKey: StringNullableSchema,
  listId: StringNullableSchema,
});

const TapcartIntegrationDTOSchema = z.object({
  screenId: StringNullableSchema,
});

export const IntegrationsDTOSchema = z.object({
  klaviyo: KlaviyoIntegrationDTOSchema,
  tapcart: TapcartIntegrationDTOSchema,
});
export const UpdateIntegrationsDTOSchema = IntegrationsDTOSchema;

export const TenantDTOSchema = z.object({
  activeSubscriptionId: BillingPlanIdSchema.nullable(),
  alias: StringSchema,
  apiKey: UUIDSchema,
  cms: CmsSchema,
  createdAt: StringSchema.datetime(),
  currency: CurrencyDTOSchema,
  hasCatalogProducts: z.boolean(),
  id: UUIDSchema,
  integrations: IntegrationsDTOSchema,
  name: StringSchema,
});

export type CreateTenantDTO = z.infer<typeof CreateTenantDTOSchema>;
export type IntegrationsDTO = z.infer<typeof IntegrationsDTOSchema>;
export type KlaviyoIntegrationDTO = z.infer<typeof KlaviyoIntegrationDTOSchema>;
export type TenantDTO = z.infer<typeof TenantDTOSchema>;
export type UpdateIntegrationsDTO = z.infer<typeof UpdateIntegrationsDTOSchema>;
