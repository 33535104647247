import { Flavor } from '@bellepoque/shared';
import { z } from 'zod';
import { SafeNonNegativeIntegerSchema, StringSchema, UUIDSchema } from '../../shared';

export const CurrencyDTOSchema = z.object({
  code: StringSchema,
  decimalDigits: SafeNonNegativeIntegerSchema,
  id: UUIDSchema,
  symbol: StringSchema,
})

export const UpdateCurrencyDTOSchema = z.object({
  currencyId: UUIDSchema,
})

export type CurrencyDTO = z.infer<typeof CurrencyDTOSchema>;
export type UpdateCurrencyDTO = z.infer<typeof UpdateCurrencyDTOSchema>;