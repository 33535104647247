import { z } from 'zod';
import { EventDTOSchema } from '../V1/api';
import { StringSchema, SafeNonNegativeIntegerSchema, UUIDSchema } from '../shared';

const EventMetadataCurrencySchema = z.object({
  code: StringSchema,
  decimalDigits: SafeNonNegativeIntegerSchema,
  id: UUIDSchema,
  symbol: StringSchema,
})

// TODO: Move to V1.player

export const EventMetadataSchema = EventDTOSchema.pick({
  chatIntroductionText: true,
  description: true,
  id:true,
  moderatorMessageLabel: true,
  postEventText: true,
  preEventText: true,
  tenantId: true,
  theme: true,
  title: true,
}).extend({
  currency: EventMetadataCurrencySchema
})

export type EventMetadata = z.infer<typeof EventMetadataSchema>;
