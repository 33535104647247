import { z } from 'zod';

type RESTRICTION = 'LIVEMEUP_EMAIL' | 'SHOPIFY_EMAIL' | 'DEVELOPMENT_STORE';
type FEATURE = 'REPLAY' | 'ADVANCED_ANALYTICS' | 'TAPCART';

export const BILLING_PLAN_ORDER = ['live_access', 'live_engage', 'live_power', 'live_enterprise'] as const;

export const BillingPlanIdSchema = z.enum(BILLING_PLAN_ORDER);
export type BillingPlanId = z.infer<typeof BillingPlanIdSchema>;

export type BillingPlan = {
  canPublishOnHomePage: boolean;
  cappedPrice: number;
  features: FEATURE[];
  id: BillingPlanId;
  integrationsEnabled: boolean;
  maxPublishedReplayDurationInSeconds?: number;
  maxPublishedReplays?: number;
  maxPublishedShoppables?: number;
  monthlyPrice: number;
  name: string;
  obsEnabled: boolean;
  restriction?: RESTRICTION;
  trialDays?: number;
  viewerMinutePrice?: number;
};

export const BILLING_PLANS: Record<BillingPlanId, BillingPlan> = {
  /*developer: {
    id: 'developer',
    name: 'Developer',
    monthlyPrice: 0,
    viewsIncluded: 0,
    cappedPrice: 0,
    features: ['REPLAY', 'ADVANCED_ANALYTICS'],
    restriction: 'DEVELOPMENT_STORE',
  },
  /*livemeup: {
    id: 'livemeup',
    name: 'Live Me Up',
    monthlyPrice: 0,
    viewsIncluded: 0,
    cappedPrice: 0,
    features: ['REPLAY', 'ADVANCED_ANALYTICS'],
    restriction: 'LIVEMEUP_EMAIL',
  },
  shopify_employee: {
    id: 'shopify_employee',
    name: 'Shopify Employee',
    monthlyPrice: 0,
    extraViewPrice: 0.1,
    viewsIncluded: 100,
    cappedPrice: 100,
    features: ['REPLAY', 'ADVANCED_ANALYTICS'],
    restriction: 'SHOPIFY_EMAIL',
  },*/
  live_access: {
    canPublishOnHomePage: false,
    cappedPrice: 100,
    features: [],
    id: 'live_access',
    integrationsEnabled: false,
    maxPublishedReplayDurationInSeconds: 22 * 60,
    maxPublishedReplays: 4,
    maxPublishedShoppables: 10,
    monthlyPrice: 0,
    name: 'Free',
    obsEnabled: false,
    trialDays: 14,
    viewerMinutePrice: 0.005,
  },
  live_engage: {
    canPublishOnHomePage: true,
    cappedPrice: 150,
    features: ['REPLAY', 'ADVANCED_ANALYTICS'],
    id: 'live_engage',
    integrationsEnabled: false,
    maxPublishedReplayDurationInSeconds: 65 * 60,
    maxPublishedReplays: 4,
    maxPublishedShoppables: 50,
    monthlyPrice: 89,
    name: 'Pro',
    obsEnabled: false,
    trialDays: 14,
    viewerMinutePrice: 0.005,
  },
  live_power: {
    canPublishOnHomePage: true,
    cappedPrice: 300,
    features: ['REPLAY', 'ADVANCED_ANALYTICS', 'TAPCART'],
    id: 'live_power',
    integrationsEnabled: true,
    maxPublishedShoppables: 150,
    monthlyPrice: 199,
    name: 'Business',
    obsEnabled: true,
    trialDays: 14,
    viewerMinutePrice: 0.005,
  },
  live_enterprise: {
    cappedPrice: 300,
    canPublishOnHomePage: true,
    features: ['REPLAY', 'ADVANCED_ANALYTICS', 'TAPCART'],
    id: 'live_enterprise',
    integrationsEnabled: true,
    monthlyPrice: 399,
    name: 'Enterprise',
    obsEnabled: true,
    trialDays: 14,
    viewerMinutePrice: 0.005,
  },
};
