export default {
  AddToEvent: 'Add to...',
  AddToEventDialog: {
    AlreadyAdded: 'Already added',
    NoEvents: 'No events were created.',
    Text: 'Choose the event to which the product will be added.',
    Text_other: 'Choose the event to which the products will be added.',
    Title: 'Add this product to...',
    Title_other: 'Add these products to...',
  },
  ApiKey: 'API key',
  Catalog: 'Catalog',
  ClearSelection: 'Clear selection',
  NoProducts: 'No product was found.',
  RenewApiKey: 'Renew',
  SelectedItems: '{{count}} selected product',
  SelectedItems_other: '{{count}} selected products',
  notifications: {
    AddProductsToEventFailed: 'An error occurred while adding products to the event. Please retry.',
    AddProductsToEventSuccess: 'Products successfully added to the event.',
    CatalogProductsFailedDeleting: 'An error occurred while deleting the products. Please retry.',
    CatalogProductsSuccessfullyDeleted: 'Products successfully deleted !.',
  },
};
