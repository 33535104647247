import { BILLING_PLANS, BillingPlanId } from '@bellepoque/api-contracts';

import { AppDispatch, State } from '../../../core/store';
import { fetchMediaCollections } from '../../../core/usecases/media-collections/fetch-media-collections';
import { fetchOneMediaCollection } from '../../../core/usecases/media-collections/fetch-one-media-collection';
import { getPagesForMediaCollections } from '../../../core/usecases/shopify/get-pages-for-media-collections';
import { trackUserJourneyEvent } from '../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createMediaCollectionsViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const {
      currentMediaCollection,
      mediaCollectionPagesUpdate: { status: mediaCollectionPagesUpdateStatus },
      mediaCollections,
      publishedShoppablesCount,
    } = state.mediaCollections;
    const { activeSubscriptionId, id: tenantId, name: tenantName } = state.tenants.currentTenant;

    const currentPlanId: BillingPlanId = activeSubscriptionId ?? 'live_access';

    return {
      currentMediaCollection,
      fetchMediaCollections: () => {
        dispatch(fetchMediaCollections({ tenantId }));
      },
      fetchOneMediaCollection: (mediaCollectionId: string) => {
        dispatch(fetchOneMediaCollection({ mediaCollectionId, tenantId }));
      },
      getPages: () => {
        dispatch(getPagesForMediaCollections(tenantId));
      },
      maxPublishedShoppablesCount: BILLING_PLANS[currentPlanId].maxPublishedShoppables ?? null,
      mediaCollectionPagesUpdateStatus,
      mediaCollections,
      mediaCollectionsFetchingStatus: state.mediaCollections.mediaCollectionsFetching.status,
      publishedShoppablesCount,
      tenantId,
      tenantName,
      trackUserJourneyEvent: () => {
        dispatch(
          trackUserJourneyEvent({
            data: { 'Tenant id': tenantId, 'Tenant name': tenantName },
            name: 'Playlists',
          }),
        );
      },
    };
  };
