import { constraints as eventsConstraints } from './events';
import { constraints as landingPageConstraints } from './landing-page';
import { constraints as mediaCollectionsConstraints } from './media-collections';
import { constraints as productsConstraints } from './products';
import { constraints as shoppableVideosConstraints } from './shoppable-videos';
import { constraints as tenantsConstraints } from './tenants';

export * from './chat';
export * from './currency';
export * from './event-statistics';
export * from './events';
export * from './facebook';
export * from './home';
export * from './klaviyo';
export * from './landing-page';
export * from './magic-contents';
export * from './media-collections';
export * from './product-overrides';
export * from './products';
export * from './reminders';
export * from './reports';
export * from './restreaming';
export * from './shopify-pages';
export * from './shoppable-videos';
export * from './tenant-statistics';
export * from './tenants';
export * from './theme';

export const constraints = {
  events: eventsConstraints,
  landingPage: landingPageConstraints,
  mediaCollections: mediaCollectionsConstraints,
  products: productsConstraints,
  shoppableVideos: shoppableVideosConstraints,
  tenants: tenantsConstraints,
};
