import { z } from 'zod';
import { URLSchema, StringNullableSchema } from '../../shared/schemas';

export const UpdateProductOverrideDTOSchema = z.object({
  description: StringNullableSchema,
  imageUrls: z.array(URLSchema).nullable(),
  title: StringNullableSchema,
});

export type UpdateProductOverrideDTO = z.infer<typeof UpdateProductOverrideDTOSchema>;
