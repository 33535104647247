import { ProductId } from './product';

// TODO: add zod

export interface LiveProductsStatistics {
  [productId: ProductId]: LiveProductStatistics;
}

export interface LiveProductStatistics {
  countLiveCartQuantity: number; // as countLiveCartQuantity but for the actual product
  nbProductPageViews: number;
  totalLiveCartAmount: number;
}

// TODO: Convert to value object in api
// TODO: Use zod and move it to V1.api
export interface LiveEventStatistics {
  byProductIds: LiveProductsStatistics;

  // number of viewers having sent at least 1 message
  countActiveChatUsers: number;

  // number of viewers actually watching the live
  countActiveViewers: number;

  // number of unique viewers having started the player
  totalLiveUniqueViewers: number;

  // total of product added to cart with the "add to cart" button
  // does not count removed products and quantities updates
  countLiveAddToCart: number;
  countLiveLikes: number;

  // live total product quantity in all users carts (add to cart + quantity updates).
  // if a user leaves, we keep the last value.
  // if a user removes a product, count quantity becomes 0.
  countLiveCartQuantity: number;

  countLiveProductPageOpened: number;
  countLiveValidateCart: number;

  // live total amount of product prices from countLiveCartQuantity
  totalLiveCartAmount: number;
}

export const initialLiveEventStatistics: LiveEventStatistics = {
  byProductIds: {},
  countActiveChatUsers: 0,
  countActiveViewers: 0,
  countLiveAddToCart: 0,
  countLiveLikes: 0,
  countLiveCartQuantity: 0,
  countLiveProductPageOpened: 0,
  countLiveValidateCart: 0,
  totalLiveCartAmount: 0,
  totalLiveUniqueViewers: 0,
};
