import { z } from "zod";
import { StringSchema, UUIDSchema } from "../shared";

const FileProcessingInstructionsSchema = z.object({
  sourceUrl: StringSchema,
  startTime: z.number(),
  videoDuration: z.number(),
}) 

export type FileProcessingInstructions = z.infer<typeof FileProcessingInstructionsSchema>

export const AutomatedReplayRequestSchema = z.object({
  eventHubName: z.string(),
  files: FileProcessingInstructionsSchema.array(),
  eventId: UUIDSchema,
  profile: z.enum(['hd', 'fhd', 'fhd++']),
  makeMp4: z.boolean(),
  makeAudioFile: z.boolean(),
})
export type AutomatedReplayRequest = z.infer<typeof AutomatedReplayRequestSchema>
