import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, IconButton, styled } from '@mui/material';
import React from 'react';

const StyledDialog = styled(Dialog)({
  borderRadius: '15px',
  padding: 0,
});

const StyledDialogActions = styled(DialogActions)({
  display: 'flex',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
});

const StyledDialogContent = styled(DialogContent)({
  overflow: 'hidden',
});

const ClosePlayerButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'relative',
  right: -6,
  top: -24,
}));

export type PlayerPreviewProps = {
  height?: number;
  ratio?: number;
};

const PlayerPreview = styled('iframe')<PlayerPreviewProps>(({ height = 600, ratio = 9 / 16 }) => ({
  border: 'none',
  height,
  width: height * ratio,
}));

export type PlayerPreviewParams = {
  endTimeStamp?: number;
  startTimeStamp?: number;
};

export type PlayerPreviewDialogProps = PlayerPreviewParams & {
  isCollection?: boolean;
  onClose: () => void;
  open: boolean;
  videoId: string;
};

export default function PlayerPreviewDialog({
  endTimeStamp,
  isCollection,
  onClose,
  open,
  startTimeStamp,
  videoId,
}: PlayerPreviewDialogProps) {
  const urlToUse = new URL(`${process.env.REACT_APP_PLAYER_ENDPOINT}/${videoId}/preview`);

  if (isCollection) urlToUse.searchParams.append('isCollection', '1');
  if (startTimeStamp) urlToUse.searchParams.append('t', startTimeStamp.toString());
  if (endTimeStamp) urlToUse.searchParams.append('e', endTimeStamp.toString());

  return (
    <StyledDialog PaperProps={{ elevation: 0, sx: { background: 'transparent' } }} onClose={onClose} open={open}>
      <StyledDialogActions>
        <ClosePlayerButton disableRipple onClick={onClose} size="large">
          <CloseIcon />
        </ClosePlayerButton>
      </StyledDialogActions>
      <StyledDialogContent>
        {/* TODO: trads */}
        <PlayerPreview scrolling="no" src={urlToUse.href} title="Player Preview" />
      </StyledDialogContent>
    </StyledDialog>
  );
}
