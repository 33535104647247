import { EventId } from '../event';
import { Message, MessageData, MessageId, MessageTypes, SerializableMessage } from './Message';

export interface VideoProcessedHlsData extends MessageData {
  eventId: EventId;
  replayHlsPath: string;
}

export interface VideoProcessedMp4Data extends MessageData {
  eventId: EventId;
  replayMp4Path: string;
}

export interface AudioProcessedData extends MessageData {
  audioFile: string;
  vodId: EventId;
}

export interface VideoProcessingFailedData extends MessageData {
  eventId: EventId;
  hasHlsUploadFailed: boolean;
}

export type VideoProcessedHlsMessage = Message<VideoProcessedHlsData>;
export type VideoProcessedMp4Message = Message<VideoProcessedMp4Data>;
export type AudioProcessedMessage = Message<AudioProcessedData>;
export type VideoProcessingFailedMessage = Message<VideoProcessingFailedData>;

export class VideoProcessedHls implements SerializableMessage<VideoProcessedHlsData> {
  constructor(public readonly id: MessageId, private time: string, private data: VideoProcessedHlsData) {}

  toJSON(): VideoProcessedHlsMessage {
    return {
      data: this.data,
      datacontenttype: 'application/json;charset=utf-8',
      id: this.id,
      partitionkey: this.data.eventId,
      source: 'replay-processing',
      time: this.time,
      type: MessageTypes.ReplayProcessing_VideoProcessedHls,
    };
  }
}

export class VideoProcessedMp4 implements SerializableMessage<VideoProcessedMp4Data> {
  constructor(public readonly id: MessageId, private time: string, private data: VideoProcessedMp4Data) {}

  toJSON(): VideoProcessedMp4Message {
    return {
      data: this.data,
      datacontenttype: 'application/json;charset=utf-8',
      id: this.id,
      partitionkey: this.data.eventId,
      source: 'replay-processing',
      time: this.time,
      type: MessageTypes.ReplayProcessing_VideoProcessedMp4,
    };
  }
}

export class AudioProcessed implements SerializableMessage<AudioProcessedData> {
  constructor(public readonly id: MessageId, private time: string, private data: AudioProcessedData) {}

  toJSON(): AudioProcessedMessage {
    return {
      data: this.data,
      datacontenttype: 'application/json;charset=utf-8',
      id: this.id,
      partitionkey: this.data.vodId,
      source: 'replay-processing',
      time: this.time,
      type: MessageTypes.ReplayProcessing_AudioProcessed,
    };
  }
}

export class VideoProcessingFailed implements SerializableMessage<VideoProcessingFailedData> {
  constructor(public readonly id: MessageId, private time: string, private data: VideoProcessingFailedData) {}

  toJSON(): VideoProcessingFailedMessage {
    return {
      data: this.data,
      datacontenttype: 'application/json;charset=utf-8',
      id: this.id,
      partitionkey: this.data.eventId,
      source: 'replay-processing',
      time: this.time,
      type: MessageTypes.ReplayProcessing_VideoProcessingFailed,
    };
  }
}
