import { z } from "zod";
import { UUIDSchema } from "./shared";

export const OnboardingStep = {
    CreateEvent: 'CREATE_EVENT',
    AddProducts: 'ADD_PRODUCTS',
    CameraApp: 'CAMERA_APP',
    PrivateTest: 'PRIVATE_TEST',
    OpenToPublic: 'OPEN_TO_PUBLIC',
    Completed: 'COMPLETED'
  } as const;

export type TOnboardingStep =
  typeof OnboardingStep[keyof typeof OnboardingStep];

export const OnboardingProgressSchema = z.object({
  eventId: UUIDSchema.nullable(),
  step: z.nativeEnum(OnboardingStep),
});

export const UpdateOnboardingProgressDTOSchema = OnboardingProgressSchema;

export type OnboardingProgress = z.infer<typeof OnboardingProgressSchema>;
export type UpdateOnboardingProgressDTO = z.infer<typeof UpdateOnboardingProgressDTOSchema>;

export const INITIAL_ONBOARDING_PROGRESS = {
  eventId: null,
  step: OnboardingStep.CreateEvent
}

export const COMPLETED_ONBOARDING_PROGRESS = {
  eventId: null,
  step: OnboardingStep.Completed
}