import { z } from 'zod';
import { StringSchema, UUIDSchema } from '../../shared';

const EventTrafficReportDTOSchema = z.object({
  nbReplaysViews: z.number().or(z.literal('NA')),
  nbUniqueLiveViewers: z.number().or(z.literal('NA')),
  nbUniqueReplayViewers: z.number().or(z.literal('NA')),
  showTime: z.string().datetime(),
  title: z.string(),
});

const ShoppableTrafficReportDTOSchema = EventTrafficReportDTOSchema.omit({ showTime: true }).extend({
  productTitle: StringSchema,
});

const TrafficReportDTOSchema = z.union([EventTrafficReportDTOSchema, ShoppableTrafficReportDTOSchema]);

const MonthTrafficReportsDTOSchema = z.record(UUIDSchema, TrafficReportDTOSchema);

const MonthNumberSchema = z.number().int().min(0).max(11);

const TrafficReportsDTOSchema = z.record(MonthNumberSchema, MonthTrafficReportsDTOSchema);

export type EventTrafficReportDTO = z.infer<typeof EventTrafficReportDTOSchema>;
export type MonthNumber = z.infer<typeof MonthNumberSchema>;
export type MonthTrafficReportsDTO = z.infer<typeof MonthTrafficReportsDTOSchema>;
export type ShoppableTrafficReportDTO = z.infer<typeof ShoppableTrafficReportDTOSchema>;
export type TrafficReportDTO = z.infer<typeof TrafficReportDTOSchema>;
export type TrafficReportsDTO = z.infer<typeof TrafficReportsDTOSchema>;

const EventBillingReportDTOSchema = z.object({
  liveUsageInMin: z.number().or(z.literal('NA')),
  nbSentSMS: z.number().or(z.literal('NA')),
  replayUsageInMin: z.number().or(z.literal('NA')),
  showTime: z.string().datetime(),
  title: StringSchema,
});

const MonthBillingReportsDTOSchema = z.record(UUIDSchema, EventBillingReportDTOSchema);

const BillingReportsDTOSchema = z.record(MonthNumberSchema, MonthBillingReportsDTOSchema);

export type BillingReportsDTO = z.infer<typeof BillingReportsDTOSchema>;
export type EventBillingReportDTO = z.infer<typeof EventBillingReportDTOSchema>;
export type MonthBillingReportsDTO = z.infer<typeof MonthBillingReportsDTOSchema>;
