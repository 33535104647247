import { ContentCopy } from '@mui/icons-material';
import { Box, Button, Link, Paper, TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CBOTenantTapcartIntegration } from '../../../../../core/domain/CBOTenant';
import { ENDPOINTS } from '../../../../../endpoints';
import UpgradePlanButton from '../../../atoms/UpgradePlanButton';
import IntegrationsHeader from '../../../atoms/integrations/IntegrationsHeader';
import { createTapcartIntegrationViewModel } from './TapcartIntegration.viewmodel';

const Container = styled(Box)({
  width: '100%',
});

const ContentContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  margin: 'auto',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  width: '100%',
}));

const Section = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
  textAlign: 'initial',
}));

const SectionTitle = styled(Typography)({
  fontSize: '1.25em',
  fontWeight: 'bolder',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '15px',
  [theme.breakpoints.up('xs')]: {
    width: '95%',
  },
  [theme.breakpoints.up('md')]: {
    width: '60%',
  },
}));

const Text = styled(Typography)({
  fontSize: '.95em',
});

const InputAndButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
}));

const Step = styled(Text)({
  display: 'inline',
  fontWeight: 'bold',
});

type Inputs = CBOTenantTapcartIntegration;

export default function TapcartIntegration() {
  const dispatch = useDispatch();
  const viewModel = useSelector(createTapcartIntegrationViewModel({ dispatch }));
  const { t } = useTranslation(['integrations', 'common']);

  const {
    currentTenant,
    integrations,
    liveShoppingPageUrlToCopy,
    playlistsBlocUrlToCopy,
    trackUserJourneyEvent,
    isEnabled,
    updateIntegration,
    updateStatus,
  } = viewModel;

  const form = useForm<Inputs>({ criteriaMode: 'all', defaultValues: integrations.tapcart, mode: 'onChange' });
  const { handleSubmit: handleFormSubmit, formState, register, watch } = form;
  const { errors } = formState;

  const { ref: screenIdInputRef, ...screenIdInputProps } = register('screenId', {
    disabled: !isEnabled,
  });

  const watchScreenId = watch('screenId');

  useEffect(() => {
    trackUserJourneyEvent({
      data: { 'Tenant id': currentTenant.id, 'Tenant name': currentTenant.name },
      name: 'Integrations',
    });
  }, []);

  useEffect(() => {
    if (updateStatus === 'success') {
      resetForm();
    }
  }, [updateStatus]);

  useEffect(() => {
    resetForm();
  }, [integrations.tapcart]);

  const resetForm = () => {
    form.reset(integrations.tapcart);
  };

  const handleSubmit = (values: Inputs) => {
    const { screenId } = values;

    updateIntegration({
      screenId,
    });
  };

  const handleCopyUrl = (url: string) => {
    navigator.clipboard.writeText(url);
  };

  const submissionInProgress = updateStatus === 'pending';

  const canSubmit = useMemo(() => {
    if (submissionInProgress || !formState.isValid || !formState.isDirty) {
      return false;
    }

    return true;
  }, [watchScreenId, submissionInProgress, formState.isValid, formState.isDirty]);

  const renderCopyUrl = (url: string) => {
    return (
      <InputAndButtonContainer>
        <TextField
          InputProps={{ style: { fontFamily: 'monospace' } }}
          disabled
          fullWidth
          label={t('Tapcart.URL')}
          size="small"
          value={url}
        />
        <Button color="primary" onClick={() => handleCopyUrl(url)} startIcon={<ContentCopy />} variant="contained">
          <span>{t('common:Copy')}</span>
        </Button>
      </InputAndButtonContainer>
    );
  };

  const renderDisabledSection = () => (
    <Box mb={2} p={6} pb={4}>
      <Typography>{t('IntegrationNotEnabled')}</Typography>
      <UpgradePlanButton sx={{ marginTop: (theme) => theme.spacing(2) }} tenantName={currentTenant.name} />
    </Box>
  );

  const renderLiveShoppingPageSection = () => (
    <Section sx={{ borderBottom: 'solid 1px', borderBottomColor: (theme) => theme.palette.divider }}>
      <SectionTitle mb={1} variant="h6">
        {t('Tapcart.LiveShoppingPageConfiguration.Title')}
      </SectionTitle>

      <Box display="flex" flexDirection="column" gap={2}>
        <Text>
          <Trans
            components={{
              CreateWebBasedScreenLink: <Link href={ENDPOINTS.tapcart.createWebBasedScreen} target="_blank" />,
              Step: <Step />,
            }}
            i18nKey={`Tapcart.LiveShoppingPageConfiguration.Step1`}
            ns="integrations"
          />
        </Text>
        {renderCopyUrl(liveShoppingPageUrlToCopy)}
        <Text>
          <Trans
            components={{
              CreateWebBasedScreenLink: <Link href={ENDPOINTS.tapcart.createWebBasedScreen} target="_blank" />,
              Step: <Step />,
            }}
            i18nKey={`Tapcart.LiveShoppingPageConfiguration.Step2`}
            ns="integrations"
          />
        </Text>
        <Text>
          <Trans
            components={{
              Step: <Step />,
            }}
            i18nKey={`Tapcart.LiveShoppingPageConfiguration.Step3`}
            t={t}
          />
        </Text>
      </Box>
    </Section>
  );

  const renderPlaylistsBlockSection = () => (
    <Section sx={{ paddingBottom: (theme) => theme.spacing(4) }}>
      <SectionTitle mb={1} variant="h6">
        {t('Tapcart.PlaylistsBlockConfiguration.Title')}
      </SectionTitle>
      <Box display="flex" flexDirection="column" gap={2}>
        <Text>
          <Trans
            components={{
              Step: <Step />,
            }}
            i18nKey={`Tapcart.PlaylistsBlockConfiguration.Step1`}
            t={t}
          />
        </Text>
        {renderCopyUrl(playlistsBlocUrlToCopy)}
        <Text>
          <Trans
            components={{
              CreateWebBasedScreenLink: <Link href={ENDPOINTS.tapcart.createWebBasedScreen} target="_blank" />,
              Step: <Step />,
            }}
            i18nKey={`Tapcart.PlaylistsBlockConfiguration.Step2`}
            t={t}
          />
        </Text>
        <Text>
          <Trans
            components={{
              Step: <Step />,
            }}
            i18nKey={`Tapcart.PlaylistsBlockConfiguration.Step3`}
            t={t}
          />
        </Text>
        <form noValidate onSubmit={handleFormSubmit(handleSubmit)} style={{ width: '100%' }}>
          <InputAndButtonContainer>
            <TextField
              {...screenIdInputProps}
              error={!!errors.screenId}
              fullWidth
              helperText={!!errors.screenId ? errors?.screenId?.message : ''}
              inputRef={screenIdInputRef}
              label={t('Tapcart.ScreenId')}
              required
              size="small"
              variant="outlined"
            />

            <Button color="primary" disabled={!canSubmit} type="submit" variant="contained">
              <span>{t('common:Save')}</span>
            </Button>
          </InputAndButtonContainer>
        </form>
        <Text>
          <Trans
            components={{
              Step: <Step />,
            }}
            i18nKey={`Tapcart.PlaylistsBlockConfiguration.Step4`}
            t={t}
          />
        </Text>
      </Box>
    </Section>
  );

  return (
    <Container>
      <IntegrationsHeader title={t('Tapcart.Tapcart')} />
      <ContentContainer>
        <StyledPaper>
          {!isEnabled ? (
            renderDisabledSection()
          ) : (
            <>
              <Section
                sx={{
                  borderBottom: 'solid 1px',
                  borderBottomColor: (theme) => theme.palette.divider,
                }}
              >
                <Trans
                  components={{
                    EnableIntegrationLink: <Link href={ENDPOINTS.tapcart.enableLMUIntegration} target="_blank" />,
                  }}
                  i18nKey={`Tapcart.Intro`}
                  t={t}
                />
              </Section>
              {renderLiveShoppingPageSection()}
              {renderPlaylistsBlockSection()}
            </>
          )}
        </StyledPaper>
      </ContentContainer>
    </Container>
  );
}
