import { z } from "zod";
import { UUIDNullableSchema } from "../../shared";

export const constraints = {
  replaySectionTitle : {
    minLength: 0,
    maxLength: 100
  },
}

export const HasCmsLandingPageDTOSchema = z.object({
  hasCmsLandingPage: z.boolean(),
})

export const LandingPageDTOSchema = z.object({
  highlightedEventId: UUIDNullableSchema,
  landingPageUrl: z.string(),
  publishedReplayEventsIds: z.array(z.string()),
  replaySectionTitle: z.string(),
})

export const UpdateLandingPageDTOSchema = LandingPageDTOSchema.omit({
  landingPageUrl: true,
});


export type HasCmsLandingPageDTO = z.infer<typeof HasCmsLandingPageDTOSchema>;
export type LandingPageDTO = z.infer<typeof LandingPageDTOSchema>;
export type UpdateLandingPageDTO = z.infer<typeof UpdateLandingPageDTOSchema>;
