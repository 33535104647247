import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import QRCode from 'qrcode.react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import { State } from '../../../../../core/store';
import { changePreviewMode } from '../../../../../core/usecases/event/live/change-preview-mode';
import { fetchEventViewerToken } from '../../../../../core/usecases/event/live/fetch-event-viewer-token';
import { openReplay } from '../../../../../core/usecases/event/live/open-replay';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';
import { eventToUserJourneyData } from '../../../../../utils/event-to-user-journey-data';
import PreviewHeader from '../../../molecules/event/preview/PreviewHeader';

const Container = styled(Box)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  gap: theme.spacing(4),
  height: '100%',
  padding: theme.spacing(3),
}));

const PlayerContainer = styled(Box)({
  border: 0,
  flex: 1,
  width: '100%',
});

const QRCodeContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  alignSelf: 'flex-start',
  backgroundColor: theme.palette.common.white,
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 0,
  gap: 2,
  justifyContent: 'center',
}));
interface LiveDetailsProps {
  event: CBOEventReadModel;
}

// TODO: use view model
export default function Preview({ event }: LiveDetailsProps) {
  const { t } = useTranslation(['events', 'common']);
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentTenant = useSelector((state: State) => state.tenants.currentTenant);
  const viewerToken = useSelector((state: State) => state.events.currentEventViewerToken);
  const isPrivatePreview = useSelector((state: State) => state.events.isPrivatePreview);
  const { status: eventViewerTokenFetchingStatus } = useSelector(
    (state: State) => state.events.eventViewerTokenFetching,
  );
  const { status: replayOpeningStatus } = useSelector((state: State) => state.events.replayOpening);
  const hasChannel = event.status !== 'PLANNED';
  const isLoaded = eventViewerTokenFetchingStatus !== 'pending';

  const urlToUse = new URL(
    `${process.env.REACT_APP_PLAYER_ENDPOINT}/${event.id}/${isPrivatePreview ? 'private-preview' : 'preview'}`,
  );
  if (isPrivatePreview) urlToUse.searchParams.set('streamToken', viewerToken);

  const urlToUseForPreview = new URL(urlToUse);
  urlToUseForPreview.searchParams.set('isPreview', '1');

  useEffect(() => {
    dispatch(
      trackUserJourneyEvent({
        data: {
          ...eventToUserJourneyData(event),
          'Tenant id': currentTenant.id,
          'Tenant name': currentTenant.name,
        },
        name: 'Preview',
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(fetchEventViewerToken({ eventId: event.id }));
  }, [dispatch, event.id, hasChannel]);

  const handleChangePreviewMode = (isPrivatePreview: boolean) => {
    dispatch(changePreviewMode({ isPrivatePreview }));
  };

  const handleOpenReplay = () => {
    dispatch(
      openReplay({
        eventId: event.id,
      }),
    );
  };

  return (
    <Container>
      <PreviewHeader
        event={event}
        onChangeMode={handleChangePreviewMode}
        onOpenReplay={handleOpenReplay}
        replayOpeningStatus={replayOpeningStatus}
        urlToUse={urlToUse.href}
      />
      {isLoaded && (
        <ContentContainer>
          <PlayerContainer>
            <iframe
              src={urlToUseForPreview.href}
              style={{ border: 'none', height: '100%', width: '100%' }}
              title={t('Preview')}
            />
          </PlayerContainer>
          {!!viewerToken && (
            <QRCodeContainer boxShadow={2}>
              <Box sx={{ borderBottom: '1px #FAFAFA solid', p: 2, width: '100%' }}>
                <Typography sx={{ fontSize: '1.1em' }} variant="h6">
                  {t('common:PreviewOnMobile')}
                </Typography>
              </Box>
              <Box sx={{ p: 3 }}>
                <QRCode
                  fgColor={theme.palette.primary.dark}
                  level="L"
                  renderAs="canvas"
                  size={200}
                  value={urlToUse.href}
                />
              </Box>
            </QRCodeContainer>
          )}
        </ContentContainer>
      )}
    </Container>
  );
}
